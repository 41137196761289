.home {
  padding: 0;

  > .row {
    margin: $rem-24 0;
  }

  &__topCard {
    @include media("t-large", max) {
      overflow-x: auto;
    }
  }
}