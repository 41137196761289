.announcement {
  width: 100%;
  height: 100%;
  min-height: 9.375rem;
  padding: 1rem;
  position: relative;
  color: black;
  border-radius: $rem-8;
  display: flex;
  align-items: center;
  
  @include media("t-large", max) {
    border-radius: $rem-20;
    &__item {
      flex-direction: column;
    }
    justify-content: center;
  }

  &__background--blue {
    background: $color-secondary8;
    border: $rem-1 solid $color-secondary1;
  }

  &__background--white {
    background: $color-neutral8;
  }

  &__background--yellow {
    background: $color-notification;
    border: $rem-1 solid $color-notification-border;
  }

  &__imageContainer {
    height: 100%;
    width: fit-content;
    position: absolute;
    top: 0;
    left: 0;

    @include media("t-large", max) {
      position: relative;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__imageContainer--small {
    padding: 1rem 0rem 1rem 1rem;
  
    @include media("t-large", max) {
      padding: 0;
    }
  }

  &__imageContainer--large {
    padding: 1rem 0rem 1rem 1rem;
  
    @include media("t-large", max) {
      padding: 0;
    }
  }

  &__textContainer {
    padding-left: 0px;
    padding-right: 0px;
    @include media("t-large", max) {
      padding: $rem-16;
      text-align: center;
      font-size: $rem-14;
    }
  }

  &__textContainer--small {
    margin: 0rem 1rem 0rem 5rem;
    @include media("t-large", max) {
      margin: 0;
    }
  }

  &__textContainer--large {
    margin: 0rem 2.375rem 0rem 7.75rem;
    @include media("t-large", max) {
      margin: 0;
    }
  }

  &__closeButtonContainer {
    height: 100%;
    width: fit-content;
    padding: 0rem;
    position: absolute;
    top: 0;
    right: 0;
  }

  &__closeButton {
    cursor: pointer;
    color: $color-neutral14;
    position: absolute;
    top: $rem-20;
    right: $rem-24;
    width: $rem-16;
    height: $rem-16;
  }
}
