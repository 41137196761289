.addAccounts {
  &__block {
    padding: $rem-32;
  }
  &__manualBtnContainer {
    margin-left: auto;
    max-width: 334px;
    min-width: max-content;
    height: $rem-48;
    .button {
      height: 100%;
      border-radius: $rem-40;
    }
    @include media("t-large", max) {
      max-width: 100%;
    }
  }
  &__addIcon {
    width: $rem-20;
    height: $rem-20;
    fill: $color-primary5;
  }
  &__toBeAdded {
    font-size: $rem-24;
  }
  &__autoDetected,
  &__manualAdd {
    white-space: nowrap;
  }
  &__addSeperator {
    border-right: 1px solid #bebebe;
    margin: 0 $rem-24;
    @include media("t-large", max) {
      border-right: 0;
      border-bottom: 1px solid #bebebe;
      margin: $rem-24 0;
    }
  }
  &__dataContainer {
    color: $color-neutral1;
    font-size: $rem-24;
    font-weight: $fontWeight-Bold;
  }
  &__btnContainer {
    justify-content: flex-end;
    @include media("m-medium", max) {
      flex-direction: column;
    }
  }
  &__cancelBtn,
  &__submitBtn {
    .button {
      height: $rem-48;
      min-width: 184px;
      border-radius: $rem-40;
    }
    @include media("t-large", max) {
      width: 100%;
      margin-top: $rem-16;
    }
  }
  &__cancelBtn {
    margin-right: $rem-24;
    @include media("t-large", max) {
      margin: $rem-16 0;
    }
  }
  @include media("t-large", max) {
    &__bottomContainer,
    &__accountContainer,
    &__btnContainer {
      flex-direction: column;
    }
  }
}
