.form-control {
  padding: 0;
  border: 0;
  border-radius: 0;
  background-color: transparent;

  &:focus {
    background-color: transparent;
  }
}

.input-group-text {
  border: 0;
  background-color: transparent;
}

.form-label {
  margin-bottom: 0;
}

.carousel-indicators {
  margin-bottom: 1rem;
}

.carousel-indicators [data-bs-target] {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-left: 4px;
  margin-right: 4px;
  opacity: 1;
  background-color: $color-neutral6;
}

.carousel-indicators .active {
  background-color: $color-secondary4;
}

.carousel-control-prev,
.carousel-control-next {
  top: auto;
  bottom: 1.4rem;
  z-index: 3; //higher than carousel indicator
  width: 50px;
  opacity: 1;
  color: $color-neutral6;

  &:hover {
    color: $color-secondary4;
  }
}

.carousel-control-prev {
  left: calc(50% - 150px);
}

.carousel-control-next {
  right: calc(50% - 150px);
}

.navbar {
  overflow-x: auto;
  margin-bottom: $rem-16;
  padding-bottom: 0;
}

.navbar-light,
.navbar-dark {
  .navbar-nav {
    align-items: center;
    min-width: max-content;
    .nav-link {
      color: inherit;
      .custom-nav-item {
        padding-top: $rem-6;
        padding-bottom: $rem-6;
      }
      &.active {
        font-weight: $fontWeight-Bold;
        .custom-nav-item {
          border-bottom: 1px solid $color-neutral8;
        }
      }
    }
  }
}

/*Tabs*/
.nav-tabs .nav-link {
  color: $color-primary1;
  padding: $rem-12 0;
  margin-right: $rem-32;
  border: 0px;

  &:hover,
  :focus {
    border: 0px;
  }
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent;
  font-weight: $fontWeight-Bold;
  border: 0px;
  border-bottom: 2px solid $color-secondary4;
}

.navbar-toggler {
  height: 32px;
  width: 32px;
  padding: 6px;
  background: none;
  border: 0;
  &:focus {
    box-shadow: none;
  }
}

.offcanvas-header {
  padding-bottom: 0;
  position: absolute;
  right: 0;
}

.offcanvas-top {
  height: fit-content;
}

.offcanvas-header .btn-close {
  box-shadow: none;
}
