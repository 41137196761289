.accountCreated {
  padding: $rem-100;
  padding-bottom: $rem-48;
  margin-bottom: $rem-24;
  
  &__title {
    font-size: $rem-32;
    line-height: 1.25;
    color: $color-primary1;
    padding-top: $rem-24;
    padding-bottom: $rem-16;
    text-align: center;
  }

  &__description {
    text-align: center;
    color: $color-neutral1;
    padding-bottom: $rem-12;
  }

  &__panel {
    margin-top: $rem-28;
    padding-top: $rem-48;
    padding-bottom: $rem-24;
    border-top: 1px solid $color-neutral5;
  }

  &__col {
    padding: 0;
  }

  &__detailTitle {
    color: $color-neutral3;
    font-size: $rem-12;
    line-height: 1.3333;
    padding-bottom: $rem-4;
  }

  &__detailValue {
    color: $color-neutral1;
    line-height: 1.5;
    padding-bottom: $rem-24;

    &--withRemark {
      padding-bottom: $rem-8;
    }
  }

  &__detailRemark {
    color: $color-neutral3;
    font-size: $rem-14;
    line-height: 1.4286;
  }

  &__footer {
    display: flex;
    justify-content: center;
  }

  &__button.button {
    width: max-content;
    padding-left: 40px;
    padding-right: 40px;
  }
}