.mainPanel {
  @include media("t-large", max) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  &__title {
    font-size: $rem-48;
    font-weight: $fontWeight-Bold;
    @include media("t-large", max) {
      font-size: $rem-24;
    }
  }

  &__description {
    font-size: $rem-16;
    padding-top: $rem-16;
    line-height: 24px;
    @include media("t-large", max) {
      font-size: $rem-14;
      padding-top: $rem-8;
      line-height: 20px;
    }
  }

  &__leftContainer {
    padding-left: 8%;
    @include media("t-large", max) {
      padding-left: $rem-16;
    }
  }

  &__img {
    max-width: 700px;
    padding-right: 0;
    @include media("t-large", max) {
      padding-top: $rem-8;
      max-width: 100%;
      padding-right: $rem-16;
    }
  }
}
