//#region rem calculations
$rem-base: 16px;
$rem-1: 1px / $rem-base * 1rem;
$rem-2: 2px / $rem-base * 1rem;
$rem-3: 3px / $rem-base * 1rem;
$rem-4: 4px / $rem-base * 1rem;
$rem-5: 5px / $rem-base * 1rem;
$rem-6: 6px / $rem-base * 1rem;
$rem-7: 7px / $rem-base * 1rem;
$rem-8: 8px / $rem-base * 1rem;
$rem-10: 10px / $rem-base * 1rem;
$rem-11: 11px / $rem-base * 1rem;
$rem-12: 12px / $rem-base * 1rem;
$rem-13: 13px / $rem-base * 1rem;
$rem-14: 14px / $rem-base * 1rem;
$rem-15: 15px / $rem-base * 1rem;
$rem-16: 16px / $rem-base * 1rem;
$rem-18: 18px / $rem-base * 1rem;
$rem-20: 20px / $rem-base * 1rem;
$rem-24: 24px / $rem-base * 1rem;
$rem-26: 26px / $rem-base * 1rem;
$rem-28: 28px / $rem-base * 1rem;
$rem-30: 30px / $rem-base * 1rem;
$rem-32: 32px / $rem-base * 1rem;
$rem-34: 34px / $rem-base * 1rem;
$rem-36: 36px / $rem-base * 1rem;
$rem-40: 40px / $rem-base * 1rem;
$rem-44: 44px / $rem-base * 1rem;
$rem-46: 46px / $rem-base * 1rem;
$rem-48: 48px / $rem-base * 1rem;
$rem-56: 56px / $rem-base * 1rem;
$rem-64: 64px / $rem-base * 1rem;
$rem-72: 72px / $rem-base * 1rem;
$rem-78: 78px / $rem-base * 1rem;
$rem-88: 88px / $rem-base * 1rem;
$rem-96: 96px / $rem-base * 1rem;
$rem-100: 100px / $rem-base * 1rem;
$rem-112: 112px / $rem-base * 1rem;
$rem-119: 119px / $rem-base * 1rem;
$rem-156: 156px / $rem-base * 1rem;
$rem-196: 196px / $rem-base * 1rem;
$rem-376: 376px / $rem-base * 1rem;
$rem-480: 480px / $rem-base * 1rem;
