.accountByState {
  display: flex;
  flex-direction: column;
  
  &__title {
    font-size: $rem-24;
    line-height: 1.3333;
    color: $color-secondary1;
  }

  .listCarousel {
    flex-grow: 1;

    .carousel-indicators {
      margin-bottom: -0.4rem;
    }

    .carousel-control-prev, 
    .carousel-control-next {
      bottom: 0;
    }
  }
}