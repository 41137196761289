.adminDetailModal {
  &--hidden {
    opacity: 0;
  }

  &__form {
    text-align: left;
  }

  &__label {
    color: $color-neutral3;
    font-size: 12px;
  }

  &__value {
    font-size: $rem-16;
    margin-top: -3px; //same as textbox
    padding-top: $rem-4;
    padding-bottom: $rem-16;
  }

  &__roleSummary {
    margin-top: $rem-36;
  }

  &__rolesPermissions {
    font-size: $rem-20;
    line-height: 1.4;
    font-weight: $fontWeight-Bold;
    margin-top: $rem-36;
    margin-bottom: $rem-8;
  }

  &__rolesPermissionsDescription {
    margin-bottom: $rem-8;
  }

  &__tooltip {
    padding: $rem-20;
    font-size: $rem-16;
  }

  &__tooltipTitle {
    margin-bottom: $rem-4;
  }

  &__trigger {
    fill: $color-neutral2;
    display: inline-block;
    width: 20px;
    vertical-align: middle;
    &:hover {
      fill: $color-primary4;
    }
  }

  &__resendActivation {
    display: inline-block;
    color: $color-primary5;
    cursor: pointer;
  }
}