.page-header {
  width: 100%;
  text-align: center;
  padding-bottom: $rem-40;
  font-weight: $fontWeight-Normal;
  letter-spacing: 0.2px;

  &__title {
    color: $color-secondary1;
    font-size: $rem-32;
    margin-bottom: $rem-16;
    line-height: $rem-40;
  }

  &__description {
    color: $color-neutral8;
    font-size: $rem-16;
    line-height: $rem-24;
  }
}

@include media("m-large", max) {
  .page-header {
    padding-bottom: $rem-16;
    text-align: left;
    &__title {
      padding-bottom: $rem-8;
      padding-left: 0;
      margin-bottom: 0;
    }
    &__description {
      font-size: $rem-14;
      line-height: $rem-20;
      padding-left: 0;
    }
  }
}
