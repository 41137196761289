.bulkAccountDetails {
  &__pagination {
    height: $rem-16;
    margin: $rem-24 0;
    display: flex;
  }

  &__page {
    width: $rem-16;
    height: 100%;
    margin-right: $rem-16;
    background-color: $color-neutral6;
    border-radius: 50%;

    &--active {
      background-color: $color-neutral3;
    }
  }

  &__formSection {
    padding: 24px 0;
    border-bottom: 1px solid $color-neutral5;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__topic {
    color: $color-primary4;
    font-size: $rem-24;
    line-height: 1.3333;
  }

  &__topicNote {
    color: $color-neutral1;
    line-height: 1.5;
  }

  &__rocModal .modal-content {
    border-radius: $rem-16;
    padding: $rem-40 $rem-48;
    background: $color-secondary6;
    position: unset;
    color: $color-neutral1;
    font-size: $rem-24;

    
    @include media("t-large", max) {
      padding: $rem-24 $rem-16;
    }
  }
  
  &__rocTable {
    display: flex;
    flex-direction: column;
  }
}