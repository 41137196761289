.width {
  &__100 {
    width: 100%;
  }
}

.text-align {
  &__center {
    text-align: center;
  }
  &__left {
    text-align: left;
  }
  &__right {
    text-align: right;
  }
}

.flex-grow {
  flex-grow: 1;
}

.error {
  color: $color-error-lightBackground;
}

.warning {
  color: $color-warning;
}

.active {
  color: $color-positive;
}

.inactive {
  color: $color-neutral3;
}

.normalText {
  color: $color-neutral14;
}

.dataWrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  display: inline-block;
}