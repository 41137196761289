.accountInfoCardLoader {
  background: rgb(19, 35, 129);
  border-radius: $rem-16;
  padding: $rem-32;
  &__title {
    height: $rem-30;
    width: 40%;
    min-width: 240px;
    margin-bottom: $rem-12;
  }
  &__showAccount {
    margin-top: $rem-24;
    height: $rem-24;
    max-width: 220px;
    display: flex;
    margin-left: auto;
  }
  &__bulkAccount {
    height: $rem-24;
    width: 25%;
    min-width: 200px;
  }
  @include media("t-large", max) {
    &__showAccount {
      margin-left: 0;
      margin-top: $rem-8;
      margin-bottom: $rem-8;
    }
  }
}