.createGroup {
  &__assignTitle {
    padding-right: $rem-16;
    @include media("t-large", max) {
      padding-right: 0;
    }
  }
  &__btnContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    @include media("t-large", max) {
      justify-content: center;
      flex-direction: column;
      padding-top: $rem-16;
    }
  }
  &__cancelBtnContainer,
  &__createBtnContainer {
    min-width: 190px;
    .button {
      height: $rem-48;
      border-radius: $rem-40;
    }
    @include media("t-large", max) {
      .button {
        flex: auto;
      }
    }
  }
  &__cancelBtnContainer {
    margin-right: $rem-24;
    @include media("t-large", max) {
      margin-right: 0;
      margin-top: $rem-16;
      order: 2;
    }
  }
  &__tableContainer {
    margin-top: $rem-24;
    @include media("t-large", max) {
      margin-top: 0;
    }
  }
}