.submitDeclaration {
  &__title {
    color: $color-primary4;
    font-size: $rem-24;
    font-weight: $fontWeight-Bold;
    line-height: 1.3333;
  }

  &__paragraph {
    padding-top: $rem-24;
    line-height: 1.5;
  }

  &__addressLine {
    &:not(:last-child)::after {
      content: ',';
    }
  }

  &__checkbox {
    margin-right: $rem-20;
  }

  &__panel {
    align-items: center;
    margin-top: $rem-32;
  }

  &__downloadLink {
    color: $color-primary5;
    fill: $color-primary5;
    cursor: pointer;

    &:hover, &:link {
      color: $color-primary5;
    }
  }

  &__downloadIcon {
    height: $rem-24;
    padding-right: $rem-8;
  }

  &__formSection {
    padding: 24px 0;
    border-bottom: 1px solid $color-neutral5;

    &:last-child {
      border-bottom: 0;
    }
  }
  
  &__description {
    margin-right: $rem-16;
    margin-bottom: $rem-16;
  }
  
  &__faqLabel {
    font-size: $rem-12;
    line-height: 1.3333;
    padding-bottom: $rem-4;
    color: $color-neutral3;
  }
  
  &__faqValue {
    font-size: $rem-16;
    line-height: 1.5;
    font-weight: $fontWeight-Bold;
  }

  &__fileUpload .fileUpload {
    background-color: $color-neutral8;
  }
}