.searchBill {
  width: 100%;
  padding-top: $rem-48;
  padding-left: 0;
  padding-right: 0;
  min-height: 87vh;
  @include media("t-large", max) {
    padding-top: 0;
  }
  &__title {
    font-weight: $fontWeight-Bold;
    font-size: $rem-32;
    padding-bottom: $rem-16;
    @include media("t-large", max) {
      padding-bottom: $rem-12;
    }
  }
  &__description {
    @include media("t-large", max) {
      font-size: $rem-14;
    }
  }
  &__leftContainer {
    padding-left: 8%;
    padding-right: 5%;
    @include media("t-large", max) {
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-bottom: $rem-36;
    }
  }
  &__btnContainer {
    max-width: 240px;
    margin-left: auto;
    margin-bottom: $rem-56;
    min-width: max-content;
    .button {
      height: $rem-48;
      border-radius: $rem-40;
    }
    @include media("t-large", max) {
      max-width: 100%;
      margin-left: initial;
    }
  }
  &__table {
    flex-basis: 100%;
    margin-bottom: $rem-48;
  }
}
.tags-input {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	min-height: $rem-48;
	width: 100%;
	//padding: 0 8px;
	
	input {
		flex: 1;
		//border: none;
		//height: $rem-46;
		//font-size: $rem-14;
		//padding: $rem-4 0 0 0;
		min-width: 50%;
		width: 100%;
		&:focus {
			outline: transparent;
		}
	}
}

#tags {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	margin: $rem-8 0 0 0;
	width: 100%;
}

.tag {
	width: auto;
	height: $rem-32;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $color-neutral1;
	padding: 0 $rem-8;
	font-size: $rem-14;
	list-style: none;
	border-radius: $rem-20;
	margin: 0 $rem-8 $rem-5 0;
	background: $color-neutral8;
	border: $rem-1 solid $color-secondary2;
	cursor: pointer;
	&:hover {
		background: $color-neutral5;
      }
	&.active{
		border: $rem-2 solid $color-primary5 !important;
		color: $color-primary5;
	}
	&.error{
		border: $rem-2 solid $color-neutral18;
		background: $color-neutral20;
	}
	&.error
	{&.active{
		background: $color-neutral20;
		color: $color-primary5;
		border: $rem-2 solid $color-primary5 !important;
	}
	}

	.tag-title {
		margin-top: $rem-3;
	}
	.tag-close-icon {
		display: block;
		width: $rem-16;
		height: $rem-16;
		line-height: $rem-16;
		text-align: center;
		font-size: $rem-14;
		margin-left: $rem-8;
		border-radius: 50%;
		background: $color-neutral8;
		cursor: pointer;
	}
}

@media screen and (max-width: 567px) {
	.tags-input {
		width: calc(100vw - 32px);
	}
}