.downtime {
  height: 100%;
  width: 100%;

  &__image {
    padding: 0;

    &.small {
      width: 96px;
    }
    &.medium {
      width: 100px;
    }
    &.large {
      width: 200px;
    }
  }

  &__text {
    white-space: pre-wrap;
    text-align: center;
    &--light {
      color: $color-neutral1;
    }
    &--dark {
      color: $color-neutral8;
    }
  }

  &__title {
    font-weight: $fontWeight-Bold;
  }

  &__buttonContainer {
    @include media("t-large", max) {
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
    }
  }

  &__buttons {
    width: 240px;
    padding: 0;
    margin-right: $rem-24;
    min-width: max-content;
    &:last-child {
      margin-right: 0;
    }
    .downtime__button {
      height: 3rem;
      border-radius: $rem-40;
    }
    @include media("t-large", max) {
      flex-direction: column;
      flex-wrap: nowrap;
      margin-right: 0;
      margin-bottom: $rem-24;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
