.detailHead {
  &__downloadIconContainer > .button {
    @include media("t-large", max) {
      justify-content: flex-start;
      padding-left: 0;
      padding-top: $rem-12;
    }
  }
  &__downloadIcon {
    width: $rem-20;
    fill: $color-primary5;
  }
  &__topContainer {
    padding-top: $rem-32;
    @include media("t-large", max) {
      flex-direction: column;
      padding-top: 0;
    }
  }
}
