.section-header {
  width: 100%;
  font-weight: $fontWeight-Normal;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__left {
    flex: 1;
  }

  &__title {
    color: $color-primary4;
    font-size: $rem-24;
    margin-bottom: $rem-16;
  }

  &__description {
    color: $color-neutral1;
    font-size: $rem-16;
  }

  @include media("t-large", max) {
    flex-direction: column;
    align-items: initial;
  }
  
  @include media("m-large", max) {
    &__title {
      margin-bottom: $rem-12;
    }
    &__description {
      font-size: $rem-16;
    }
  }
}
