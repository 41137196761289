.history {
  color: $color-neutral1;
  
  &__title {
    font-size: $rem-24;
    line-height: 1.3333;
    color: $color-primary4;
  }

  &__label {
    font-size: $rem-12;
    line-height: $rem-18;
    color: $color-neutral3;
  }

  &__value {
    font-size: $rem-14;
    line-height: $rem-18;
  }

  &__status--paid {
    color: $color-positive;
  }
  &__status--actionRequired {
    color: $color-warning;
  }
  &__status--processing {
    color: $color-neutral2;
  }
  &__status--failed {
    color: $color-error-lightBackground;
  }

  &__status--active {
    color: $color-positive;
  }
  &__status--warning {
    color: $color-warning;
  }
}