.adminForgotPasswordSent {
  min-height: 100vh;

  &__container {
    display: flex;
    justify-content: center;
    margin-top: $rem-156;
    @include media("t-large", max) {
      margin-top: 0;
    }
  }
  &__logo {
    width: 150px;
    height: auto;
    margin-left: $rem-32;
    padding: 0;
    cursor: pointer;
    @include media("t-large", max) {
      margin-left: $rem-16;
    }
  }
  &__title {
    font-weight: $fontWeight-Bold;
    font-size: 1.9em;
    line-height: $rem-40;
    @include media("t-large", max) {
      font-size: 1.5em;
      line-height: $rem-32;
    }
  }
  &__info {
    white-space: pre-wrap;
    @include media("t-large", max) {
      font-size: $rem-14;
      line-height: $rem-20;
    }
  }
  &__buttonContainer {
    max-width: 252px;
    height: $rem-48;
    @include media("t-large", max) {
      max-width: none;
    }
  }
  &__button--back {
    padding: $rem-10;
    border-radius: $rem-40 !important;
    height: 100%;
  }
  @include media("t-large", max) {
    &__image {
      width: auto;
      height: auto;
      min-width: auto;
      padding-top: $rem-32;
      padding-bottom: $rem-16;
    }
    &__button {
      margin-bottom: $rem-48;
      justify-content: center;
    }
  }
}
