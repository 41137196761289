.adminCards {
  margin-bottom: $rem-24;
  overflow-x: auto;

  &__container {
    display: flex;
    flex-wrap: nowrap;
    
    @include media("t-large", max) {
      min-width: max-content;
    }
  }

  &__card {
    flex-grow: 1;
    margin-right: $rem-24;
    &:last-child {
      margin-right: 0;
    }
  }
}