.filter {
  height: 24px;
  width: 24px;
  margin: $rem-8;
  cursor: pointer;
  fill: $color-neutral2;

  &.activated {
    fill: $color-primary5;
  }

  &.disabled {
    fill: $color-neutral5;
    cursor: not-allowed;
  }

  &__popover {
    font-size: $rem-16;
    color: $color-neutral1;
    
    @include media("t-large", max) {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: $color-neutral8;
      overflow: auto;

      .customDateRange {
        width: 100%;
      }
    }
  }

  &__dateRangeToggle {
    display: flex;
    align-items: center;
    padding: $rem-12 $rem-16;

    & + .filter__options {
      > .filter__optionCategory:first-child {
        border-top: 1px solid $color-neutral6;
      }
    }
    
    &--date {
      @include media("t-large", max) {
        display: none;
      }
    }
  }

  &__dateRangeContainer {
    cursor: pointer;
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-top: $rem-12;
      border-top: 1px solid $color-neutral6;
      padding-top: $rem-12;
    }
  }

  &__dateRangeBack {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &__dateRange {
    flex: 1;
  }

  &__dateRangeSelect {
    font-size: $rem-12;

    &--left {
      padding-right: $rem-12;
    }

    &--right {
      padding-left: $rem-12;
    }
  }

  &__options {
    margin: 0 $rem-16;
    
    @include media("t-large", max) {
      border-bottom: 1px solid rgba(0,0,0,0); //little hack to force shrink prevention
    }
  }

  &__optionCategory {
    padding-top: $rem-12;
    border-top: 1px solid $color-neutral6;

    &:first-child {
      border-top: 0;
    }
  }

  &__option {
    margin: $rem-12 0;
  }

  &__optionInput {
    cursor: pointer;
    display: flex;
    align-items: center;

    input[type="checkbox"] {
      margin-right: $rem-12;
      cursor: pointer;
    }

    &--highlighted {
      color: $color-warning;
    }

    &--warning {
      color: $color-error-lightBackground;
    }

    &--positive {
      color: $color-positive;
    }

    &--processing {
      color: $color-neutral2;
    }

    &--inactive {
      color: $color-neutral3;
    }

    &--disabled {
      color: $color-neutral5;

      cursor: not-allowed;
    }
  }

  &__clearAll {
    border-top: 1px solid $color-neutral6;
    cursor: pointer;
    color: $color-primary5;
    margin: 0 $rem-12;
    padding: $rem-12 0;
    
    @include media("t-large", max) {
      display: none;
    }
  }

  &__dateRangeFilter {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    
    @include media("t-large", max) {
      padding-top: $rem-12;
    }
  }

  &__dateRangeApply {
    width: min-content;
    padding: $rem-4;
  }

  &__chevron {
    width: $rem-18;
    height: $rem-18;
  }

  &__header {
    display: none;
    justify-content: space-between;
    padding:$rem-12;
    border-bottom: 1px solid $color-neutral6;

    @include media("t-large", max) {
      display: flex;
    }
  }

  &__headerLeft {
    height: 24px;
    width: 24px;
    fill: $color-neutral1;
    cursor: pointer;
    &:hover {
      fill: $color-primary5;
    }
  }

  &__headerRight {
    cursor: pointer;
    color: $color-primary5;
  }
}

div[id^='filter__'].customTooltip {
  @include media("t-large", max) {
    transform: none !important; //overwrite inline styling, give popover full screen
  }
}