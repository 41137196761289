.bulkAccountTable {
  &__dataWrap {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    display: inline-block;
  }
  &__tableDetails {
    cursor: pointer;
    text-align: center;
    &:hover {
      color: $color-primary4;
      fill: $color-primary4;
      .icon-document-inner {
        fill: $color-primary4;
      }
    }
  }
  &__previewIcon {
    width: $rem-20;
  }
  &__downloadContainer {
    width: fit-content;
    .button {
      padding-left: 0;
    }
    &:hover {
      .bulkAccountTable__downloadAccountIcon {
        fill: $color-primary4;
      }
    }
  }
  &__downloadAccountIcon {
    width: $rem-20;
    fill: $color-primary5;
  }
}
