.topCard {
  display: flex;
  
  @include media("t-large", max) {
    min-width: max-content;
  }

  &__card {
    flex-grow: 1;
    margin-right: $rem-28;
    &:last-child {
      margin-right: 0;
    }
  }
}