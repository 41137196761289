.journey {
  background: #d6ebfa;
  color: $color-neutral1;
  font-weight: $fontWeight-Bold;
  font-size: $rem-16;
  margin-left: -72px;
  margin-right: -72px;
  padding: $rem-48 14%;
  @include media("t-large", max) {
    margin-left: -16px;
    margin-right: -16px;
    padding: $rem-24 $rem-32;
    font-size: $rem-14;
  }

  &__title {
    font-size: $rem-24;
    padding-bottom: $rem-32;
    @include media("t-large", max) {
      font-size: $rem-14;
      padding-bottom: $rem-16;
    }
  }

  &__journeyImgContainer {
    position: relative;
  }

  &__borderMiddle {
    border-bottom: 4px dotted $color-primary5;
    position: absolute;
    top: calc(50% - 2px);
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }

  &__journeyContainer {
    .col {
      margin-right: $rem-28;
      &:last-child {
        margin-right: 0;
      }
    }
    @include media("t-large", max) {
      flex-direction: column;
      flex-wrap: nowrap;
      .col {
        margin-right: 0;
        margin-bottom: $rem-28;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__imgContainer {
    display: flex;
    justify-content: center;
  }

  &__img {
    width: 200px;
    height: 130px;
    border-radius: $rem-16;
    color: $color-neutral8;
    font-weight: $fontWeight-Normal;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: $rem-16;
    &--1 {
      background: #81b4ff;
    }
    &--2 {
      background: #236cd4;
    }
    &--3 {
      background: #8399ee;
    }
  }

  &__imgSub {
    position: absolute;
  }

  &__img1Ball {
    top: 10px;
    left: -8px;
  }

  &__img1Lego1 {
    top: 112px;
    right: 41px;
  }

  &__img1Lego2 {
    top: 108px;
    right: 20px;
  }

  &__img1Lego3 {
    top: 108px;
    right: -8px;
  }

  &__img2 {
    top: 80px;
    left: -12px;
  }

  &__img3 {
    top: 60px;
    right: -8px;
  }

  &__description {
    padding-top: $rem-24;
  }
}
