.login {
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  width: 100%;
  overflow: visible;
  position: relative;
  letter-spacing: 0.2px;

  &__downtimeAnnouncement {
    height: fit-content;
    z-index: 4;
    width: 75%;
  }

  &__content {
    background: $background-primaryGradient-45deg;
    padding: 40px 72px;
    flex-basis: 100%;
    margin-top: 92px;

    @include media("t-large") {
      flex-basis: 39%;
      margin-top: 0;
    }
  }

  &__billboard {
    background: $background-primaryGradient-135deg;
    min-height: 108vw;
    flex-basis: 100%;

    @include media("t-large") {
      flex-basis: 61%;
      min-height: auto;
    }
  }

  &__logo {
    position: fixed;
    top: 48px;
    left: 48px;
    width: 150px;
    height: auto;
    z-index: 1; //bootstrap's carousel is having z-index
  }

  &__links {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__links--default {
    display: flex;
  }

  &__links--mobile {
    display: none;
  }

  &__link {
    padding-bottom: 6px;
    border-bottom: 1px solid transparent;
    margin-right: $rem-48;
    cursor: pointer;
    &:hover {
      border-bottom-color: #fff;
    }

    &--active {
      cursor: default;
      font-weight: $fontWeight-Bold;
      padding-bottom: 6px;
      border-bottom: 2px solid #fff;
      margin-right: $rem-48;
    }
  }

  &__linkRemark {
    border-radius: $rem-16;
    background-color: $color-secondary1;
    color: $color-neutral1;
    font-size: $rem-12;
    font-weight: $fontWeight-Bold;
    line-height: 1.3333;
    padding: $rem-4 $rem-8;
    margin-left: -$rem-24;
    align-self: flex-start;
  }

  &__notification {
    min-height: 100px;
    max-height: 150px;
    margin: $rem-8 0;
    padding: $rem-16 0;
    position: relative;
    &--noError {
      visibility: hidden;
      transition: visibility 0.3s ease;
    }
  }

  &__form {
    padding: 28px 0;
  }

  &__extraInfo {
    font-size: $rem-16;
    line-height: $rem-24;
  }

  &__goToPortal {
    color: $color-secondary1;
  }

  &__email,
  &__password,
  &__forgotPassword {
    padding-bottom: 28px;
  }

  &__forgotPasswordLink {
    font-size: $rem-14;
    line-height: 1.4286;
    color: $color-secondary1;
    &:link {
      color: $color-secondary1;
    }
    &:hover {
      color: $color-secondary1;
    }

    &.disabled {
      cursor: default;
    }
  }

  &__buttons {
    display: flex;
    @include media("t-large", max) {
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }

  &__button {
    flex: 1 1 50%;
    .button {
      height: $rem-48;
      border-radius: $rem-40;
    }
    &:last-child {
      margin-left: $rem-24;
      @include media("t-large", max) {
        margin-left: 0;
      }
    }
    @include media("t-large", max) {
      margin-left: 0;
      margin-bottom: $rem-24;
      &:first-child {
        margin-bottom: 0;
      }
    }
  }

  &__slide--link,
  &__slide--link:link {
    cursor: pointer;
    color: $color-secondary1;
    &:link {
      color: $color-secondary1;
    }
    &:hover {
      color: $color-secondary1;
    }
  }

  @include media("t-large", max) {
    &__logo {
      position: absolute;
      top: $rem-24;
      left: $rem-16;
    }
    &__links--default {
      display: none;
    }
    &__links--mobile {
      display: flex;
      border-bottom: 1px solid $color-neutral9;
    }
    &__content {
      padding: $rem-40 $rem-16;
    }
    &__contentContainer {
      background: $color-primary7;
      padding: $rem-24 $rem-16 0 $rem-16;
      border-radius: $rem-16;
      margin-bottom: $rem-24;
      position: relative;
    }
    &__notification {
      position: fixed;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 0;
      z-index: 3;
    }
    &__buttons {
      flex: auto;
      flex-wrap: wrap;
    }
    &__button--register {
      order: 2;
    }
  }

  &__extraInfoContainer {
    color: $color-primary1;
    line-height: $rem-24;
  }

  &__extraInfoTitle {
    font-weight: $fontWeight-Bold;
    padding-bottom: $rem-8;
  }

  &__extraInfoContentBold {
    font-weight: $fontWeight-Bold;
  }
}

a.login__extraInfoContent--link {
  color: $color-primary5;

  &:hover {
    color: $color-primary4;
  }
}
