.allPaymentsTable {
  &__dataWrap {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    display: inline-block;
  }

  &__filterPopover {
    max-height: 392px;
    overflow-y: auto;
    @include media("t-large", max) {
      max-height: none;
    }
  }

  &__status-icon {
    cursor: pointer;
    width: $rem-20;
    fill: $color-neutral8;

    &:hover {
      fill: $color-secondary1;
    }
  }

  &__statusKey {
    font-weight: $fontWeight-Bold;
  }

  &__statusHR {
    height: 0.5px !important;
  }

  &__statusPositive {
    color: $color-positive;
  }

  &__statusWarning {
    color: $color-warning;
  }

  &__statusError {
    color: $color-error-lightBackground;
  }

  &__statusTooltip {
    white-space: pre-wrap;
    margin-top: $rem-8;
    max-width: 600px;
    font-size: $rem-16;
    line-height: $rem-24;
  }

  &__statusSubTitle {
    text-align: center;
    font-weight: $fontWeight-Bold;
  }

  &__tableDetails {
    cursor: pointer;
    text-align: center;
    &:hover {
      color: $color-primary4;
      fill: $color-primary4;
      .icon-document-inner {
        fill: $color-primary4;
      }
    }
  }

  &__previewIcon {
    width: $rem-20;
  }

  &__emptyImg {
    width: $rem-100;
    height: $rem-100;
  }
  &__emptyText {
    color: $color-neutral1;
    font-size: $rem-14;
  }

  &__excelSection {
    padding-top: $rem-16;
    margin-top: $rem-16;
    border-top: 1px solid $color-neutral5;
  }

  &__excelContainer {
    margin: $rem-24 0;
    button.button {
      padding-left: $rem-48;
      padding-right: $rem-48;
    }
  }
}
