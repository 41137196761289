.registerDetails {
  width: 100%;
  &__title {
    font-weight: 600;
    font-size: $rem-32;
    padding-bottom: $rem-16;
  }

  &__buttonContainer {
    display: flex;
    margin-top: $rem-40;
    .button {
      border-radius: $rem-40;
      height: $rem-48;
    }

    @include media("t-large", max) {
      flex: auto;
      flex-wrap: wrap;
      margin-top: $rem-24;
    }
  }

  &__cancelBtnContainer {
    margin-right: $rem-24;
  }

  &__button {
    flex: 1 1 50%;
  }

  &__loginContainer {
    font-size: $rem-14;
  }

  &__termsContainer {
    cursor: pointer;
    position: relative;

    input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;
    }
  }

  &__checkbox {
    position: absolute;
    top: 0;
    left: 0;
    height: $rem-16;
    width: $rem-16;
    border: solid 2px $color-neutral8;
    border-radius: 2px;
  }

  &__termsContainer input:checked ~ &__checkbox {
    background-color: $color-neutral8;
  }

  &__termsContainer input:checked ~ &__checkbox:after {
    display: block;
  }

  &__checkbox:after {
    content: "";
    position: absolute;
    display: none;
  }

  &__checkbox:after {
    left: 3.5px;
    top: 0;
    width: 5px;
    height: $rem-10;
    border: solid $color-primary5;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &__termsLink {
    color: $color-secondary1;
    &:link {
      color: $color-secondary1;
    }
    &:hover {
      color: $color-secondary1;
    }
  }

  &__termsError {
    color: $color-warning;
    font-size: $rem-12;
    margin-top: $rem-4;
  }

  @include media("t-large", max) {
    &__buttonContainer {
      flex-direction: column-reverse;
    }
    &__cancelBtnContainer {
      margin-right: 0;
      margin-top: $rem-16;
    }
  }
  
  &__notification {
    position: relative;
    &--noError {
      visibility: hidden;
      transition: visibility 0.3s ease;
    }
    @include media("t-large", max) {
      position: fixed;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: $rem-8;
      z-index: 3;
    }
  }
}
