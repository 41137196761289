.quickTipsModals {
  &__image {
    cursor: pointer;
    width: 188px;
  }
  &__description {
    cursor: pointer;
    font-weight: $fontWeight-Bold;
    font-size: $rem-14;
    line-height: $rem-16;
    color: $color-primary5;
    text-align: left;
    padding-top: $rem-12;
    padding-left: $rem-14;
  }
  &__buttonContainer {
    height: $rem-48;
    .button {
      border-radius: $rem-40;
      max-width: 220px;
    }
  }
  @include media("t-large", max) {
    &__description {
      text-align: center;
      padding-left: 0;
    }
  }
}
