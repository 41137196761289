.latestBillSummary {
  display: flex;
  flex-wrap: wrap;

  @include media("t-large", max) {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  &__triggerContainer {
    display: inline-block;
  }

  &__latest {
    flex: 5;
    border-right: 1px solid #fff;

    @include media("t-large", max) {
      order: 3;
      border-right: 0;
      border-top: 1px solid #fff;
      padding-top: $rem-24;
    }
  }

  &__total {
    flex: 5;
    height: max-content;

    @include media("t-large", max) {
      order: 1;
      margin-bottom: $rem-24;
      padding: 0;
    }
  }

  &__viewBillPayment {
    flex: 2;
    align-self: center;

    @include media("t-large", max) {
      order: 2;
      margin-bottom: $rem-24;
      width: 100%;
    }

    button {
      padding-top: $rem-12;
      padding-bottom: $rem-12;
    }
  }

  &__latestTitle,
  &__totalTitle {
    font-size: $rem-24;
    line-height: 1.3333;
    color: $color-secondary1;

    @include media("t-large", max) {
      text-align: center;
    }
  }

  &__totalRow {
    @include media("t-large", max) {
      text-align: center;
    }
  }

  &__latestBillIcon {
    width: $rem-24;
    height: $rem-24;
    fill: $color-neutral8;
    cursor: pointer;

    &:hover {
      fill: $color-secondary1;
    }
  }

  &__latestBillITriggerContainer {
    margin-left: $rem-8;

    .tooltip-inner {
      border-radius: $rem-4;
    }
  }

  &__latestBillTooltip {
    font-size: $rem-16;
    line-height: $rem-24;
    max-width: 400px;

    @include media("m-large", max) {
      max-width: 300px;
    }
    @include media("m-medium", max) {
      max-width: 250px;
    }
  }

  &__latestBillTooltipInfoBold {
    font-weight: $fontWeight-Bold;
  }

  &__latestRow {
    margin-bottom: $rem-8;
  }

  &__value {
    text-align: right;
  }

  &__amountDue {
    font-size: $rem-32;
    line-height: 1.25;
    font-weight: $fontWeight-Bold;
    margin-bottom: $rem-8;
  }
}
