.sectionHeaderLoader {
  background: $color-secondary6;
  &__container {
    @include media("t-large", max) {
      flex-direction: column;
    }
  }
  &__title {
    height: $rem-32;
    border-radius: $rem-16;
    width: 60%;
  }
  &__info {
    height: $rem-24;
    border-radius: $rem-16;
    margin-top: $rem-16;
    @include media("t-large", max) {
      margin-bottom: $rem-16;
    }
  }
  &__button {
    height: $rem-48;
    border-radius: $rem-40;
    width: 50%;
    display: block;
    margin-left: auto;
    @include media("t-large", max) {
      width: 100%;
    }
  }
}
