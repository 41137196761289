.master-layout_user {
  flex-grow: 1;
  width: 100%;

  &__cvmHeaderContainer {
    min-height: $rem-72;
    @include media("t-large", max) {
      min-height: $rem-112;
    }
  }
  &__cvmHeader {
    background-color: $color-secondary1;
    color: $color-primary1;
    position: sticky;
    top: 0;
    z-index: 5; // loader is 4
    @include media("t-large", max) {
      flex-direction: column;
    }
  }
  &__cvmDescription {
    margin-right: $rem-24;
    font-size: $rem-20;
    text-align: center;
    @include media("t-large", max) {
      margin-right: 0;
      margin-bottom: $rem-16;
    }
  }
  & > .master-layout-container {
    position: relative;
    height: 100%;
    padding-left: $rem-72;
    padding-right: $rem-72;
  }
  @include media("t-large", max) {
    & > .master-layout-container {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
