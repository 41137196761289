.paymentMethodLoader {
  background-color: $color-secondary6;

  &__method {
    height: $rem-56;
    margin: $rem-8 0;
  }

  &__title {
    margin-top: $rem-8;
    height: $rem-24;
    width: 25%;

    &--long {
      margin-top: $rem-8;
      height: $rem-24;
      width: 75%;
    }
  }

  &__value {
    margin-bottom: $rem-8;
    height: $rem-32;
    width: 50%;
  }

  &__space {
    height: $rem-16;
  }

  &__button {
    margin-top: $rem-12;
    height: $rem-40;
  }
}