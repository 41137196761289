.companyDetailsBlock {
  padding: $rem-32;
  margin-bottom: $rem-40;
  color: $color-neutral1;

  &__topic {
    color: $color-primary4;
    font-size: $rem-24;
    line-height: 1.3333;
    @include media("t-large", max) {
      margin-bottom: $rem-8;
    }
  }

  &__label {
    color: $color-neutral3;
    font-size: $rem-12;
    margin-bottom: $rem-4;
  }

  &__postalCode {
    @include media("t-large", max) {
      margin-bottom: $rem-24;
    }
  }
}
