.reconciliationModal {
  &__row {
    @include media("t-large", max) {
      flex-direction: column;
    }
  }
  &__calculateContainer {
    text-align: left;
    width: max-content;
  }
  &__calculateTitle {
    color: $color-primary4;
  }
  &__enabled {
    color: $color-primary4;
    cursor: pointer;
    &:hover {
      color: $color-primary5;
      .reconciliationModal__chevron {
        fill: $color-primary5;
      }
    }
  }
  &__disabled {
    color: $color-neutral5;
    cursor: not-allowed;
    .reconciliationModal__chevron {
      fill: $color-neutral5;
    }
  }
  &__chevron {
    width: $rem-24;
    fill: $color-primary4;
    margin-left: $rem-8;
    transition: transform 0.5s;
    &.rotateReconciliationImage {
      transform: rotate(90deg);
    }
  }
  &__reconciliationDetailsContainer {
    overflow: hidden;
    transition: max-height 0.5s;
    max-height: 0;
    animation: fadeOut 0.8s;
    &.reconciliationModal__hasDetail {
      max-height: 1000px;
      animation: fadeIn 0.8s;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  &__positive {
    color: $color-positive;
  }
  &__reasonInfo {
    cursor: pointer;
    width: $rem-20;
    fill: $color-neutral2;
    &:hover {
      fill: $color-primary4;
    }
  }
  &__reasonPopover {
    max-width: 400px;
    font-size: $rem-16;
    color: $color-neutral2;
  }
  &__reasonPopoverTitle {
    color: $color-neutral1;
    padding-bottom: $rem-4;
  }
}
