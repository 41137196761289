.toggle {
  display: flex;
  border: 1px solid $color-secondary7;
  color: $color-neutral8;
  font-size: $rem-14;
  line-height: calc(#{$rem-40} - 2px); //minus borders
  border-radius: $rem-40;
  text-align: center;
  align-items: center;

  @include media("t-large", max) {
    font-size: $rem-10;
    line-height: calc(#{$rem-28} - 2px); //minus borders
  }

  &--light {
    border: 1px solid $color-primary5;
    color: $color-primary5;
  
    .toggle__switch.active {
      background-color: $color-primary5;
      color: $color-neutral8;
      box-shadow: 0px 0px 0px 1px $color-primary5;
    }
  }
  
  &--disabled {
    border: 1px solid $color-neutral6;
    color: $color-neutral3;
    cursor: not-allowed;
  
    .toggle__switch{
      cursor: not-allowed;
      &.active {
        background-color: $color-neutral6;
        color: $color-neutral3;
        box-shadow: 0px 0px 0px 1px $color-neutral6;
      }
    }
  }

  &__switch {
    flex: 1 1;
    border-radius: $rem-40;
    cursor: pointer;
    transition: 0.3s;
    overflow: hidden;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
  
    &.active {
      cursor: default;
      pointer-events: none;
      background-color: $color-neutral8;
      color: $color-primary1;
    }
  }
}