.allPayments {
  &__block {
    padding: $rem-32;
  }
  &__topCard {
    overflow-y: hidden;
    overflow-x: auto;
  }
  &__tableContainer {
    min-height: 220px;
  }
}
