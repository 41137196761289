.notification {
  width: 100%;
  height: 100%;
  position: relative;
  color: $color-neutral1;
  transition: opacity 0.3s ease;
  text-align: left;
  z-index: 1;

  &.fadeOut {
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: -10;
    transition-delay: 0s, 0.4s;
    transition-property: opacity, z-index;
  }

  &__iconContainer {
    position: absolute;
  }

  &__icon {
    margin: $rem-18 $rem-8 0rem $rem-20;
    width: $rem-24;
    height: $rem-24;
    &--close, &--check {
      fill: $color-neutral8;
    }
    &--exclamation, .icon-close-filled {
      fill: $color-error-lightBackground2;
    }
    .icon-check-filled {
      fill: $color-positive;
    }
  }

  .notification__icon {
    padding-top: 0px;
  }

  &__textValue {
    white-space: pre-wrap;
    background-color: $color-neutral8;
    border-radius: 0.5rem;
    border-left: 8px solid rgba(0,0,0,0);
    box-shadow: 0rem $rem-1 $rem-6 $rem-1 $color-neutral13;
  }

  &__closeButtonContainer {
    height: 100%;
    width: fit-content;
    padding: 0rem;
    position: absolute;
    top: 0;
    right: 0rem;
  }
  &__closeButton {
    cursor: pointer;
    width: $rem-16;
    height: $rem-16;
    color: $color-neutral1;
  }
}
