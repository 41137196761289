.accGroups {
  &__btnContainer {
    min-width: 240px;
    .button {
      height: $rem-48;
      border-radius: $rem-40;
    }
    @include media("t-large", max) {
      min-width: 100%;
      margin-top: $rem-16;
    }
  }
}
