.accGroupTable {
  &__statusIcon {
    cursor: pointer;
    width: $rem-20;
    fill: $color-neutral8;

    &:hover {
      fill: $color-secondary1;
    }
  }
  &__tooltip {
    white-space: pre-wrap;
    margin-top: $rem-8;
    max-width: 400px;
    font-size: $rem-16;
    line-height: 1.5;
  }
  &__referenceBold {
    font-weight: $fontWeight-Bold;
  }
  &__dataWrap {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    display: inline-block;
  }
  &__statusActive {
    color: $color-positive;
  }
  &__statusInactive {
    color: $color-neutral3;
  }
  &__tableDetails {
    cursor: pointer;
    text-align: center;
    &:hover {
      color: $color-primary4;
      fill: $color-primary4;
      .icon-document-inner {
        fill: $color-primary4;
      }
    }
  }
  &__previewIcon {
    width: $rem-20;
  }
  &__documentIcon {
    width: $rem-20;
    .icon-document-line {
      display: none;
    }
  }
  &__bottomContainer {
    align-items: center;
    @include media("t-large", max) {
      align-items: initial;
      flex-direction: column;
    }
  }
  &__buttonContainer {
    width: 240px;
    min-height: $rem-48;
    margin-left: auto;
    margin-right: 0;
    min-width: max-content;
    .button {
      border-radius: $rem-40;
      height: $rem-48;
    }
    @include media("t-large", max) {
      width: 100%;
      margin-right: auto;
      margin-top: $rem-16;
    }
  }
  &__downloadContainer {
    width: fit-content;
    .button {
      padding-left: 0;
    }
    &:hover {
      .childAccountTable__downloadAccountIcon {
        fill: $color-primary4;
      }
    }
  }
  &__downloadAccountIcon {
    width: $rem-20;
    fill: $color-primary5;
  }
  &__btnContainer {
    max-width: 240px;
    min-width: max-content;
    margin-left: auto;
    .button {
      height: $rem-48;
      border-radius: $rem-40;
    }
    @include media("t-large", max) {
      max-width: none;
    }
  }
}
