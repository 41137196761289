.telNo {
  .form__control {
    &.light {
      color: $color-neutral1;
      &:focus {
        color: $color-neutral1;
      }
    }
    &.dark {
      color: $color-neutral8;
      &:focus {
        color: $color-neutral8;
      }
    }
  }

  .flag-container {
    width: 100px !important; //override vendor css
    padding: 0 !important; //override vendor css
  }

  .selected-flag {
    display: flex !important; //override vendor css
    padding-top: 4px;
    padding-bottom: 8px;
    background-color: transparent !important; //override vendor css
  }

  .selected-dial-code {
    pointer-events: none;
    padding-left: 4px !important; //override vendor css
  }

  .arrow {
    pointer-events: none;
    font-size: 10px !important; //override vendor css
  }

  .country-list {
    max-width: 400px;
  }
  
}
.country-name {
  color: $color-neutral1 !important; //override vendor css
}