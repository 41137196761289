.guidance {
  position: relative;
  z-index: 99; //have to be on top of anything for the sake of highlighting
  border-radius: $rem-16;
  border: 1px solid $color-secondary1;
  background-color: $color-primary1;
  padding: $rem-8;

  &--light {
    background-color: #eff5fe;
  }

  &__tooltip {
    position: absolute;

    &--topStart {
      top: 0;
      left: 0;
    }
    &--topEnd {
      top: 0;
      right: 0;
    }
    &--bottomEnd {
      top: 100%;
      right: 0;
    }
    &--bottomStart {
      top: 100%;
      left: 0;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
    z-index: 98; //just 1 degree below guidance
  }
}