.requestStatement {
  letter-spacing: 0.2px;
  &__modal--title {
    font-size: $rem-12;
    line-height: 1;
    color: $color-neutral3;
    padding-bottom: $rem-4;
  }
  &__modal--info {
    font-size: $rem-14;
    line-height: 1.25;
    font-weight: $fontWeight-Bold;
  }
  &__info--accountNo,
  &__info--referenceId,
  &__info--statementCycle {
    flex: inherit;
    width: 50%;
  }
  &__disabled {
    pointer-events: none;
  }
  &__notAllowed {
    cursor: not-allowed;
  }
  @include media("t-large", max) {
    &__info--accountNo,
    &__info--referenceId,
    &__info--statementCycle {
      flex: auto;
      width: inherit;
      padding-right: 0;
    }
  }
}
