.tutorial {
  color: $color-neutral1;
  position: relative;

  &__title {
    font-weight: $fontWeight-Bold;
    padding: $rem-24 $rem-24 $rem-8;
  }

  &__content {
    padding: 0 $rem-24 $rem-24;
    line-height: 1.375;
  }

  &__control {
    padding: $rem-16 $rem-24;
    display: flex;
    align-items: center;
  }

  &__step {
    flex-grow: 1;
  }

  &__buttons {
    display: flex;
  }

  &__button {
    &:last-child {
      margin-left: $rem-8;
    }
  }

  &__progress {
    width: 100%;
    background: $color-neutral6;
    height: 4px;
  }

  &__progressBar {
    width: 0;
    background: $color-primary5;
    height: 100%;
  }

  &__closeButtonContainer {
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: $rem-8;
    right: $rem-8;
  }
  &__closeButton {
    cursor: pointer;
    width: $rem-16;
    height: $rem-16;
    color: $color-neutral1;
  }
}
