.outstandingChargeLoader {
  background: $color-neutral12;
  border-radius: $rem-16;
  &__title,
  &__info {
    height: $rem-24;
    border-radius: $rem-16;
  }
  &__title {
    top: 32px;
    left: 18px;
    max-width: 55%;
  }
  &__info {
    top: 44px;
    left: 18px;
    max-width: 45%;
  }
  &__image {
    top: -32px;
    left: -20px;
    display: flex;
    margin-left: auto;
  }
}
