.noBulkIdAnnouncement {
  &__infoContainer {
    color: $color-primary1;
    line-height: $rem-24;
    font-size: $rem-16;
  }

  &__infoTitle {
    font-weight: 600;
    padding-bottom: $rem-8;
  }

  &__announcement {
    height: fit-content;
    width: 80%;
    position: fixed;
    z-index: 1;
    left: 0;
    right: 0;
    @include media("t-large", max) {
      width: 95%;
    }
  }
}
