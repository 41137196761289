.guides {
  font-size: $rem-16;
  @include media("t-large", max) {
    padding: 0 1rem;
  }

  &__container {
    color: $color-neutral1;
    padding: $rem-48 8% 0;
    border-radius: 0;
    @include media("t-large", max) {
      padding: $rem-24 $rem-16 0;
    }
  }

  &__title {
    font-weight: $fontWeight-Bold;
    font-size: $rem-32;
    padding-bottom: $rem-32;
    @include media("t-large", max) {
      font-size: $rem-24;
      padding-bottom: 0;
    }
  }

  &__contentContainer {
    align-items: start;
  }

  &__description {
    font-size: $rem-20;
    font-weight: $fontWeight-Bold;
    text-align: center;
    @include media("t-large", max) {
      padding: $rem-16 0;
      font-size: $rem-14;
      font-weight: $fontWeight-Lighter;
    }
  }

  &__userBtn,
  &__videoBtn {
    .button {
      background: $color-dark-button;
      border-color: $color-dark-button;
    }
  }

  &__userBtn {
    margin-bottom: $rem-8;
  }
  &__divider {
    border-bottom: 1px solid $color-neutral4;
    margin-top: $rem-48;
    @include media("t-large", max) {
      margin: $rem-24 $rem-16 0 $rem-16

    }
  }
}
