.registerEmail {
  width: 100%;
  &__container {
    padding-top: $rem-72;

    @include media("t-large", max) {
      padding-top: 0;
    }
  }
  &__title {
    font-weight: 600;
    font-size: $rem-32;
    padding-bottom: $rem-16;
  }

  &__buttonContainer {
    .button {
      border-radius: $rem-40;
      height: $rem-48;
      max-width: 240px;
      min-width: max-content;

      @include media("t-large", max) {
        max-width: initial;
      }
    }
  }
  
  &__notification {
    position: relative;
    top: -90px;
    &--noError {
      visibility: hidden;
      transition: visibility 0.3s ease;
    }
    @include media("t-large", max) {
      position: fixed;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: $rem-8;
      z-index: 3;
    }
  }
}
