.block {
  min-height: 100%;
  width: 100%;
  border-radius: $rem-16;
  padding: $rem-24;

  &--light {
    background-color: $color-secondary6;
    color: $color-primary1;
  }

  &--lighter {
    background-color: $color-neutral12;
    color: $color-neutral2;
  }

  &--lighter-Red {
    background-color: $color-neutral16;
    color: $color-neutral18;
  }

  &--lighter-Orange {
    background-color: $color-neutral17;
    color: $color-neutral19;
  }

  &--lightest {
    background-color: $color-neutral8;
    color: $color-neutral1;
  }

  &--dark {
    background-color: $color-primary7;
  }
}