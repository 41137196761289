.customTooltip {
  &__tooltipBoxShadow {
    .tooltip-inner {
      box-shadow: 0px 7px 12px rgba(52, 115, 238, 0.2);
    }
  }
  &__onboardingBoxShadow {
    .tooltip-inner {
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.14841);
    }
  }
  &__dropdownBoxShadow {
    .tooltip-inner {
      box-shadow: 0px 7px 12px rgba(38, 38, 38, 0.25);
    }
  }
  &__noArrow {
    .tooltip-arrow {
      display: none;
    }
  }
}

.tooltip-inner {
  background-color: $color-neutral8;
  color: $color-neutral1;
  max-width: none;
  white-space: pre-wrap;
  text-align: left;
  width: 100%;
  height: 100%;
  padding: 0;
}

.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: $color-neutral8;
}

.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: $color-neutral8;
}

.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: $color-neutral8;
}

.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: $color-neutral8;
}

.tooltip.show {
  opacity: 1;
}
