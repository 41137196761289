.reconciliationDetails {
  &__title {
    font-size: $rem-24;
    line-height: 1.3333;
    color: $color-primary4;
  }

  &__label {
    font-size: $rem-12;
    line-height: $rem-18;
    color: $color-neutral3;
  }

  &__value {
    font-size: $rem-14;
    line-height: $rem-18;
  }

  &__btnContainer {
    justify-content: flex-end;
    padding-right: $rem-12;
    margin-top: $rem-32;

    @include media("t-large", max) {
      justify-content: center;
      padding-left: $rem-12;
    }
  }

  &__backBtnContainer {
    margin-right: $rem-24;

    @include media("t-large", max) {
      margin-right: 0;
      margin-top: $rem-16;
      order: 2;
    }
  }

  &__backBtnContainer,
  &__submitBtnContainer {
    min-width: 190px;

    .button {
      height: $rem-48;
      border-radius: $rem-40;
    }

    @include media("t-large", max) {
      min-width: 100%;

      .button {
        flex: auto;
      }
    }
  }

  &__inputField, &__displayField {
    padding: $rem-12 0;
  }

  &__displayLabel {
    color: $color-neutral3;
    font-size: $rem-12;
    line-height: 1.33;
  }

  &__displayValue {
    padding-top: $rem-4;
    font-size: $rem-16;
    line-height: 1.5;
  }
}
