.keyFeature {
  font-size: $rem-16;
  margin: 0;
  @include media("t-large", max) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &__container {
    padding: $rem-48 8%;
    padding-bottom: 3rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    @include media("t-large", max) {
      padding: $rem-24 $rem-16;
      padding-bottom: 0;
    }
  }

  &__title {
    font-size: $rem-24;
    font-weight: $fontWeight-Bold;
    padding-bottom: $rem-32;
    line-height: 32px;
    @include media("t-large", max) {
      font-size: $rem-14;
      padding: 0;
      line-height: 20px;
    }
  }

  &__featureContainer {
    justify-content: center;
    padding-left: 60px;
    padding-right:60px;
    .col {
      margin-bottom: $rem-24;
      margin-right: $rem-24;
    }
    @include media("t-large", max) {
      flex-direction: column;
      flex-wrap: nowrap;
      .col {
        margin-right: 0;
      }
    }
  }

  &__col{
    padding-right:0;
  }

  &__featureTitle {
    font-weight: $fontWeight-Bold;
    padding-top: $rem-24;
    padding-bottom: $rem-12;
    line-height: 24px;
    @include media("t-large", max) {
      padding: $rem-8 0;
      line-height: 20px;
    }
  }
  &__featureDesc {
   line-height:24px;
   @include media("t-large", max) {
    line-height: 20px;
  }
  }

  &__imgContainer {
    height: 168px;
  }

  &__img {
    max-width: 240px;
    max-height: 100%;
    @include media("m-small", max) {
      min-width: 100%;
    }
  }

  &__btnContainer {
    max-width: 240px;
    min-width: fit-content;
    margin: auto;
    @include media("t-large", max) {
      margin-bottom: $rem-16;
    }
    .button{
      margin-top:1.5rem;
    }
  }
}
