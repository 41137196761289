.pgQuery {
  width: 100%;
  padding-top: $rem-48;
  padding-left: 0;
  padding-right: 0;
  min-height: 87vh;
  @include media("t-large", max) {
    padding-top: 0;
  }
  &__notificationContainer {
    position: relative;
  }
  &__notification {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    min-width: fit-content;
    height: fit-content;
    @include media("t-large", max) {
      width: 100%;
    }
  }
  &__title {
    font-weight: $fontWeight-Bold;
    font-size: $rem-32;
    padding-bottom: $rem-16;
    @include media("t-large", max) {
      padding-bottom: $rem-12;
    }
  }
  &__description {
    @include media("t-large", max) {
      font-size: $rem-14;
    }
  }
  &__leftContainer {
    padding-left: 8%;
    padding-right: 8%;
    @include media("t-large", max) {
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-bottom: $rem-36;
    }
  }
  &__btnContainer {
    max-width: 240px;
    margin-left: auto;
    margin-bottom: $rem-56;
    min-width: max-content;
    .button {
      height: $rem-48;
      border-radius: $rem-40;
    }
    @include media("t-large", max) {
      max-width: 100%;
      margin-left: initial;
    }
  }
  &__table {
    flex-basis: 100%;
    margin-bottom: $rem-48;
  }
}