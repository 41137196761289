.dropdown {
  width: 100%;
  height: 100%;
  color: $color-neutral1;

  &__box {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  &__selectedText {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    display: inline-block;
  }
  
  &__searchableBox {
    cursor: pointer;
  }

  &__label {
    position: relative;
    transform: translateY(4px);
    opacity: 0;
    transition: 0.2s;
    transition-property: transform, opacity;
    font-size: $rem-12;
    line-height: 1.33;
    &--active {
      transform: translateY(0);
      opacity: 1;
    }
    &--light {
      color: $color-neutral3;
    }
    &--dark {
      color: $color-neutral6;
    }
  }

  &__errorMessage {
    font-size: $rem-12;
    color: $color-warning;
    margin-top: $rem-4;
  }

  &__triggerColor {
    &--dark {
      color: $color-neutral8;
      fill: $color-neutral8;
    }
    &--error {
      color: $color-warning;
      fill: $color-warning;
    }
    &--lightLabel {
      color: $color-neutral3;
    }
  }

  &__triggerContainer {
    &--button {
      background-color: $color-neutral8;
      border: $rem-1 solid $color-secondary5;
      border-radius: $rem-6;
      text-align: center;
      padding: $rem-4 $rem-16 $rem-4 $rem-16;
      font-size: $rem-14;
      line-height: $rem-20;
    }
    &--textbox {
      padding: $rem-4 $rem-8 $rem-8 0rem;
      border-bottom: $rem-1 solid $color-primary5;
      &--dark {
        border-color: $color-neutral8;
      }
      &--light {
        border-color: $color-neutral3;
      }
      &--error {
        border-color: $color-warning;
      }
    }
  }
  &__triggerImage {
    &--button {
      width: $rem-12;
      min-width: $rem-12;
      margin-left: $rem-8;
    }
    &--textbox {
      width: $rem-24;
      min-width: $rem-24;
      margin-left: auto;
    }
  }
  &__tooltipContainer {
    padding: 0;
    .tooltip-inner {
      border-radius: $rem-8;
    }
  }
}

.dropdownPopover {
  text-align: center;
  border-radius: $rem-8;
  overflow: hidden;
  max-height: 342px;
  overflow-y: auto;
  &__margin {
    &--button {
      margin-top: $rem-10;
    }
    &--textbox {
      margin-top: $rem-16;
    }
  }
  &__item {
    cursor: pointer;
    font-weight: $fontWeight-Bold;
    &:hover {
      background-color: $color-primary5;
      color: $color-neutral8;
    }
    &--textbox {
      text-align: left;
      padding-left: $rem-12;
    }
  }
  &__itemSoftSelected {
    background-color: $color-primary5;
    color: $color-neutral8;
  }
}
