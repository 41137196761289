.timeoutModal {
  .modal-dialog > .modal-content {
    .modal-footer {
      display: none;
    }
  }
  &__timeoutIcon {
    fill: $color-error-lightBackground;
    width: $rem-56;
    height: $rem-56;
    margin-bottom: -20px;
  }
  &__modalTitle {
    font-weight: $fontWeight-Bold;
  }

  &__buttonContainer {
    height: $rem-48;
    margin-top: $rem-32;
  }

  &__buttons {
    width: 240px;
    padding: 0;
    min-width: max-content;
    .timeoutModal__button {
      border-radius: $rem-40;
      height: 3rem;
    }
  }
  @include media("t-large", max) {
    &__buttonContainer {
      margin-top: $rem-24;
      .button {
        max-width: none;
      }
    }
  }
}
