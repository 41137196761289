.adminManageAccount {
  &__notificationContainer {
    position: relative;
  }
  &__notification {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    min-width: fit-content;
    height: fit-content;
    @include media("t-large", max) {
      width: 100%;
    }
  }
  &__backContainer {
    cursor: pointer;
    width: fit-content;
    &:hover {
      color: $color-secondary1;
      .adminManageAccount__backIcon {
        fill: $color-secondary1;
      }
    }
  }
  &__backIcon {
    width: $rem-24;
    height: $rem-24;
    fill: $color-chevron-icon;
  }
  &__pageHeader {
    text-align: left;
  }
}
