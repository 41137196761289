.transactionModalLoader {
  z-index: 2;
  background: $color-secondary6;
  &__col {
    height: $rem-48;
  }
  &__button {
    margin-top: $rem-12;
    height: $rem-24;
  }
  &__table {
    margin-top: $rem-24;
    height: $rem-156;
  }
}
