.searchPGResult {
  &__label {
    font-size: $rem-12;
    line-height: $rem-18;
    color: $color-neutral3;
  }

  &__value {
    font-size: $rem-14;
    line-height: $rem-18;
  }

  &__lastQuery {
    font-size: $rem-12;
    line-height: $rem-18;
    color: $color-neutral3;
    font-style: italic;
    margin-top: $rem-12;
    
    @include media("t-large", max) {
      text-align: left;
    }
  }
}