.firstTimePwdReset {
  min-height: 100vh;
  letter-spacing: 0.2px;
  &__logo {
    width: 150px;
    height: auto;
    margin-left: $rem-32;
    padding: 0;
    margin-bottom: $rem-40;
    cursor: pointer;
  }

  &__pageContent {
    padding-top: $rem-72;
    @include media("t-large", max) {
      padding-top: 0;
    }
  }

  &__imgContainer {
    justify-content: center;
  }

  &__img {
    @include media("t-large", max) {
      max-width: 40%;
      min-width: 250px;
    }
  }

  &__contentContainer {
    position: relative;
    padding-bottom: $rem-40;
  }

  &__notification {
    position: relative;
    top: -90px;
    &--noError {
      visibility: hidden;
      transition: visibility 0.3s ease;
    }
    @include media("t-large", max) {
      position: fixed;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: $rem-8;
      z-index: 3;
    }
  }

  &__title {
    font-weight: 600;
    font-size: $rem-32;
    padding-bottom: $rem-16;
  }

  &__button {
    .button {
      padding: $rem-10;
      border-radius: $rem-40;
      height: $rem-48;
    }
    &--cancel {
      margin-right: 1.5rem;
    }
    @include media("t-large", max) {
      .button {
        flex: auto;
      }
      &--cancel {
        order: 2;
        margin-right: 0;
        margin-top: 1rem;
      }
    }
  }
}
