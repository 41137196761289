.datebox {
  position: relative;
  cursor: pointer;

  &__icon {
    cursor: pointer;
    height: 24px;
    width: 24px;
  }

  &__trigger {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  // &__popover {
  //   padding-top: $rem-16;
  // }
  
  &__dateRangeApply {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: $rem-8;

    .button {
      justify-content: flex-end;
    }
  }
}