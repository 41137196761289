.paymentDetails {
  position: relative;
  @include media("t-large", max) {
    &__infoContainer {
      flex-direction: column;
    }
  }
  &__backContainer {
    cursor: pointer;
    width: fit-content;
    &:hover {
      color: $color-secondary1;
      .paymentDetails__backIcon {
        fill: $color-secondary1;
      }
    }
  }
  &__backIcon {
    width: $rem-24;
    height: $rem-24;
    fill: $color-chevron-icon;
  }
  &__pageHeader {
    padding-bottom: $rem-32;
  }
  &__block {
    padding: $rem-32;
  }
  &__detailTitle {
    font-size: $rem-12;
    line-height: $rem-16;
    color: $color-neutral3;
  }
  &__detailInfo {
    font-size: $rem-16;
    line-height: $rem-24;
    color: $color-neutral1;
  }
  &__statusActive {
    color: $color-positive;
  }
  &__statusInactive {
    color: $color-neutral3;
  }
  &__btnContainer {
    justify-content: flex-end;
    padding-right: $rem-12;
    @include media("t-large", max) {
      justify-content: center;
      padding-left: $rem-12;
    }
  }
  &__removeBtnContainer,
  &__editBtnContainer {
    max-width: 190px;
    min-width: max-content;
    .button {
      height: $rem-48;
      border-radius: $rem-40;
    }
    @include media("t-large", max) {
      max-width: 100%;
      .button {
        flex: auto;
      }
    }
  }
  &__removeBtnContainer {
    margin-right: $rem-24;
    @include media("t-large", max) {
      margin-right: 0;
      margin-top: $rem-16;
      order: 2;
    }
    .button {
      color: $color-error-lightBackground;
      border-color: $color-error-lightBackground;
      &:hover {
        color: $color-error-lightBackground;
        border-color: $color-error-lightBackground;
      }
    }
  }
  &__requestBtnContainer {
    width: 240px;
    height: $rem-48;
    min-width: max-content;
    .button {
      border-radius: $rem-40;
      height: $rem-48;
    }
    @include media("t-large", max) {
      align-items: initial;
      margin-top: $rem-16;
      width: 100%;
    }
  }
  
  &__notification {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    min-width: fit-content;
    height: fit-content;
    @include media("t-large", max) {
      width: 100%;
    }
  }
}
