//bootstrap's reference
// xs (for phones - screens less than 768px wide)
// sm (for tablets - screens equal to or greater than 768px wide)
// md (for small laptops - screens equal to or greater than 992px wide)
// lg (for laptops and desktops - screens equal to or greater than 1200px wide)

/* Mixin for Media Queries */

$breakpoints: (
  "m-small": 320px,
  "m-medium": 480px,
  "m-large": 600px,
  "t-small": 720px,
  "t-medium": 768px,
  "t-large": 992px,
  "d-small": 1024px,
  "d-medium": 1080px,
  "d-large": 1140px,
  "d-xlarge": 1280px,
);

/// @param {string} $width
/// Define the media breakpoint width by a name listed in $breakpoints

/// @param {string} $type
/// type of the width whether its max-width or min-width as "min" or "max"

@mixin media($width, $type: min) {
  /// Checks whether $breakpoints contains a value associated with $width.
  @if map_has_key($breakpoints, $width) {
    /// Set the value of $width associated with $breakpoints.
    $width: map_get($breakpoints, $width);

    @if $type == max {
      $width: $width - 1;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

/// @param {string} $min-width
/// Define the media breakpoint min-width by a name listed in $breakpoints

/// @param {string} $max-width
/// Define the media breakpoint max-width by a name listed in $breakpoints

@mixin media-mix($min-width, $max-width) {
  @if map_has_key($breakpoints, $min-width) and map_has_key($breakpoints, $max-width) {
    /// Set the value of $width associated with $breakpoints.
    $min-width: map_get($breakpoints, $min-width);
    $max-width: map_get($breakpoints, $max-width);

    @media only screen and (min-width: $min-width) and (max-width: ($max-width - 1)) {
      @content;
    }
  }
}

@mixin media-mix-device($min-width, $max-width) {
  @if map_has_key($breakpoints, $min-width) and map_has_key($breakpoints, $max-width) {
    /// Set the value of $width associated with $breakpoints.
    $min-width: map_get($breakpoints, $min-width);
    $max-width: map_get($breakpoints, $max-width);

    @media only screen and (min-device-width: $min-width) and (max-device-width: ($max-width - 1)) {
      @content;
    }
  }
}

@mixin media-with-orientation($orientation, $mix) {
  @if $mix == "mix" {
    @media (max-width: ($max-width - 1)) and (min-width: $min-width) and (orientation: $orientation) {
      @content;
    }
  } @else {
    @media only screen and (orientation: $orientation) {
      @content;
    }
  }
}
