.review {
  position: relative;

  @include media("t-large", min) {
    &__transactionSummary, &__paymentSummary {
      position: sticky;
      top: $rem-24;
      align-self: flex-start;
    }
  }

  &__fpxDowntimeAnnouncement {
    height: fit-content;
    z-index: 4;
    width: 80%;
    box-shadow: 0px 7px 12px rgba(52, 115, 238, 0.2);
    color: $color-primary1;
    line-height: $rem-24;
  }
}

.transactionSummary {
  &__title {
    font-size: $rem-24;
    line-height: 1.3333;
    color: $color-primary4;
  }

  &__table {
    padding-top: $rem-32;
    padding-bottom: $rem-32;
    overflow-y: auto;
  }

  &__note {
    color: $color-neutral1;
    font-size: $rem-14;
    line-height: 1.4286;
  }
}

.paymentSummary {
  &__details {
    padding-top: $rem-24;
    border-top: 1px solid $color-neutral5;
  }

  &__detail {
    padding-bottom: $rem-24;
  }

  &__detailTitle {
    line-height: 1.375;
    color: $color-primary1;
  }

  &__detailValue {
    font-weight: $fontWeight-Bold;
    font-size: $rem-24;
    line-height: 1.3333;
    color: $color-neutral1;

    &Prefix {
      font-size: $rem-16;
      line-height: 2;
    }
  }

  &__title {
    font-size: $rem-24;
    line-height: 1.3333;
    color: $color-primary4;
  }

  &__setDefaultMode {
    padding-top: $rem-24;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__setDefaultText {
    color: $color-primary1;
    fill: $color-primary1;
    line-height: 1.5;

    &--left {
      cursor: pointer;
    }
  }

  &__change {
    color: $color-primary5;
    fill: $color-primary5;
    line-height: 1.5;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;
  }

  &__changeIcon {
    display: inline-block;
    padding-left: $rem-12;
    height: $rem-20;
    width: $rem-32;

    &--left {
      padding-left: 0;
      padding-right: $rem-12;
    }
  }

  &__payOptions {
    padding: $rem-24 0;
  }

  &__payOption {
    display: flex;
    align-items: center;
    justify-content: stretch;
    padding: $rem-24;
    margin-bottom: $rem-16;
    border-radius: $rem-8;
    background-color: $color-neutral8;
    border: 2px solid $color-secondary4;
    cursor: pointer;

    &--active {
      cursor: default;
      background-color: $color-secondary5;
      border: 2px solid $color-primary4;

      .paymentSummary__payOptionChecked {
        display: block;
      }
    }

    &--disable {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &__payOptionIcon {
    width: 32px;
    margin-right: $rem-24;
  }

  &__payOptionTitle {
    flex: 1;
  }

  &__payOptionChecked {
    display: none;
    height: 24px;
    width: 24px;
  }

  &__saveLabel {
    color: $color-neutral1;
    line-height: 1.5;
    font-weight: $fontWeight-Bold;
  }
  
  &__saveCheckbox {
    margin-right: $rem-8;
  }

  &__pleaseEnsure {
    color: $color-neutral1;
    font-weight: $fontWeight-Lighter;
    padding: $rem-24 0;
  }

  &__inputField {
    padding: $rem-12 0;
  }

  &__statement {
    color: $color-primary5;
    line-height: 1.5;
    margin-top: $rem-16;
    margin-bottom: $rem-32;
    cursor: pointer;
  }
  
  &__inputFieldIcon {
    cursor: pointer;
    height: 24px;
    width: 24px;
  }

  &__addMore {
    color: $color-primary5;
    fill: $color-primary5;
    font-size: $rem-14;
    line-height: 1.4286;
    padding: 1rem 0;
    cursor: pointer;
    width: fit-content;

    &--disabled {
      color: $color-neutral5;
      fill: $color-neutral5;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &__addMoreLabel {
    padding-left: $rem-8;
  }

  &__buttons {
    display: flex;

    button {
      &:last-child {
        margin-left: $rem-16;
      }
    }
    @include media("t-large", max) {
      flex-direction: column-reverse;
      button {
        &:last-child {
          margin-left: 0;
          margin-bottom: $rem-16;
        }
      }
    }
  }
}
