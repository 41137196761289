.groupDetailsLoader {
  background: $color-secondary6;
  z-index: 1;
  &__data {
    height: $rem-36;
    border-radius: $rem-16;
  }
  &__button {
    height: $rem-48;
    border-radius: $rem-40;
    max-width: 240px;
    display: flex;
    margin-left: auto;
  @include media("t-large", max) {
    max-width: none;
  }
  }
  @include media("t-large", max) {
    &__row {
      flex-direction: column;
    }
    &__firstBtn,
    &__data {
      margin-bottom: $rem-16;
    }
  }
}
