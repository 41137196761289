.groupDetails {
  &__block {
    padding: $rem-32;
  }
  &__topContainer {
    @include media("t-large", max) {
      flex-direction: column;
    }
  }
  &__infoContainer {
    @include media("t-large", max) {
      flex-direction: column;
      margin-bottom: $rem-16;
    }
  }
  &__nameContainer {
    margin-right: $rem-100;
    @include media("t-large", max) {
      margin-right: 0;
      margin-bottom: $rem-16;
    }
  }
  &__label {
    font-size: $rem-12;
    color: $color-neutral3;
  }
  &__data {
    font-size: $rem-16;
    color: $color-neutral1;
  }
  &__btnContainer {
    justify-content: flex-end;
    @include media("t-large", max) {
      flex-direction: column;
    }
  }
  &__leftBtnContainer, &__rightBtnContainer {
    min-width: 184px;
    .button {
      height: $rem-48;
      border-radius: $rem-40;
    }
  }
  &__leftBtnContainer {
    margin-right: $rem-24;
    @include media("t-large", max) {
      margin-right: 0;
      margin-bottom: $rem-16;
    }
  }
  &__removeBtnColor {
    .button {
      color: $color-error-lightBackground;
      border-color: $color-error-lightBackground;
      &:hover {
        color: $color-error-lightBackground;
        border-color: $color-error-lightBackground;
      }
    }
  }
}