.dataTableLoader {
  z-index: 4;
  background: $color-secondary6;
  &__borderRadius {
    border-radius: $rem-16;
  }
  &__title {
    height: $rem-30;
    width: 40%;
  }
  &__filter {
    height: $rem-24;
  }
  &__graph {
    margin-top: $rem-16;
    height: 65%;
  }
  &__tableBottom {
    margin-top: $rem-16;
    height: $rem-24;
    max-width: 220px;
  }
  &__tableBottomContainer {
    text-align: right;
  }
  @include media("t-large", max) {
    &__filter {
      margin-top: $rem-8;
      width: 25%;
    }
    &__tableBottom {
      margin-top: $rem-8;
    }
    &__tableBottomContainer {
      display: none;
    }
    &__graph {
      height: 55%;
    }
  }
}
