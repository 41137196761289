.userManagementTable {
  &__title {
    color: $color-primary4;
    font-size: $rem-24;
    line-height: 1.3333;
    margin-top: $rem-32;
    margin-bottom: $rem-12;
  }
  &__statusIcon {
    cursor: pointer;
    width: $rem-20;
    fill: $color-neutral8;

    &:hover {
      fill: $color-secondary1;
    }
  }
  &__statusTooltip {
    white-space: pre-wrap;
    margin-top: $rem-8;
    max-width: 600px;
    font-size: $rem-16;
    line-height: $rem-24;
  }
  &__action {
    display: flex;
    align-items: flex-end;
  }

  &__actionButton {
    cursor: pointer;
    color: $color-neutral2;
    fill: $color-neutral2;
    padding: 0 $rem-8;

    &:hover {
      color: $color-primary4;
      fill: $color-primary4;
    }

    svg {
      height: $rem-20;
      width: $rem-20;
    }

    &--empty {
      padding: 0 $rem-8;

      &::before {
        content: '';
        display: block;
        height: $rem-20;
        width: $rem-20;
      }
    }
  }
  &__export {
    width: fit-content;
    margin-top: $rem-16;
    margin-bottom: $rem-8;
  }
  &__button {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $color-primary5;
    fill: $color-primary5;
  }
  &__buttonLogo {
    height: $rem-24;
    width: $rem-24;
    padding-right: $rem-8;
  }
}