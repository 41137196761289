.app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-image: linear-gradient(0deg, $background-primaryGradient-0 20%, $background-primaryGradient-100 100%);

  & > .app-master-layout {
    min-height: 50vh;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__logo {
    width: 100px;
    height: auto;
  }
}
