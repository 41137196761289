.manageUsersPage {
  &__block {
    padding: $rem-32;
  }
  &__notificationContainer {
    position: relative;
  }
  &__notification {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    min-width: fit-content;
    height: fit-content;
    @include media("t-large", max) {
      width: 100%;
    }
  }
  &__viewActivityLogLink {
    color: #1654cc;
    margin-bottom: $rem-1;
    cursor: pointer;
    float: right;
  }
  &__disabled {
    cursor: not-allowed;
    color: $color-neutral5;
  }
  &__statusTooltip {
    white-space: pre-wrap;
    margin-top: $rem-8;
    max-width: 400px;
    font-size: $rem-16;
    line-height: 1.5;
  }
  &__statusActive {
    color: $color-positive;
  }
  &__statusInactive {
    color: $color-neutral3;
  }
  &__statusIcon {
    cursor: pointer;
    width: $rem-20;
    fill: $color-neutral8;

    &:hover {
      fill: $color-secondary1;
    }
  }
  &__buttonContainer {
    max-width: 240px;
    margin-left: auto;
    margin-right: 0;
    min-width: max-content;
    @include media("t-large", max) {
      max-width: 100%;
    }
  }
  &__button {
    .button {
      border-radius: $rem-40;
      min-height: $rem-48;
    }
  }
  &__cursor {
    cursor: pointer;
    color: $color-neutral1;
    fill: $color-neutral1;
    &:hover {
      color: $color-primary4;
      fill: $color-primary4;
    }
  }
  &__disabled {
    cursor: not-allowed;
  }
  &__tableIcon {
    width: $rem-20;
    height: $rem-20;
  }
  &__roleDescriptionSmall {
    color: $color-neutral5;
    font-size: $rem-14;
  }
  &__topCard {
    overflow-y: hidden;
    overflow-x: auto;
    margin-bottom: $rem-24;
  }
}
