.billSummary {
  position: sticky;
  bottom: 0;
  background-color: $color-secondary7;
  border-top-left-radius: $rem-16;
  border-top-right-radius: $rem-16;
  box-shadow: 0px -4px 12px $color-neutral15;
  z-index: 4; //stay beyond table's header, checkbox, loader
  margin-left: -$rem-72;
  margin-right: -$rem-72;

  @include media("t-large", max) {
    margin-left: -$rem-16;
    margin-right: -$rem-16;
  }

  &__container {
    padding: $rem-24 $rem-72;

    @include media("d-xlarge", max) {
      padding: $rem-12 $rem-16;
    }
  }

  &__title {
    color: $color-primary1;
    font-size: $rem-24;

    @include media("t-large", max) {
      font-size: $rem-14;
      line-height: 1.7143;
    }
  }

  &__subject {
    @include media("t-large", max) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $rem-8;
    }

    &--rightBorder {
      border-right: 1px solid $color-neutral5;
    }
  }

  &__subtitle {
    color: $color-primary1;
    margin-bottom: $rem-8;

    @include media("t-large", max) {
      font-size: $rem-10;
      line-height: 1.2;
      flex-basis: fit-content;
      margin-bottom: 0;
    }
  }

  &__value {
    color: $color-neutral1;
    font-size: $rem-24;
    line-height: $rem-32;
    font-weight: $fontWeight-Bold;

    @include media("t-large", max) {
      font-size: $rem-16;
      line-height: 1.5;
      padding-left: $rem-8;
      flex-grow: 1;
      text-align: right;
    }
  }

  &__value-small {
    font-weight: $fontWeight-Bold;
    color: $color-neutral1;
  }

  &__note {
    font-size: $rem-14;
    line-height: $rem-20;
    color: $color-neutral1;
  }

  &__buttons {
    margin-top: $rem-8;
  }

  button.billSummary__button {
    @include media("t-large", max) {
      padding-top: $rem-8;
      padding-bottom: $rem-8;
      font-size: $rem-12;
    }
  }
}
