.textboxTooltip {
  white-space: pre-wrap;
  border-radius: $rem-4;
  margin-top: $rem-8;
  max-width: 400px;
  font-size: $rem-16;
  &__title {
    font-weight: $fontWeight-Bold;
  }
  &__icon {
    width: $rem-24;
    cursor: pointer;
    &:hover {
      fill: $color-secondary1 !important;
    }
  }
}

.commonTooltip {
  white-space: pre-wrap;
  border-radius: $rem-4;
  margin-top: $rem-8;
  max-width: 400px;
  font-size: $rem-16;
  &__title {
    font-weight: $fontWeight-Bold;
  }
  &__icon {
    width: $rem-24;
    fill: $color-neutral8;
    cursor: pointer;
    &:hover {
      fill: $color-secondary1 !important;
    }
  }
}