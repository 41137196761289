.reviewerRemarks {
  position: sticky;
  bottom: 0;
  z-index: 4; //stay beyond table's header, checkbox, loader
  box-shadow: 0px -4px 12px $color-neutral15;
  margin-left: -$rem-72;
  margin-right: -$rem-72;

  @include media("t-large", max) {
    margin-left: -$rem-16;
    margin-right: -$rem-16;
  }

  &__container {
    padding: 0;
  }

  &__containerUp {
    background-color: $color-table-hover;
    padding: $rem-16 $rem-72;

    @include media("t-large", max) {
      padding: $rem-12 $rem-16;
    }
  }

  &__upHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color-primary4;
    padding-bottom: $rem-16;
  }

  &__by {
    color: $color-neutral1;
    font-size: $rem-14;
  }

  &__containerDown {
    background-color: $color-primary1;
    padding: $rem-16 $rem-72;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media("t-large", max) {
      padding: $rem-12 $rem-16;
      justify-content: center;
      flex-direction: column;
    }
  }

  &__subHeader {
    font-size: $rem-24;
    @include media("t-large", max) {
      margin-bottom: $rem-12;
    }
    @include media("m-large", max) {
      font-size: $rem-16;
    }
  }

  &__btnsContainer {
    display: flex;
    overflow-y: auto;
  }

  &__btnContainer {
    max-width: 240px;
    min-width: fit-content;
    .button {
      border-radius: $rem-40;
      height: $rem-48;
    }
    @include media("m-large", max) {
      max-width: 100%;
    }
  }

  &__requestBtn,
  &__rejectBtn,
  &__rejectBtnDisabled {
    margin-right: $rem-16;
  }

  &__rejectBtn {
    .button {
      color: $color-error-lightBackground;
      border-color: $color-error-lightBackground;
      &:hover {
        color: $color-error-lightBackground;
        border-color: $color-error-lightBackground;
      }
    }
  }
}
