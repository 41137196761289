.accountInfoInput {
  &__leftContainer {
    margin-right: $rem-40;
    @include media("t-large", max) {
      margin-right: 0;
    }
  }
  &__rightContainer {
    margin-right: $rem-48;
    @include media("t-large", max) {
      margin-right: 0;
    }
  }
  &__postalStateContainer {
    @include media("t-large", max) {
      flex-direction: column;
    }
  }
  &__postalContainer {
    padding-top: 3px;
  }
  &__btnContainer {
    justify-content: flex-end;
    padding-right: $rem-12;
    margin-top: $rem-32;
    @include media("t-large", max) {
      justify-content: center;
      padding-left: $rem-12;
    }
  }
  &__cancelBtnContainer,
  &__saveBtnContainer {
    max-width: 190px;
    min-width: max-content;
    .button {
      height: $rem-48;
      border-radius: $rem-40;
    }
    @include media("t-large", max) {
      max-width: 100%;
      .button {
        flex: auto;
      }
    }
  }
  &__cancelBtnContainer, &__modalCancelBtnContainer {
    margin-right: $rem-24;
    @include media("t-large", max) {
      margin-right: 0;
      margin-top: $rem-16;
      order: 2;
    }
  }
  &__modalCancelBtnContainer,
  &__modalConfirmBtnContainer {
    min-width: 190px;
    .button {
      height: $rem-48;
      border-radius: $rem-40;
    }
    @include media("t-large", max) {
      min-width: 100%;
      .button {
        flex: auto;
      }
    }
  }
  &__modalIcon {
    fill: $color-primary5;
    width: $rem-56;
    height: $rem-56;
  }
  &__modalBtnContainer {
    @include media("t-large", max) {
      flex-direction: column;
    }
  }

  &__label {
    font-size: $rem-12;
    padding-bottom: $rem-4;
    color: $color-neutral6;
  }
}
