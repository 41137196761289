.carousel {
  height: 100%;

  &-inner {
    height: 100%;
  }

  &__item {
    height: 100%;
  }

  &__image {
    background-size: 100% 100%;
    width: 100%;
  }

  &__caption {
    font-size: $rem-12;
    line-height: 1.3333;
    bottom: 2.5rem;

    @include media("t-medium") {
      font-size: $rem-20;
      line-height: 1.4;
    }

    &--left {
      text-align: left;

      @include media("t-large", max) {
        text-align: center;
      }
    }

    &--right {
      text-align: right;
    }

    &--center {
      text-align: center;
    }
  }

  &__headline {
    font-size: $rem-20;
    font-weight: $fontWeight-Bold;
    line-height: 1.4;
    padding-bottom: 16px;

    @include media("t-medium") {
      font-size: $rem-36;
      line-height: 1.2;
    }
  }

  .carousel-control-next,
  .carousel-control-prev {
    &:hover {
      color: $color-secondary1;
    }
  }

  &--noArrow {
    .carousel-control-next {
      right: 48px;
      bottom: 1rem;
      line-height: 28px;
      color: $color-primary5;
    }
    .carousel-control-prev {
      left: 48px;
      bottom: 1rem;
      line-height: 28px;
      color: $color-primary5;
    }
  }

  &__caretIcon {
    width: $rem-16;
    height: $rem-16;
    fill: $color-neutral5;
    &:hover {
      fill: $color-secondary1;
    }
  }
}
