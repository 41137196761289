.latestBillSummaryLatestLoader {
  background: rgb(25, 49, 148);
  display: flex;
  flex-wrap: wrap;
  &__infoLeft,
  &__infoRight {
    height: $rem-24;
    margin-bottom: $rem-12;
  }
  &__infoRight {
    margin-left: $rem-30;
    width: 80%;
  }
  @include media("t-large", max) {
    display: initial;
    height: max-content;
    &__infoLeft {
      width: 100%;
    }
    &__infoRightContainer {
      display: none;
    }
  }
}

.latestBillSummaryTotalLoader {
  background: rgb(25, 49, 148);
  display: flex;
  height: auto;
  flex-wrap: wrap;
  flex-direction: column;
  &__info {
    height: $rem-24;
    margin-bottom: $rem-16;
    &--big {
      height: $rem-40;
    }
  }
  &__secondTotal {
    @include media("t-large", max) {
      display: none;
    }
  }
}