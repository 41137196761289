.stepProgressBar {
  color: $color-neutral4;
  text-align: center;
  font-weight: 600;
  padding-bottom: $rem-8;
  overflow-x: auto;
  justify-content: center;
  @include media("t-large", max) {
    justify-content: flex-start;
  }

  &__stepContainer {
    min-width: 160px;
    &--active {
      color: $color-primary4;
      .stepProgressBar__step {
        border-color: $color-primary4;
      }
    }
  }

  &__check {
    width: $rem-36;
    height: $rem-36;
    fill: $color-secondary6;
    .icon-check-filled {
      fill: $color-primary4;
    }
  }

  &__step {
    border-radius: 50%;
    width: $rem-36;
    height: $rem-36;
    border: $rem-4 solid $color-neutral4;
    font-size: $rem-16;
  }

  &__description {
    padding: $rem-10 $rem-10 0 $rem-10;
  }

  &__bar {
    height: $rem-4;
    border: $rem-4 solid $color-neutral4;
    background-color: $color-neutral4;
    border-radius: $rem-64;
    margin: 14px -44px 0px -44px;
    min-width: 80px;

    &--active {
      border-color: $color-primary4;
      background-color: $color-primary4;
      color: $color-primary4;
    }
  }
}
