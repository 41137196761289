.businessType {
  &__logo {
    width: 150px;
    height: auto;
    margin-left: $rem-32;
    padding: 0;
    margin-bottom: $rem-40;
  }

  &__announcement {
    width: 75%;
    @include media("t-large", max) {
      width: 95%;
    }
  }

  &__announcementContainer {
    color: $color-primary1;
    line-height: $rem-24;
    font-size: $rem-16;
  }

  &__announcementTitle {
    font-weight: 600;
    padding-bottom: $rem-8;
  }

  &__announcementLink {
    color: $color-primary5;
  
    &:hover {
      color: $color-primary4;
    }
  
    &:link {
      color: $color-primary4;
    }
  }  

  &__roleContainer {
    justify-content: center;
    overflow-x: scroll;
    flex-wrap: nowrap;
    @include media("t-large", max) {
      justify-content: initial;
    }
  }

  &__tick {
    margin-left: auto;
    position: absolute;
    top: $rem-16;
    right: $rem-16;

    &--notSelected {
      visibility: hidden;
    }
  }

  &__role {
    background: $color-secondary6;
    border-radius: $rem-8;
    text-align: center;
    width: 184px;
    color: rgb(73, 73, 74);
    font-size: $rem-14;
    cursor: pointer;
    position: relative;
    transition: 0.5s;
    &--selected {
      transform: scaleY(1.15) scaleX(1.1);
    }
    &--NA {
      pointer-events: none;
    }
    &--notSelected {
      opacity: 0.5;
    }
  }

  &__roleTitle {
    color: rgb(28, 121, 202);
    font-size: $rem-16;
    padding-top: $rem-8;
    padding-bottom: $rem-8;
  }

  &__businessImg {
    width: $rem-56;
    height: $rem-56;
  }

  &__customerImg {
    width: $rem-88;
    height: $rem-88;
  }

  &__developerImg {
    width: $rem-64;
    height: $rem-64;
  }

  &__contractorImgContainer {
    position: relative;
    height: $rem-64;
    width: $rem-64;
  }

  &__contractorImg1 {
    position: absolute;
    top: $rem-16;
    left: $rem-12;
  }

  &__contractorImg2 {
    position: absolute;
    left: 0;
  }

  &__ribbon {
    position: absolute;
    top: -8px;
    left: -8px;
  }

  &__buttonRow {
    margin-bottom: $rem-48;
    @include media("t-large", max) {
      margin-bottom: $rem-20;
    }
  }

  &__buttonContainer {
    width: 240px;
    height: $rem-48;
    min-width: max-content;
    .button {
      height: 100%;
      border-radius: $rem-40;
    }
  }

  &__announcementLink,
  &__announcementLink:link {
    color: $color-primary5;

    &:hover {
      color: $color-primary4;
    }
  }

  &:link {
    color: $color-primary4;
  }
}
