.listCarousel {
  .carousel-control-prev {
    left: 0;
    width: $rem-16;

    &:hover {
      color: $color-secondary1;
    }
  }
  
  .carousel-control-next {
    right: 0;
    width: $rem-16;

    &:hover {
      color: $color-secondary1;
    }
  }

  &__itemRow {
    display: flex;
    color: $color-neutral8;
    background-color: $color-primary8;
    border-radius: $rem-32;
    padding: $rem-8 $rem-16;
    margin-bottom: $rem-8;
    align-items: center;

    &--empty {
      background: none;
    }
  }

  &__itemRowCallback {
    cursor: pointer;
    &:hover {
      .listCarousel__itemKey,
      .listCarousel__itemValue {
        color: $color-secondary1;
      }
    }
  }

  &__itemKey,
  &__itemValue {
    padding: 0;
    flex: 1;
  }

  &__itemValue {
    text-align: right;
  }

  &__caret {
    width: $rem-16;
    height: $rem-16;
    fill: $color-neutral5;
    &:hover { 
      fill: $color-secondary1;
    }
  }
}
