.table {
  font-size: 14px;
  width: 100%;
  overflow-x: auto;
  margin: 0px;

  &.full-width-always {
    overflow-x: hidden;
    .header {
      & > .tr {
        width: 100% !important;
      }
    }
    .body {
      & > .tr {
        width: 100% !important;
      }
    }
    //Scroll columns in smaller screens
    @include media("t-large", max) {
      overflow-x: scroll;
      .header {
        & > .tr {
          width: fit-content !important;
        }
      }
      .body {
        & > .tr {
          width: fit-content !important;
        }
      }
    }
  }

  & > :not(:first-child) {
    border: 0px;
  }

  .header {
    margin: 0 auto;
    width: 100%;
    & > .tr {
      &:first-child {
        & > .th {
          &:first-child {
            border-top-left-radius: 16px;
          }
          &:last-child {
            border-top-right-radius: 16px;
          }
        }
      }
    }

    &--disabled {
      pointer-events: none;
      .th-icon {
        opacity: 0.25;
      }
    }
  }

  .body {
    border: 0px;
    & > .tr {
      &:last-child {
        & > .td {
          &:first-child {
            border-bottom-left-radius: 16px;
          }
          &:last-child {
            border-bottom-right-radius: 16px;
          }
        }
      }
    }
  }

  .tr {
    margin: 0 auto;
    :last-child {
      .td {
        margin-bottom: 0;
      }
    }
    &__hover {
      &:hover > .td {
        background-color: $color-table-hover;
      }
    }
    &__clickable {
      &:hover > .td {
        cursor: pointer;
        background-color: $color-table-hover;
      }
    }
  }

  .th,
  .td {
    padding: 12px 16px;
    background-color: #fff;
    overflow: auto;
    flex-grow: 1;
    position: relative;

    &.cell-editable {
      padding: 0px;

      &.gap-left {
        border-left: 4px solid #eff5fe;
      }
      &.gap-right {
        border-right: 4px solid #eff5fe;
      }
    }

    &--isLoading::after {
      content: '';
      position: absolute;
      border: 2px solid $color-neutral7;
      border-top: 2px solid $color-primary5;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      top: 50%;
      right: 8px;
      animation: spin 2s linear infinite;
    }

    @keyframes spin {
      0% { transform: translateY(-50%)rotate(0deg); }
      100% { transform: translateY(-50%)rotate(360deg); }
    }

    &.cell-not-editable {
      background-color: $color-table-hover;
    }

    &--error {
      background-color: $color-error-inputBackground !important;
    }

    &--noFlexGrow {
      flex-grow: 0;
    }

    .resizer {
      display: inline-block;
      width: 5px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(50%);
      z-index: 1;

      &.isResizing {
        background: red;
      }
    }

    .editable-cell {
      border-left: 4px solid #eff5fe;
      border-right: 4px solid #eff5fe;
    }
  }

  .th {
    padding: 16px;
    color: #fff;
    background-color: rgba(22, 84, 204, 1);
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;

    &[data-sticky-td="true"] {
      background-color: rgba(10, 68, 178, 1);
    }

    .th-icon {
      margin-left: 8px;
      height: 20px;
      width: 20px;
    }
  }

  .td {
    margin-bottom: 4px;
  }

  .bg-tranparent {
    background-color: transparent;
  }
  .bg-normal-input {
    background-color: $color-neutral8;
  }
  .bg-error-input {
    background-color: $color-error-inputBackground;
  }

  .table-dropdown-container {
    height: 42px;
    border-bottom: 0px;
    padding: $rem-8 $rem-8 $rem-8 $rem-16;
    &:hover {
      border: 1px solid $color-primary5;
    }
  }

  .not-allowed-class {
    cursor: not-allowed !important;
  }

  .indeterminateCheckboxContainer {
    max-width: $rem-40;
  }
  //Sticky Style
  &.sticky {
    overflow: scroll;
    .header,
    .footer {
      position: sticky;
      z-index: 1;
      width: fit-content;
    }

    .header {
      top: 0;
    }

    .footer {
      bottom: 0;
    }

    .body {
      position: relative;
      z-index: 0;

      [data-sticky-last-left-td] {
        border-right: 4px solid #eff5fe;
      }

      [data-sticky-first-right-td] {
        border-right: 4px solid #eff5fe;
      }

      .sticky-border-right_none {
        border-right: 0px;
      }

      .tr {
        width: fit-content !important;
      }
    }

    [data-sticky-td] {
      position: sticky;
    }
  }

  //Style - Clean
  &.data-table-style_clean {
    .header {
      border-bottom: 1px solid rgba(119, 188, 238, 1);
    }

    .th {
      padding: 16px;
      color: rgba(25, 38, 115, 0.9);
      background-color: transparent;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      font-weight: $fontWeight-Bold;

      .th-icon {
        margin-left: 8px;
        height: 20px;
        width: 20px;
      }
    }

    .td {
      background-color: transparent;
      color: rgba(38, 38, 38, 1);
      margin-bottom: 0px;
      line-height: 20px;
    }
  }

  &__deleteIcon {
    height: 20px;
    width: 20px;
    fill: $color-neutral2;
    cursor: pointer;
    &:hover {
      fill: $color-primary4;
    }
  }

  //Scroll columns in smaller screens
  @include media("t-medium", max) {
    overflow-x: scroll;
    .header {
      & > .tr {
        width: fit-content !important;
      }
    }
    .body {
      & > .tr {
        width: fit-content !important;
      }
    }
  }
}

.table-pagination {
  margin-top: 16px;
  position: relative;
  color: rgba(38, 38, 38, 1);
  font-size: 14px;
  align-items: center;

  .pagination-nav-icon {
    fill: rgba(38, 38, 38, 1);
    padding: 0px;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    display: flex;
    align-items: center;

    &:disabled {
      fill: rgba(38, 38, 38, 0.3);
    }

    & > svg {
      height: 18px;
      width: 18px;
    }
  }

  .pagination-input-text {
    width: 60px;
    text-align: center;
    border: 1px solid rgba(119, 188, 238, 0.5);
    border-radius: 6px;
    height: 28px;
  }

  @include media("t-medium", max) {
    flex-direction: column;
    align-items: flex-start;
    &__rightContainer {
      padding-top: $rem-4;
    }
  }

  @include media("m-medium", max) {
    &__leftContainer,
    &__rightContainer {
      flex-wrap: wrap;
      div,
      .button,
      span,
      .pagination-nav-icon {
        margin-bottom: $rem-8;
      }
    }
  }
}
