.welcomeModal {
  &__bedge {
    position: absolute;
    left: -$rem-8;
    top: -$rem-8;
  }
  &__content {
    .carousel__caption {
      height: 230px;
      overflow: hidden;
      @include media("t-medium", max) {
        height: 300px;
      }
    }
  }
  &__footer {
    justify-content: center;
    border: none;
    padding: 0;
    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: $rem-12 $rem-24;
      height: $rem-48;
      border-radius: $rem-40;
      width: 190px;
      cursor: pointer;
    }
  }
  &__video {
    text-align: center;
    width: 100%;
  }
  &__title {
    font-size: $rem-24;
    font-weight: $fontWeight-Bold;
    letter-spacing: 0.0125rem;
    text-align: center;
    margin: $rem-15 0;
    color: $color-neutral1;
  }
  &__quick {
    .card {
      border: none;
      box-shadow: none;
      cursor: pointer;
      margin: 0 $rem-10 $rem-24 $rem-10;
      .card-body {
        padding: $rem-10 0;
        .card-title {
          font-weight: $fontWeight-Bold;
          font-size: $rem-12;
          line-height: 1.35;
          color: $color-primary5;
        }
      }
    }
  }
  &__play {
    img {
      max-width: 100%;
    }
    .carousel__item {
      .carousel__caption {
        padding-bottom: 0;
      }
      .carousel__video {
        position: relative;
        top: -$rem-48;
        @include media("t-medium", max) {
          top: -$rem-96;
        }
      }
    }
  }
  &__slideLink,
  &__slideLink:link {
    color: $color-primary5;
    &:hover {
      color: $color-secondary1;
    }
  }
}

.welcomeModal {
  .modal-dialog {
    min-height: 594px;
    max-width: 696px;
    top: $rem-48;
    .modal-content {
      border-radius: $rem-16;
      padding: $rem-36 $rem-48;
      @include media("t-medium", max) {
        padding: $rem-30 $rem-15;
      }
      .modal-header {
        border: none;
        width: 100%;
        padding-top: 0;
      }
      .modal-body {
        padding: 0;
      }
      .carousel-item {
        transition: none;
        .carousel__image {
          max-width: 390px;
          min-height: 220px !important; //to override inline styles
          margin: 0 auto;
          margin-bottom:24px;
          @include media("t-medium", max) {
            min-height: 200px !important; //to override inline styles
          }
        }
        .carousel__caption {
          position: relative;
          left: 0;
          right: 0;
          .carousel__headline {
            margin: $rem-30 0 0 0;
            font-size: $rem-24;
            text-align: center;
            letter-spacing: 0.0125rem;
          }
          p {
            font-size: $rem-16;
            font-weight: $fontWeight-Lighter;
            letter-spacing: 0.0125rem;
            margin-bottom: 0;
            span {
              font-weight: $fontWeight-Bold;
            }
          }
        }
      }
      .carousel-indicators {
        margin-bottom: 0.5rem;
      }
      .carousel_image {
        text-align: center;
      }
      .carousel-control-prev {
        left: 0;
        bottom: 0.5rem;
      }
      .carousel-control-next {
        right: 0;
        bottom: 0.5rem;
      }
    }
  }
}
