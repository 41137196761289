.custom-modal-checkbox-declaration {
  .declaration-checkbox-cr {
    display: flex;
    align-items: center;
    font-size: 16px;
    p {
      text-align: start;
      margin: 0;
    }
  }
  .declaration-checkbox-cr:first-child {
    margin-top: 24px;
  }
  .declaration-checkbox-payment-text {
    margin-top: 48px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }
  .declaration-checkbox-payment-type {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }
  .declaration-checkbox-note {
    margin-bottom: 16px;
    color: #a2a2a2;
    font-size: 12px;
    font-weight: 400;
    font-style: italic;
  }
  .checkbox-declaration-buttons {
    margin-bottom: 6px;
  }
}
.setupSector {
  margin-bottom: $rem-24;
  display: flex;

  &__formSection {
    padding: 24px;
    padding-bottom: 0;
    text-align: center;
    flex: 1;
  }

  &__topic {
    color: $color-primary4;
    font-size: $rem-24;
    line-height: 1.3333;
    padding-bottom: $rem-12;
  }

  &__topicNote {
    color: $color-neutral1;
    line-height: 1.5;
    padding-bottom: $rem-16;
  }

  &__interact {
    padding-top: $rem-72;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    align-self: flex-end;
  }

  &__notification {
    font-size: $rem-14;
    line-height: 1.4286;
    font-style: italic;
  }

  &__announcementTitle {
    font-weight: $fontWeight-Bold;
    margin-bottom: $rem-8;
  }

  &__imageContainer, &__title, &__buttonContainer {
    display: flex;
    justify-content: center;
    margin: $rem-16 0;
    
    button.setupSector__notifyButton {
      margin: $rem-12 0;
    }
  }

  &__title {
    font-weight: $fontWeight-Bold;
  }
  
  &__description {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: $rem-16 0;
  }

  button.setupSector__button {
    margin: $rem-12 0;
    width: fit-content;
  }
}