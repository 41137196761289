.form__formGroup {
  .form__label {
    position: relative;
    transform: translateY(4px);
    opacity: 0;
  }
  &.active .form__label {
    transform: translateY(0);
    opacity: 1;
  }
  &.active,
  &.error {
    .form__text {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.form__inputGroup {
  border-bottom: 1px solid;
}

.form__label {
  transition: 0.2s;
  transition-property: transform, opacity;
  font-size: $rem-12;
  line-height: 1.33;
}

.form__searchInstruction{
  transition: 0.2s;
  transition-property: transform, opacity;
  font-size: $rem-12;
  line-height: 1.33;
  font-weight: 300;
}

.form__text {
  display: block;
  font-size: $rem-12;
  line-height: 1.33;
  transition: 0.2s;
  transition-property: transform, opacity;
  opacity: 0;
  transform: translateY(-4px);
}

.form__control {
  font-size: $rem-16;
  margin-top: -3px;
  line-height: 1.5;
  background: initial;
  border: 0;
  padding-top: $rem-4;
  padding-bottom: $rem-8;

  ~ * {
    transform: translateY(-3px);
    margin: 0 $rem-8;
    line-height: 1.5;
  }

  &:focus {
    border: 0;
    box-shadow: none;

    &::placeholder {
      opacity: 0;
    }
  }

  &::placeholder {
    transition: 0.2s;
  }
}
