.addAccountsFailure {
  &__block {
    padding: $rem-32;
  }
  &__updateContainer {
    align-items: baseline;
    @include media("t-large", max) {
      flex-direction: column;
    }
  }
  &__btnContainer {
    min-width: 240px;
    .button {
      border-radius: $rem-40;
      height: $rem-48;
    }
    @include media("t-large", max) {
      min-width: 100%;
    }
  }
}