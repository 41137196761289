.emptyData {
  &__image {
    width: $rem-100;
  }

  &__description {
    &--light {
      color: $color-neutral1;
    }
    &--dark {
      color: $color-neutral8;
    }
  }
}
