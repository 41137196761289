.customSearchbox {
  position: relative;

  &__container {
    color: $color-neutral1;
    font-size: $rem-14;
    border: 1.5px solid rgba(0,0,0,0);
    display: flex;
    overflow: hidden;
    height: 40px;
    line-height: 40px;
    width: 40px;
    min-width: fit-content;
    // transition: 0.2s; disable transition
    align-items: center;
    justify-content: flex-end;
    
    @include media("t-large", max) {
      transition: none;
      min-width: 0px;
    }
  }

  &__left {
    display: inline-flex;
    flex-wrap: nowrap;
    overflow: hidden;
    // transition: 0.2s max-width 0.2s, 0.2s opacity 0.4s; disable transition
    align-items: center;
    flex-basis: fit-content;
    max-width: 0;
    opacity: 0;
    
    @include media("t-large", max) {
      transition: none;
    }
  }

  &__label {
    margin-left: $rem-16;
    white-space: nowrap;
  }

  &__selection &__selectionDropdown {
    border: 0;
    padding-left: $rem-8;
    padding-right: $rem-8;
    width: max-content;
  }

  &__textbox, &__textbox:focus {
    height: 32px;
    border: 0;
    outline: none;
    border-radius: 0;
    line-height: normal;
    min-width: fit-content;
  }

  &__submit {
    display: inline-flex;
    fill: $color-neutral2;
    height: 24px;
    min-width: 40px;
    flex-basis: 24px;
    cursor: pointer;

    &--disabled {
      fill: $color-neutral5;
      cursor: not-allowed;
    }
  }

  &__right {
    display: inline-flex;
    overflow: hidden;
    // transition: 0.2s max-width, 0.2s opacity 0.4s; disable transition
    flex-basis: fit-content;
    max-width: 0;
    opacity: 0;
    
    @include media("t-large", max) {
      transition: none;
    }
  }

  &__clear {
    min-width: 24px;
    width: 24px;
    flex-basis: 24px;
    height: 24px;
    fill: $color-neutral8;
    cursor: pointer;
    margin: 0 $rem-8;

    svg {
      vertical-align: baseline;
    }

    .icon-close-filled {
      fill: $color-neutral4;
    }

    &:hover .icon-close-filled{
      fill: $color-primary5;
    }
  }

  &.activated  {
    .customSearchbox {
      &__container {
        background: $color-neutral8;
        border-radius: 48px;
        border-color: $color-secondary5;
        width: fit-content;
        @include media("t-large", max) {
          width: 0px;
        }
      }

      &__left {
        max-width: 100%;
        opacity: 1;
      }

      &__submit {
        min-width: 24px;
        fill: $color-primary5;
      }
      
      &__right {
        max-width: 100%;
        opacity: 1;
      }

      &__lgContainer {
        z-index: 5; //must be on top of table's pagination control
        @include media("t-large", max) {
          display: flex;
        }
      }
    }
  }

  &.hasValue {
    .customSearchbox__submit {
      fill: $color-primary5;
    }
  }

  &__lgContainer {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $color-neutral8;
    flex-direction: column;
  }

  &__lgHeader {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $color-neutral6;
    padding: $rem-16;
  }

  &__lgHeaderLeft {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &__lgControl {
    padding: $rem-16;
    display: inline-flex;
    align-items: center;
  }

  &__lgLabel {
    margin-right: $rem-8;
  }

  &__lgSelectionDropdown.dropdown {
    border: 0;
  }

  &__lgBody {
    margin: 0 $rem-16;
    display: inline-flex;
    align-items: center;
    border: 1px solid $color-secondary5;
    border-radius: 1em;
    overflow: hidden;
  }

  &__lgTextbox, &__lgTextbox:focus {
    height: 32px;
    border: 0;
    padding-left: $rem-8;
    flex-grow: 1;
    outline: none;
    border-radius: 0;
  }

  &__lgSubmit {
    display: inline-flex;
    fill: $color-neutral2;
    height: 24px;
    min-width: 40px;
    flex-basis: 24px;
    cursor: pointer;
  }

  &__lgClear {
    min-width: 24px;
    width: 24px;
    flex-basis: 24px;
    height: 24px;
    fill: $color-neutral8;
    cursor: pointer;
    margin: 0 $rem-8;

    svg {
      vertical-align: baseline;
    }

    .icon-close-filled {
      fill: $color-neutral4;
    }

    &:hover .icon-close-filled{
      fill: $color-primary5;
    }
  }
}
