.assignModal {
  &__infoContainer {
    text-align: left;
  }
  &__label {
    color: $color-neutral3;
    font-size: $rem-12;
  }
  &__data {
    font-size: $rem-14;
  }
  &__dropdownPopover {
    max-height: 162px;
  }
}