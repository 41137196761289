.learnHeader {
  background-color: $background-primaryGradient-100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: $rem-16;
  padding-left: $rem-72;
  padding-right: $rem-72;
  @include media("t-large", max) {
    padding-top: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &__logo {
    width: 150px;
  }

  &__navCollapse {
    @include media("t-large", max) {
      display: none;
    }
  }

  &__navItem {
    margin: 0 $rem-8;
    transition: 0.3s;
    border-bottom: 1px solid rgba(0,0,0,0);
    color: $color-neutral1;
    &:hover {
      border-bottom: 1px solid $color-neutral8;
    }

    &.active {
      border-bottom: 2px solid $color-neutral8;
    }
  }
}

.navbar-nav .nav-link.learnHeader__navItem {
  @include media("t-large", min) {
    padding-left: 0;
    padding-right: 0;
  }

  &:hover {
    color: $color-neutral8;
  }
}