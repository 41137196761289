.registrationApplication {
  &__block {
    padding: $rem-32;
    color: $color-neutral1;
    background: $color-secondary7;
  }
  &__btnsContainer {
    display: flex;
    align-items: center;
    @include media("t-large", max) {
      flex-direction: column;
      justify-content: center;
    }
  }
  &__btnContainer {
    margin-left: $rem-16;
    @include media("t-large", max) {
      margin-left: 0;
      margin-top: $rem-16;
    }
  }
  &__row {
    flex-direction: column;
  }
  &__label {
    font-size: $rem-12;
    color: $color-neutral3;
  }
  &__value {
    font-weight: $fontWeight-Bold;
  }
  &__rowContainer {
    @include media("t-large", max) {
      flex-direction: column;
    }
  }
  &__assistanceContainer {
    background: $color-secondary6;
    border: 1px solid $color-primary5;
    border-radius: $rem-8;
    display: flex;
    align-items: center;
    @include media("m-large", max) {
      flex-direction: column;
      text-align: center;
    }
  }
  &__assistanceImgContainer {
    @include media("m-large", max) {
      margin-bottom: $rem-16;
    }
  }
  &__assistanceImg {
    width: 150px;
  }
  &__assistanceTitle {
    color: $color-primary1;
    font-weight: $fontWeight-Bold;
  }
  &__assistanceDescription {
    color: $color-neutral1 !important;
  }

  &__detailModal .modal-content {
    border-radius: $rem-16;
    padding: $rem-40 $rem-48;
    background: $color-secondary6;
    position: unset;
    color: $color-neutral1;
    
    @include media("t-large", max) {
      padding: $rem-24 $rem-16;
    }
  }

  &__topic {
    font-weight: $fontWeight-Bold;
    color: $color-primary5;
    padding: $rem-16 0;
    border-top: 1px solid $color-neutral6;

    &:first-child {
      border: 0;
      padding-top: 0;
    } 
  }

  &__tableContainer {
    height: fit-content;
  }

  &__loaderContainer {
    position: relative;
    height: $rem-376;
  }
}
