.editAccountDetails {
  &__block {
    padding: $rem-32;
  }
  &__btnContainer {
    justify-content: flex-end;
    padding-right: $rem-12;
    @include media("t-large", max) {
      justify-content: center;
      padding-left: $rem-12;
    }
  }
  &__cancelBtnContainer,
  &__saveBtnContainer {
    max-width: 190px;
    min-width: max-content;
    .button {
      height: $rem-48;
      border-radius: $rem-40;
    }
    @include media("t-large", max) {
      max-width: 100%;
      .button {
        flex: auto;
      }
    }
  }
  &__cancelBtnContainer {
    margin-right: $rem-24;
    @include media("t-large", max) {
      margin-right: 0;
      margin-top: $rem-16;
      order: 2;
    }
  }
}
