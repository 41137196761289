.electricityAccountSetup {
  &__title {
    font-size: $rem-24;
    line-height: 1.3333;
    color: $color-primary4;
    padding-top: $rem-16;
    padding-bottom: $rem-16;
  }

  &__topic {
    font-size: $rem-24;
    line-height: 1.3333;
    color: $color-primary4;
    padding-bottom: $rem-12;

    &--warning {
      color: $color-warning;
    }
  }

  &__description {
    font-size: $rem-16;
    line-height: 1.5;
    color: $color-neutral1;
  }

  &__table {
    height: fit-content;
    padding-top: $rem-16;
    padding-bottom: $rem-16;
  }

  &__cell {
    &--error {
      color: $color-error-lightBackground;
    }
  }

  &__viewAccount {
    display: flex;
    align-self: center;
    font-size: $rem-20;
    line-height: 1.4;
    color: $color-primary5;
    fill: $color-primary5;
    cursor: pointer;

    @include media("t-large", max) {
      margin-top: $rem-16;
    }

    &--warning {
      color: $color-warning;
      fill: $color-warning;
    }
  }

  &__viewAccountIcon {
    padding-left: $rem-12;
    height: $rem-16;
  }

  &__divider {
    border-top: 1px solid $color-neutral5;
    margin-top: $rem-32;
    margin-bottom: $rem-8;
  }

  &__totalCount {
    color: $color-primary1;
    font-size: $rem-24;
    line-height: 1.3333;
  }

  &__addCountItem {
    padding: 0 $rem-24;
    &:first-child {
      border-right: 1px solid $color-neutral5;
    }
  }

  &__addCountTitle {
    color: $color-primary1;
    line-height: 1.375;
    padding-bottom: $rem-8;
  }

  &__addCountValue {
    color: $color-primary1;
    font-size: $rem-24;
    line-height: 1.3333;
    font-weight: $fontWeight-Bold;
  }

  &__modalIcon {
    fill: $color-primary5;
    width: $rem-56;
    height: $rem-56;

    &--error {
      fill: $color-error-lightBackground;
    }
  }

  &__manualAddSummary {
    color: $color-primary4;
    font-size: $rem-24;
    line-height: 1.3333;
  }

  &__accountTitle {
    font-size: $rem-24;
    line-height: 1.3333;
    color: $color-primary5;
    padding-bottom: $rem-12;
  }

  &__accountDescription {
    line-height: 1.5;
    color: $color-neutral1;
    padding-bottom: $rem-24;
  }
  
  &__closeButtonContainer {
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: $rem-32;
    right: $rem-32;
  }

  &__closeButton {
    cursor: pointer;
    width: $rem-16;
    height: $rem-16;
    color: $color-neutral1;
  }

  &__tableContainer {
    height: fit-content;
  }

  &__right {
    align-self: flex-end;
  }

  &__infoButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $color-primary5;
    fill: $color-primary5;
    margin-left: $rem-32;
  }

  &__infoButtonLogo {
    height: $rem-24;
    width: $rem-24;
    padding-right: $rem-8;
  }

  &__templateLink {
    color: $color-primary5;
    &:link {
      color: $color-primary5;
    }
    &:hover {
      color: $color-primary4;
    }
  }

  &__uploadNote {
    font-size: $rem-14;
    font-weight: $fontWeight-Lighter;
  }

  &__fileUpload .fileUpload {
    background-color: $color-neutral8;
  }
}