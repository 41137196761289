.pastBillBatch {
  &--hasLoadMore {
    padding-bottom: 0;
  }

  &__last2Bills {
    color: $color-primary1;
  }
  .custom-date-calender{
    width:470px;
    @include media("t-large", max) {
      width:auto;
      padding-bottom:24px;
    }
  }
  &__link {
    display: flex;
    color: $color-primary5;
    fill: $color-primary5;
    cursor: pointer;
  }

  &__icon {
    height: 24px;
    width: 24px;
    margin-left: $rem-8;
  }

  &__header {
    align-items: center;
  }

  &__pastBillsTriggerContainer {
    margin-left: $rem-8;

    .tooltip-inner {
      border-radius: $rem-4;
    }
  }

  &__infoIcon {
    width: $rem-24;
    height: $rem-24;
    fill: $color-neutral2;
    cursor: pointer;
    margin-left: $rem-4;

    &:hover {
      fill: $color-primary5;
    }
  }

  &__pastBillsTooltip {
    font-size: $rem-16;
    line-height: $rem-24;
    max-width: 300px;

    @include media("m-medium", max) {
      max-width: 250px;
    }
  }

  &__billBatchId {
    cursor: pointer;
    &:hover {
      color: $color-primary4;
    }
  }

  &__loadMore {
    border-top: 1px solid $color-secondary4;
  }

  &__loadMoreButton {
    width: fit-content;
    margin: 0 auto;
    padding: $rem-12 0;
  }

  @include media("t-medium", max) {
    &__header {
      flex-direction: column;
      align-items: flex-start;
    }
    &__last2Bills {
      margin-bottom: $rem-4;
    }
  }

  &__cardBlockContainer{
    display:flex;
    justify-content: space-between;
    padding-bottom:16px;
    @include media("t-large", max) {
      padding-bottom:12px;
      overflow-x: scroll;
    }
  }
  &__cardBlock{
    display:flex;
    .block--lightest{
      background-color: #EFF5FE;  
      margin-right:16px;
      font-size:12px;
      line-height:16px;
      padding:12px;
      border: 1px solid #77BCEE;
      width:auto;
      @include media("t-large", max) {
        margin-right:6px;
        font-size:10px;
        line-height: 14px;
      }
      .displayCard{
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        .displayCard__item{
          @include media("t-large", max) {
           padding-bottom:4px;
          }
          .displayCard__logoContainer{
            display: none !important;
          }
        }
        .displayCard__textValue{
          font-size: 14px;
          line-height: 20px;
          @include media("t-large", max) {
            font-size:10px;
            line-height: 14px;
          }
          .displayCard__textPrefix--small{
            font-size: 14px;
            line-height: 20px;
            @include media("t-large", max) {
              font-size:10px;
              line-height: 14px;
            }
          }
          .displayCard__textArrowContainer{
            padding:0 !important;
          }
        }
      }
    }
  }
}