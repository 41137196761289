.customerRegistration {
  &__block {
    padding: $rem-32;
    margin-bottom: $rem-40;
    color: $color-neutral1;
  }
  &__downloadContainer {
    width: fit-content;
    .button {
      padding-left: 0;
    }
    &:hover {
      .customerRegistration__downloadIcon {
        fill: $color-primary4;
      }
    }
  }
  &__downloadIcon {
    width: $rem-20;
    fill: $color-primary5;
  }
}
