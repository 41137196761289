.custom-modal-width{
  max-width:1300px;
}

.header-tr-custom-class{
  @include media("t-large", min) {
 width:1200px !important;
  }
}
.table.sticky .body .custom-tr-modal-class{
  @include media("t-large", min) {
    width:1200px !important;
     }
 }

.paymentTransactionDetailsModal {
  width: 80%;
  max-width: none !important; //overwrite bootstrap

  &__body {
    padding: $rem-32 $rem-40;
    color: $color-neutral1;
    background-color: $color-secondary6;
    overflow: auto;
  }

  &__title {
    color: $color-primary4;
    font-size: $rem-24;
    line-height: 1.3333;
    padding: 0 0 $rem-16 0;
    border: 0;

    button {
      width: 16px;
      height: 16px;
      background-size: 16px 16px;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  &__headerInfo {
    display: flex;
    padding-bottom: $rem-24;
  }

  &__infoBlock {
    flex-grow: 1;

    &--right {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__infoTitle {
    font-size: $rem-12;
    line-height: 1.3333;
    color: $color-neutral3;
  }

  &__infoValue {
    line-height: 1.5;
  }
  &__customEmptyClass {
    height:24px;
  }

  &__infoButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $color-primary5;
    fill: $color-primary5;
  }

  &__infoButtonLogo {
    height: $rem-24;
    width: $rem-24;
    padding-right: $rem-8;
  }

  &__closeButtonContainer {
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: $rem-8;
    right: $rem-8;
  }
  &__closeButton {
    cursor: pointer;
    width: $rem-16;
    height: $rem-16;
    color: $color-neutral1;
  }

  @include media("t-large", max) {
    &__headerInfo {
      flex-direction: column;
      align-items: flex-start;
    }

    &__infoBlock {
      margin-bottom: $rem-8;
      &:last-child {
        margin-bottom: 0;
      }

      &--right {
        justify-content: normal;
      }
    }

    &__infoBlock--button {
      width: 100%;
    }
  }
}
