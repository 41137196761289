.accountErrorDetected {
  &__title {
    color: $color-primary4;
    font-size: $rem-24;
    line-height: 1.3333;
    padding-bottom: $rem-16;
  }

  &__description {
    color: $color-neutral1;
    line-height: 1.5;
  }

  &__viewErrorFree {
    color: $color-primary5;
    font-size: $rem-20;
    line-height: 1.4;
    text-align: right;
  }

  &__viewErrorFreeAcc {
    cursor: pointer;
  }

  &__icon {
    display: inline-block;
    vertical-align: middle;
    fill: $color-primary5;
    padding-left: $rem-12;
    height: $rem-16;
    width: fit-content;
  }

  &__panel {
    display: flex;
    align-content: center;
    border-top: 1px solid $color-neutral5;
    padding-top: $rem-32;
    padding-bottom: $rem-16;
  }

  &__accountTitle {
    font-size: $rem-24;
    line-height: 1.3333;
    color: $color-primary5;
    padding-bottom: $rem-12;
  }

  &__accountDescription {
    line-height: 1.5;
    color: $color-neutral1;
    padding-bottom: $rem-24;
  }
  
  &__closeButtonContainer {
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: $rem-32;
    right: $rem-32;
  }

  &__closeButton {
    cursor: pointer;
    width: $rem-16;
    height: $rem-16;
    color: $color-neutral1;
  }

  &__tableContainer {
    height: fit-content;
  }
}