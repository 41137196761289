.billHistory {
  &__actionCell {
    width: $rem-20;
  }

  &__downloadIconWrapper {
    display: flex;
    justify-content: space-evenly;
    cursor: pointer;
    text-align: center;
    fill: $color-neutral2;
    &:hover {
      fill: $color-primary5;
    }
  }

  &__spinner {
    vertical-align: middle;
    display: flex;
    justify-content: center;
    .spinner-border {
      width: $rem-20;
      height: $rem-20;
      border-right: 0.25em solid transparent !important;
    }
  }

  &__container {
    min-height: 250px;
  }

  &__learnMoreLink {
    color: $color-primary5;
    &:link {
      color: $color-primary5;
    }
    &:hover {
      color: $color-primary5;
    }
  }

  &__searchButtonContainer {
    width: 240px;
    height: $rem-48;
    min-width: max-content;
    .button {
      border-radius: $rem-40;
      height: 100%;
    }
    @include media("t-large", max) {
      align-items: initial;
      margin-top: $rem-16;
      width: 100%;
    }
  }
}
