.billPayment {
  letter-spacing: 0.2px;
  &__announcement {
    height: fit-content;
    z-index: 2;
    width: 75%;
  }
  @include media("t-large", max) {
    &__announcement {
      width: 95%;
    }
  }
  &__infoContainer {
    color: $color-primary1;
    line-height: $rem-24;
  }

  &__infoTitle {
    font-weight: $fontWeight-Bold;
    padding-bottom: $rem-8;
  }

  &__infoContentBold {
    font-weight: $fontWeight-Bold;
  }
}
a.billPayment__infoContent--link {
  color: $color-primary5;

  &:hover {
    color: $color-primary4;
  }
}
.navbar-nav .nav-link {
  padding-left: $rem-8;
  padding-right: $rem-8;
}
