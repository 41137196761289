.outstandingCharge {
  &__title {
    font-size: $rem-24;
    line-height: 1.3333;
    color: $color-primary4;
  }

  &__cardContainer {
    overflow-x: auto;
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    min-width: max-content;
  }

  &__card {
    flex-grow: 1;
    margin-right: $rem-24;
    &:last-child {
      margin: 0px;
    }
  }
  &__trafficCard
  {
    cursor: pointer;
  }

  &__tableHeader {
    align-items: baseline;
  }

  &__table {
    flex-basis: 100%;
  }

  &__outstandingInfoIcon {
    width: $rem-24;
    height: $rem-24;
    fill: $color-neutral2;
    cursor: pointer;

    &:hover {
      fill: $color-primary5;
    }
  }

  &__outstandingTriggerContainer {
    margin-left: $rem-8;
    padding: 0 !important;

    .tooltip-inner {
      border-radius: $rem-4;
    }
  }

  &__titleTooltip {
    font-size: $rem-16;
    line-height: $rem-24;
    max-width: 400px;
  }

  &__titleTooltipInfoBold {
    font-weight: $fontWeight-Bold;
  }
  &__statusIcon {
    cursor: pointer;
    width: $rem-20;
    fill: $color-neutral8;

    &:hover {
      fill: $color-secondary1;
    }
  }
  &__statusTooltip {
    white-space: pre-wrap;
    margin-top: $rem-8;
    max-width: 400px;
    font-size: $rem-16;
    line-height: $rem-24;
  }

  &__statusPaymentRequired {
    color: $color-warning;
  }

  &__statusPaymentOverdue {
    color: $color-error-lightBackground;
  }

  &__payNowContainer {
    cursor: pointer;
    text-align: center;
    &:hover {
      color: $color-primary4;
    }
  }

  &__payIcon {
    width: $rem-20;
    fill: $color-primary4;
  }

  &__guidance {
    width: fit-content;
    left: 100%;
    transform: translateX(-100%);
  }

  &__dataTable {
    min-height: 250px;
  }

  .displayCard__title{
    width: 170px;
  }

  @include media("m-large", max) {
    &__titleTooltip {
      max-width: 300px;
    }
  }
  @include media("m-medium", max) {
    &__titleTooltip {
      max-width: 250px;
    }
  }
}
