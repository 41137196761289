.payment {
  &__title {
    font-size: $rem-24;
    line-height: 1.3333;
    color: $color-primary4;
  }
  &__guidanceContainer {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}