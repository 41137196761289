.adminResetPassword {
  min-height: 100vh;

  &__container {
    margin-top: $rem-72;
    @include media("t-large", max) {
      margin-top: 0;
    }
  }
  &__imageContainer {
    justify-content: flex-end;
    @include media("t-large", max) {
      justify-content: center;
    }
  }
  &__image {
    width: 496px;
    height: 348px;
    min-width: 496px;
  }
  &__logo {
    width: 150px;
    height: auto;
    margin-left: $rem-32;
    padding: 0;
    @include media("t-large", max) {
      margin-left: $rem-16;
    }
  }
  &__notification {
    position: relative;
    top: -72px;
    &--noError {
      visibility: hidden;
      transition: visibility 0.3s ease;
    }
    @include media("t-large", max) {
      position: fixed;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: $rem-8;
      z-index: 3;
    }
  }
  &__title {
    font-weight: $fontWeight-Bold;
    font-size: 1.9em;
    line-height: $rem-40;
    @include media("t-large", max) {
      font-size: 1.5em;
      line-height: $rem-32;
    }
  }
  &__textboxes {
    margin-bottom: 60px;
    &--newPassword,
    &--confirmPassword {
      padding: 0;
    }
    &--newPassword {
      margin-bottom: $rem-40;
    }
  }
  &__passwordPopoverTrigger {
    cursor: pointer;
    width: $rem-24;

    &:hover {
      fill: $color-secondary1 !important;
    }
  }
  &__passwordTooltip {
    white-space: pre-wrap;
    background: $color-neutral8;
    color: $color-neutral1;
    border-radius: $rem-4;
    margin-top: $rem-12;
  }
  &__button {
    .button {
      padding: $rem-10;
      border-radius: $rem-40;
      height: $rem-48;
    }
    &--cancel {
      margin-right: 1.5rem;
    }
  }
  .form__formGroup {
    padding: 0;
  }
  @include media("t-large", max) {
    &__image {
      width: auto;
      height: auto;
      min-width: auto;
    }
    &__info {
      font-size: $rem-14;
      line-height: $rem-20;
    }
    .button {
      flex: auto;
    }
    &__button--submit {
      order: 1;
    }
    &__button--cancel {
      order: 2;
      margin-right: 0;
      margin-top: 1rem;
    }
  }
}
