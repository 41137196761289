.editGroup {
  &__topContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media("t-large", max) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: baseline;
    }
  }
  &__groupContainer {
    @include media("t-large", max) {
      width: 100%;
    }
  }
  &__addAccBtnContainer {
    min-width: 275px;
    .button {
      height: $rem-48;
      border-radius: $rem-40;
    }
    @include media("t-large", max) {
      width: 100%;
      margin-top: $rem-16;
    }
  }
  &__addIcon {
    width: $rem-20;
    height: $rem-20;
    fill: $color-primary5;
  }
  &__smallBtnContainer {
    justify-content: flex-end;
    padding-right: $rem-12;
  }
  &__removeBtnContainer,
  &__reassignBtnContainer {
    max-width: 79px;
    min-width: max-content;
    .button {
      height: $rem-32;
      border-radius: $rem-24;
    }
  }
  &__bigBtnContainer {
    justify-content: flex-end;
    padding-right: $rem-12;
    @include media("t-large", max) {
      justify-content: center;
      padding-left: $rem-12;
    }
  }
  &__cancelBtnContainer,
  &__saveBtnContainer {
    max-width: 190px;
    min-width: max-content;
    .button {
      height: $rem-48;
      border-radius: $rem-40;
    }
    @include media("t-large", max) {
      max-width: 100%;
      .button {
        flex: auto;
      }
    }
  }
  &__cancelBtnContainer {
    margin-right: $rem-24;
    @include media("t-large", max) {
      margin-right: 0;
      margin-top: $rem-16;
      order: 2;
    }
  }
}