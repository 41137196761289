.flex {
  display: flex;
  flex-direction: row;
  // &:not(.nospace) > *:not(:last-child) {
  //   margin-right: $rem-16;
  // }
  &.column {
    flex-direction: column;
    // &:not(.nospace) > *:not(:last-child) {
    //   margin-right: 0;
    //   margin-bottom: $rem-16;
    // }
  }
  &.center {
    align-items: center;
  }
  &.center-self {
    align-self: center;
  }
  &.align-self-end {
    align-self: flex-end;
  }
  &.justify-center {
    justify-content: center;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.space-around {
    justify-content: space-around;
  }
  &.space-evenly {
    justify-content: space-evenly;
  }
  &.end {
    justify-content: flex-end;
  }
  &.start {
    justify-content: flex-start;
  }
  &.align-start {
    align-items: flex-start;
  }
  &.stretch {
    align-items: stretch;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.start-items {
    align-items: flex-start;
  }
  &.end-items {
    align-items: flex-end;
  }

  &.gap {
    &__8 {
      gap: $rem-8;
    }
    &__16 {
      gap: $rem-16;
    }
    &__24 {
      gap: $rem-24;
    }
    &__28 {
      gap: $rem-28;
    }
    &__32 {
      gap: $rem-32;
    }
  }

  & > .flex-grow-child {
    flex-grow: 1;
  }
}
