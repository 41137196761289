.unpaidInvoice {
  &__downloadIconWrapper {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    &:hover {
      fill: $color-primary5;
      color: $color-primary5;
    }
  }

  &__downloadIcon {
    min-width: $rem-20;
    margin-right: $rem-8;
  }

  &__statusIcon {
    cursor: pointer;
    width: $rem-20;
    fill: $color-neutral8;

    &:hover {
      fill: $color-secondary1;
    }
  }
  &__statusTooltip {
    white-space: pre-wrap;
    margin-top: $rem-8;
    max-width: 500px;
    font-size: $rem-16;
    line-height: $rem-24;

    .status-key {
      font-weight: $fontWeight-Normal;
    }
  }
  &__status--paymentRequired {
    color: $color-warning;
  }
  &__status--paymentOverdue {
    color: $color-error-lightBackground;
  }
  &__status--paymentProcessing {
    color: $color-neutral2;
  }
  &__buttonContainer {
    min-width: 240px;
    height: $rem-48;
    .button {
      height: 100%;
      border-radius: $rem-40;
    }
  }
  &__buttonSpinner {
    max-width: 30%;
  }
  &__footnote {
    color: $color-neutral1;
    font-size: $rem-14;
  }
  &__filterPopover {
    max-height: 380px;
    overflow-y: auto;
    @include media("t-large", max) {
      max-height: none;
    }
  }

  @include media("t-large", max) {
    &__buttonContainer {
      min-width: 100%;
      margin-top: $rem-16;
    }
  }
}
