.faq {
  margin-bottom: $rem-48;
  font-size: $rem-16;
  @include media("t-large", max) {
    padding: 0 1rem;
  }

  &__container {
    color: $color-neutral1;
    padding: $rem-48 8%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    @include media("t-large", max) {
      padding: $rem-24 $rem-16;
    }
  }

  &__title {
    font-weight: $fontWeight-Bold;
    font-size: $rem-24;
    padding-bottom: $rem-32;
    @include media("t-large", max) {
      font-size: $rem-20;
      padding-bottom: $rem-16;
    }
  }

  &__question {
    font-weight: $fontWeight-Bold;
    padding-bottom: $rem-12;
    @include media("t-large", max) {
      padding-bottom: $rem-8;
    }
  }

  &__answer {
    padding-bottom: $rem-32;
    &--last {
      padding-bottom: 0;
    }
    @include media("t-large", max) {
      padding-bottom: $rem-24;
      &--last {
        padding-bottom: 0;
      }
    }
  }
}
