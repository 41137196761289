.viewCustomerRegistrationDetails {
  &__backContainer {
    cursor: pointer;
    width: fit-content;
    &:hover {
      color: $color-secondary1;
      .viewAccountDetails__backIcon {
        fill: $color-secondary1;
      }
    }
  }
  &__backIcon {
    width: $rem-24;
    height: $rem-24;
    fill: $color-chevron-icon;
  }
  &__header {
    text-align: left;
  }
}
