.childAccountTable {
  &__statusIcon {
    cursor: pointer;
    width: $rem-20;
    fill: $color-neutral8;

    &:hover {
      fill: $color-secondary1;
    }
  }
  &__tooltip {
    white-space: pre-wrap;
    margin-top: $rem-8;
    max-width: 400px;
    font-size: $rem-16;
    line-height: 1.5;
  }
  &__referenceBold {
    font-weight: $fontWeight-Bold;
  }
  &__dataWrap {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    display: inline-block;
  }
  &__tableDetails {
    cursor: pointer;
    text-align: center;
    &:hover {
      color: $color-primary4;
      fill: $color-primary4;
      .icon-document-inner {
        fill: $color-primary4;
      }
    }
  }
  &__previewIcon {
    width: $rem-20;
  }
  &__documentIcon {
    width: $rem-20;
    .icon-document-line {
      display: none;
    }
  }
  &__bottomContainer {
    align-items: center;
    @include media("t-large", max) {
      align-items: initial;
      flex-direction: column;
    }
  }
  &__buttonContainer {
    width: 240px;
    min-height: $rem-48;
    margin-left: auto;
    margin-right: 0;
    min-width: max-content;
    .button {
      border-radius: $rem-40;
      height: $rem-48;
    }
    @include media("t-large", max) {
      width: 100%;
      margin-right: auto;
      margin-top: $rem-16;
    }
  }
  &__downloadContainer {
    width: fit-content;
    .button {
      padding-left: 0;
    }
    &:hover {
      .childAccountTable__downloadAccountIcon {
        fill: $color-primary4;
      }
    }
  }
  &__downloadAccountIcon {
    width: $rem-20;
    fill: $color-primary5;
  }
  &__2BtnsContainer {
    justify-content: flex-end;
    padding-right: $rem-12;
    @include media("t-large", max) {
      justify-content: center;
      padding-left: $rem-12;
    }
  }
  &__removeBtnContainer,
  &__assignBtnContainer {
    max-width: 136px;
    min-width: max-content;
    .button {
      height: $rem-32;
      border-radius: $rem-24;
    }
    @include media("t-large", max) {
      max-width: 100%;
      .button {
        flex: auto;
      }
    }
  }
  &__removeBtnContainer {
    margin-right: $rem-16;
    @include media("t-large", max) {
      margin-right: 0;
      margin-bottom: $rem-16;
    }
  }
  &__cancelBtnContainer,
  &__saveBtnContainer {
    max-width: 190px;
    min-width: max-content;
    .button {
      height: $rem-48;
      border-radius: $rem-40;
    }
    @include media("t-large", max) {
      max-width: 100%;
      .button {
        flex: auto;
      }
    }
  }
  &__cancelBtnContainer {
    margin-right: $rem-24;
    @include media("t-large", max) {
      margin-right: 0;
      margin-top: $rem-16;
      order: 2;
    }
  }
  &__filterPopover {
    max-height: 327px;
    overflow-y: auto;
    @include media("t-large", max) {
      max-height: none;
    }
  }
  &__editTutorialImg {
    width: 100%;
    margin-top: $rem-8;
  }
}
