.searchElectricityAccount {
  &__notificationContainer {
    position: relative;
  }
  &__notification {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    min-width: fit-content;
    height: fit-content;
    @include media("t-large", max) {
      width: 100%;
    }
  }
}