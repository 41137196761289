.paymentDetailCardLoader {
  background: $color-secondary6;
  &__title,
  &__info,
  &__data {
    height: $rem-24;
    border-radius: $rem-16;
  }
  &__label,
  &__detail {
    height: $rem-20;
  }
  &__title {
    max-width: 55%;
    margin-bottom: $rem-16;
    @include media("t-large", max) {
      margin-bottom: $rem-8;
    }
  }
  &__data {
    max-width: 60%;
    @include media("t-large", max) {
      max-width: 50%;
    }
  }
  &__label {
    margin-bottom: $rem-4;
    @include media("t-large", max) {
      max-width: 24%;
    }
  }
  @include media("t-large", max) {
    &__download {
      display: none;
    }
    &__info {
      max-width: 60%;
    }
    &__row {
      flex-direction: column;
    }
    &__detail {
      max-width: 30%;
      margin-bottom: $rem-16;
    }
  }
}
