.videoGuideModal {
  @include media("t-large", max) {
    .modal-dialog {
      max-width: none;
    }
  }

  &__language {
    text-align: left;
    margin-top: $rem-32;
    margin-bottom: $rem-16;
    font-size: $rem-24;
    padding-left: $rem-12;
    border-left: 4px solid $color-neutral2;
    color: $color-neutral2;

    &:not(:first-child) {
      margin-top: $rem-48;
    }

    @include media("t-large", max) {
      margin-top: 0;
    }
  }

  &__contents {
    margin-bottom: $rem-32;
    transition: 0.3s transform;
    @include media("t-large", min) {
      &:hover {
        transform: scale(1.02);
      }
    }
  }

  &__title {
    text-align: left;
    font-size: $rem-18;
    font-weight: $fontWeight-Bold;
    padding: $rem-4 $rem-12;
    padding-top: $rem-12;
    color: $color-neutral2;
  }

  &__description {
    text-align: left;
    font-size: $rem-14;
    color: $color-neutral2;
    padding: 0 $rem-12;
  }

  &__videoContainer {
    width: 100%;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: relative;
      width: 100%;
      padding-top: 50%;
    } 
  }

  &__video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
