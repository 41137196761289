.unpaidBill {
  margin-bottom: $rem-48;

  & > div {
    padding-bottom: $rem-24;

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__topCard {
    overflow-y: hidden;
    overflow-x: auto;
    margin-bottom: $rem-24;
  }
}
