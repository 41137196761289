.registerPage {
  min-height: 100vh;
  letter-spacing: 0.2px;
  &__logo {
    width: 150px;
    height: auto;
    margin-left: $rem-32;
    padding: 0;
    margin-bottom: $rem-40;
    cursor: pointer;
  }

  &__announcement {
    height: fit-content;
    width: 80%;
    margin-bottom: $rem-16;
    @include media("t-large", max) {
      width: 95%;
    }
  }

  &__infoContainer {
    color: $color-primary1;
    line-height: $rem-24;
    font-size: $rem-16;
  }

  &__infoTitle {
    font-weight: 600;
    padding-bottom: $rem-8;
  }

  &__imgContainer {
    justify-content: center;
    @include media("t-large", max) {
      justify-content: center;
    }
  }

  &__img {
    @include media("t-large", max) {
      max-width: 40%;
      min-width: 250px;
    }
  }

  &__contentContainer {
    position: relative;
  }

  &__infoContent--link {
    color: $color-primary5;
    &:link {
      color: $color-primary5;
    }
    &:hover {
      color: $color-primary4;
    }
  }

  &__row {
    justify-content: center;
  }

  &__video {
    padding-bottom: $rem-48;
    padding-right: $rem-16;
    @include media("t-large", max) {
      padding: 0;
      padding-bottom: $rem-24;
      min-height: 400px;
    }
  }
}
.registerDetails1 {
  width: 100%;
  &__title {
    font-weight: 600;
    font-size: $rem-32;
    padding-bottom: $rem-16;
  }

  &__buttonContainer {
    display: flex;
    .button {
      border-radius: $rem-40;
      height: $rem-48;
    }

    @include media("t-large", max) {
      flex: auto;
      flex-wrap: wrap;
    }
  }

  &__cancelBtnContainer {
    margin-right: $rem-24;
  }

  &__button {
    flex: 1 1 50%;
  }

  &__loginContainer {
    font-size: $rem-14;
  }
  
  &__loginLink {
    color: $color-secondary1;
    cursor: pointer;
    &--disabled {
      cursor: not-allowed;
    }
    &:hover {
      color: $color-secondary1;
    }
  }

  &__label {
    font-size: $rem-12;
    padding-bottom: $rem-4;
    color: $color-neutral6;
  }

  @include media("t-large", max) {
    &__buttonContainer {
      flex-direction: column-reverse;
    }
    &__cancelBtnContainer {
      margin-right: 0;
      margin-top: $rem-16;
    }
  }
}
