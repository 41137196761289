.adminManageUserTable {
  &__masterRoleContainer {
    align-items: center;
    @include media("t-large", max) {
      flex-direction: column;
      align-items: initial;
    }
  }
  &__trasferBtnContainer {
    min-width: 240px;
    .button {
      border-radius: $rem-40;
      height: $rem-48;
    }
    @include media("t-large", max) {
      min-width: 100%;
      margin-top: $rem-16;
    }
  }
}
