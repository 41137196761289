.detailCard {
  &__block {
    padding: $rem-48;
    @include media("t-large", max) {
      padding: $rem-32;
    }
  }
  &__currentPaymentRow {
    padding-top: $rem-32;
    @include media("t-large", max) {
      flex-direction: column;
      padding-top: 0;
    }
  }
  &__detailContainer {
    @include media("t-large", max) {
      padding-top: $rem-24;
    }
  }
  &__label {
    color: $color-neutral3;
    font-size: $rem-12;
    padding-bottom: $rem-4;
  }
  &__data {
    color: $color-neutral1;
  }
  &__statusPositive {
    color: $color-positive;
  }
  &__statusWarning {
    color: $color-warning;
  }
  &__statusError {
    color: $color-error-lightBackground;
  }

  &__currentPaymentActivityContainer {
    padding-top: $rem-48;
    @include media("t-large", max) {
      padding-top: $rem-32;
    }
  }
  &__currentPaymentSectionHeader {
    margin-bottom: 0;
  }

  &__buttonsContainer {
    justify-content: space-between;
    padding-top: $rem-8;
    @include media("t-large", max) {
      padding-top: 0;
      justify-content: center;
      flex-direction: column;
    }
  }

  &__rightBtnsContainer {
    @include media("t-large", max) {
      flex-direction: column;
    }
  }

  &__declineBtnContainer,
  &__reconcileBtnContainer,
  &__approveBtnContainer {
    .button {
      height: $rem-48;
      border-radius: $rem-40;
      padding-left: $rem-24;
      padding-right: $rem-24;
    }
  }

  &__declineBtnContainer {
    .button {
      color: $color-error-lightBackground;
      border-color: $color-error-lightBackground;
      &:hover {
        color: $color-error-lightBackground;
        border-color: $color-error-lightBackground;
      }
    }
    @include media("t-large", max) {
      margin-bottom: $rem-16;
    }
  }

  &__reconcileBtnContainer {
    margin-right: $rem-16;
    @include media("t-large", max) {
      margin-right: 0;
      margin-bottom: $rem-16;
    }
  }

  &__paymentHistoryTitleContainer {
    cursor: pointer;
    color: $color-primary4;
    font-size: $rem-24;
    &:hover {
      color: $color-primary5;
      .detailCard__paymentHistoryChevron {
        fill: $color-primary5;
      }
    }
    @include media("m-large", max) {
      font-size: $rem-16;
    }
  }

  &__paymentHistoryChevron {
    width: $rem-24;
    fill: $color-primary5;
    margin-left: $rem-8;
    transition: transform 0.5s;
    &.rotateImage {
      transform: rotate(90deg);
    }
  }
  &__paymentHistoryDetailsContainer {
    overflow: hidden;
    transition: max-height 0.5s;
    max-height: 0;
    animation: fadeOut 0.8s;
    &.hasDetail {
      max-height: 3000px;
      animation: fadeIn 0.8s;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
