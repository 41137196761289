.masterHeader {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  .header-profile-nav {
    & > .profile-nav-item {
      position: relative;
      cursor: pointer;
      padding: $rem-8;

      .profile-name {
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .profile-user-icon {
        fill: $color-neutral8;
        width: $rem-24;
        height: $rem-24;
      }

      .profile-bell-icon {
        fill: $color-neutral8;
        width: $rem-24;
        height: $rem-20;
      }

      .profile-caret-icon {
        fill: $color-neutral8;
        width: $rem-13;
        height: $rem-13;
      }
    }
  }

  &__popoverTrigger {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__profileSelection {
    font-size: $rem-14;
    line-height: 1.4286;

    @include media("t-large", max) {
      display: none;
    }
  }

  &__selectionDivider {
    border-bottom: 1px solid $color-neutral6;
    margin: 0 $rem-12;
  }

  &__selectionList {
    border-radius: $rem-4;
    overflow: hidden;
  }

  &__selectionListItem {
    padding: $rem-12;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: $color-neutral8;
      background-color: $color-primary5;

      .masterHeader__selectionLogo {
        fill: $color-neutral8;
      }

      .icon-learn-i {
        fill: $color-primary5;
      }
    }
  }

  &__selectionLogo {
    fill: $color-neutral2;
    width: 24px;
    height: 24px;

    + .masterHeader__selectionText {
      padding-left: $rem-8;
    }

    .icon-learn-i {
      fill: $color-neutral8;
    }
  }

  &__logout {
    width: 24px;
    height: 24px;
    fill: $color-neutral8;
  }

  &__offcanvas {
    &Shape {
      width: 100%;
      height: 100%;
      background: none;
      border: 0;
      display: flex;
      flex-direction: column;
    }
    &IconLine {
      height: 3px;
      width: 100%;
      background-color: $color-neutral8;
      margin-bottom: $rem-4;
    }
    .navbar-nav {
      padding: 0;
      margin: 0 $rem-24;
    }
    .nav-link {
      padding: $rem-16 0;
      border-bottom: 1px solid $color-neutral6;
    }

    .masterHeader__offcanvasList {
      border-radius: $rem-4;
      overflow: hidden;
    }

    .masterHeader__offcanvasListItem {
      padding: $rem-12 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: $rem-14;
 
      &:hover {
        .masterHeader__offcanvasText {
          color: $color-primary4;
        }
        .masterHeader__offcanvasLogo {
          fill: $color-primary4;
        }
      }

      &--borderTop {
        border-top: 1px solid $color-neutral6;
      }
    }

    .masterHeader__offcanvasText {
      color: $color-neutral1;
    }

    .masterHeader__offcanvasLogo {
      fill: $color-neutral2;
      width: 24px;
      height: 24px;

      + .masterHeader__offcanvasText {
        padding-left: $rem-8;
      }

      .icon-learn-i {
        fill: $color-neutral8;
      }
    }
  }

  &__offcanvasItem, &__offcanvasItem:link {
    color: $color-neutral1;
    font-size: $rem-14;
  }
}

a.masterHeader__selectionListItem {
  color: $color-neutral1;

  &:hover {
    color: $color-neutral8;
  }
}
