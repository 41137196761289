.searchResults {
  &__sectionHeader {
    padding-bottom: $rem-24;
    @include media("t-large", max) {
      padding-bottom: $rem-16;
    }
  }
  &__filterPopover {
    max-height: 315px;
    overflow-y: auto;
    @include media("t-large", max) {
      max-height: none;
    }
  }
}
