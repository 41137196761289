.customDateRange {
  width: 312px;
  font-size: $rem-16;
  padding-top:1rem;

  .rdrWeekDay {
    color: $color-primary4;
    height: 40px;
    line-height: 40px;
  }

  .rdrDays {
    border-bottom: 1px solid #e5e5e5;
  }

  .rdrDay {
    height: 40px;
    line-height: 40px;
  }

  .rdrMonth {
    width: 100%;
    padding-bottom: 0;
  }

  .rdrDayToday {
    .rdrDayNumber span {
      font-weight: $fontWeight-Normal;
      &:after {
        background: transparent;
      }
    }

    &:not(.rdrDayPassive) {
      .rdrInRange ~ .rdrDayNumber span:after,
      .rdrStartEdge ~ .rdrDayNumber span:after,
      .rdrEndEdge ~ .rdrDayNumber span:after,
      .rdrSelected ~ .rdrDayNumber span:after {
        background: transparent;
      }
    }
  }

  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    border: 0;
    background-color: transparent;
    top: 5px;
    bottom: 5px;
  }

  .rdrDayPassive .rdrDayNumber span {
    color: $color-neutral6;
  }

  .rdrDayDisabled {
    background-color: transparent;
  }

  .rdrDayStartPreview {
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    right: 0;
  }

  .rdrDayEndPreview {
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    left: 0;
  }

  .rdrDayStartOfMonth .rdrDayInPreview,
  .rdrDayStartOfMonth .rdrDayEndPreview,
  .rdrDayStartOfWeek .rdrDayInPreview,
  .rdrDayStartOfWeek .rdrDayEndPreview {
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    border-left-width: 1px;
    left: 0px;
  }

  .rdrDayEndOfMonth .rdrDayInPreview,
  .rdrDayEndOfMonth .rdrDayStartPreview,
  .rdrDayEndOfWeek .rdrDayInPreview,
  .rdrDayEndOfWeek .rdrDayStartPreview {
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    border-right-width: 1px;
    right: 0px;
  }

  .rdrDayStartOfMonth .rdrDayInPreview ~ .rdrDayNumber,
  .rdrDayStartOfWeek .rdrDayEndPreview ~ .rdrDayNumber,
  .rdrDayStartOfWeek .rdrDayInPreview ~ .rdrDayNumber,
  .rdrDayStartOfWeek .rdrDayEndPreview ~ .rdrDayNumber,
  .rdrDayStartPreview ~ .rdrDayNumber {
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    left: 0;
  }

  .rdrDayEndOfMonth .rdrDayInPreview ~ .rdrDayNumber,
  .rdrDayEndOfMonth .rdrDayStartPreview ~ .rdrDayNumber,
  .rdrDayEndOfWeek .rdrDayInPreview ~ .rdrDayNumber,
  .rdrDayEndOfWeek .rdrDayStartPreview ~ .rdrDayNumber,
  .rdrDayEndPreview ~ .rdrDayNumber {
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    right: 0;
  }

  .rdrDay:not(.rdrDayPassive) .rdrDayInPreview ~ .rdrDayNumber,
  .rdrDay:not(.rdrDayPassive) .rdrDayStartPreview ~ .rdrDayNumber,
  .rdrDay:not(.rdrDayPassive) .rdrDayEndPreview ~ .rdrDayNumber {
    background: currentColor;
    border: 1px solid transparent;
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    bottom: 5px;
    span {
      color: #fff;
    }
  }

  .rdrInRange ~ .rdrDayStartPreview.rdrDayEndPreview ~ .rdrDayNumber,
  .rdrStartEdge ~ .rdrDayStartPreview.rdrDayEndPreview ~ .rdrDayNumber,
  .rdrEndEdge ~ .rdrDayStartPreview.rdrDayEndPreview ~ .rdrDayNumber {
    background: transparent;
    border: 0;
  }

  .rdrDay:not(.rdrDayPassive) .rdrDayStartPreview ~ .rdrDayNumber {
    left: 0;
  }

  .rdrDay:not(.rdrDayPassive) .rdrDayEndPreview ~ .rdrDayNumber {
    right: 0;
  }

  &__monthAndYearPickerWrapper {
    margin: 4px 1.5em;
    border-bottom: 1px solid $color-neutral6;
  }

  &__monthAndYearPicker {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $rem-14;
    line-height: 1.4285;
  }

  &__pickerLabel {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $rem-12;
    font-weight: $fontWeight-Bold;
  }

  &__prevButton,
  &__nextButton {
    display: block;
    width: 24px;
    height: 24px;
    padding: 0;
    border: 0;
    border-radius: 5px;
    background-color: transparent;
    color: $color-neutral2;

    &--disabled {
      color: $color-neutral6;
      cursor: not-allowed !important;
    }
  }

  &__prevButton {
    text-align: left;
  }

  &__nextButton {
    text-align: right;
  }

  &__chevron {
    width: $rem-20;
    width: $rem-20;
    padding-bottom: $rem-4;
  }
}

.customDateRangeShowAll{
  .rdrStartEdge,.rdrInRange,.rdrEndEdge{
    background-color: transparent;
  }
  .rdrDayNumber{
    span{
      color: #aeb9bf !important;
    }
  }
}
 
.favourite_disabled{
  opacity:0.4;
}