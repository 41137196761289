.transferRoleModal {
  &__dataRowContainer {
    text-align: left;
    @include media("t-large", max) {
      flex-direction: column;
    }
  }
  &__dropdownContainer {
    padding-top: 5px;
    @include media("t-large", max) {
      margin-bottom: $rem-24;
    }
  }
  &__dropdownPopover {
    max-height: 162px;
  }
}
