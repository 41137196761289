.notificationBell {
  &__icon {
    fill: $color-neutral8;
    width: $rem-24;
    height: $rem-20;
  }
  &__iconNumberContainer {
    position: relative;
  }
  &__iconNumber {
    position: absolute;
    top: -12px;
    right: -10px;
    background-color: $color-positive;
    color: $color-neutral1;
    width: $rem-24;
    height: $rem-24;
    border-radius: $rem-16;
    font-size: $rem-12;
    text-align: center;
    font-weight: $fontWeight-Bold;
    padding-top: $rem-4;
    &--small {
      font-size: $rem-14;
    }
  }
  &__popoversChild {
    white-space: pre-wrap;
    margin-top: $rem-8;
    font-size: $rem-16;
    line-height: $rem-24;
  }
  .tooltip-arrow {
    top: $rem-8;
  }
  .tooltip-inner {
    border-radius: $rem-8;
  }
  &__emptyData {
    max-width: 300px;
    font-weight: $fontWeight-Bold;
  }
  &__dataContainer {
    padding: $rem-24;
    max-width: 420px;
  }
  &__readDotContainer {
    padding-right: $rem-12;
    padding-top: $rem-8;
  }
  &__readDot {
    width: $rem-8;
    height: $rem-8;
    border-radius: $rem-8;
  }
  &__readDotNoRead {
    background-color: $color-positive;
  }
  &__titleNoRead {
    font-weight: $fontWeight-Bold;
  }
  &__itemContainer {    
    &:not(:last-child) {
      padding-bottom: $rem-16;
      border-bottom: $rem-1 solid $color-neutral6;
      margin-bottom: $rem-16;
    }
  }
  &__item {
    cursor: pointer;
    display: flex;
  }
  &__title {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &__notificationTime {
    color: $color-neutral2;
    font-size: $rem-14;
    padding-top: $rem-4;
  }
  &__bottom {
    border-top: $rem-1 solid $color-neutral6;
    padding: $rem-16;
    text-align: center;
    color: $color-primary5;
    cursor: pointer;
    &:hover {
      color: $color-primary4;
    }
  }
  &__emptyData {
    text-align: center;
  }
}
