.pgQueryLoader {
  border-radius: $rem-16;
  z-index: 4;
  &__label {
    height: $rem-18;
    &--short {
      width: 35%;
      min-width: 100px;
    }
    &--shorter {
      width: 30%;
      min-width: 100px;
    }
    &--long {
      width: 50%;
      min-width: 150px;
    }
  }

  &__tableControl {
    height: $rem-24;
  }

  &__table {
    height: $rem-112;
  }

  &__button {
    height: $rem-36;
  }
}