.viewCustomerBlock {
  padding: $rem-32;
  margin-bottom: $rem-40;
  color: $color-neutral1;
  &__topContainer {
    @include media("t-large", max) {
      flex-direction: column;
    }
    &--hasPadBottom {
      padding-bottom: $rem-48;
      @include media("t-large", max) {
        padding-bottom: $rem-32;
      }
    }
  }
  &__description {
    @include media("t-large", max) {
      padding-bottom: $rem-32;
    }
  }
  &__btnContainer {
    max-width: 240px;
    min-width: fit-content;
    margin-left: auto;
    margin-top: $rem-48;
    .button {
      border-radius: $rem-40;
      height: $rem-48;
    }
    @include media("t-large", max) {
      margin-top: $rem-32;
      max-width: 100%;
    }
  }
}
