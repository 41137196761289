.commonModal {
  &__icon {
    width: $rem-56;
    height: $rem-56;
  }
  &__icon--positive {
    fill: $color-primary5;
  }
  &__icon--warning {
    fill: $color-error-lightBackground;
  }
  &__successIcon {
    width: $rem-56;
    height: $rem-56;
    fill: $color-neutral8;
    .icon-check-filled {
      fill: $color-positive;
    }
  }
  &__infoIcon {
    fill: $color-primary5;
    width: $rem-56;
    height: $rem-56;
  }
  &__notification {
    position: relative;
  }

  &__secondBtn {
    margin-left: $rem-24;
    @include media("t-large", max) {
      margin-left: 0;
      margin-top: $rem-16;
    }
  }

  &__disabledBtn {
    .button {
      cursor: not-allowed;
    }
  }

  &__buttons {
    min-width: 190px;
    .button {
      height: $rem-48;
      border-radius: $rem-40;
    }
    @include media("t-large", max) {
      min-width: 100%;
      .button {
        flex: auto;
      }
    }
  }
  &__buttonContainer {
    @include media("t-large", max) {
      flex-direction: column;
    }
  }
}
