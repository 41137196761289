.billPayment {
  &__title {
    font-size: $rem-24;
    line-height: 1.3333;
    color: $color-secondary1;
  }

  &__chartTitle {
    font-size: $rem-20;
    line-height: 1.4;
    text-align: center;
  }

  &__tooltipLastUpdated {
    margin-top: $rem-12;
    font-size: $rem-12;
    line-height: $rem-16;
    color: $color-neutral2;
  }
}
