.footer {
  color: $color-neutral8;
  background: $color-footer;
  display: flex;
  flex-wrap: wrap;
  padding: $rem-32 $rem-72;

  @include media("t-large", max) {
    padding-left: $rem-16;
    padding-right: $rem-16;
  }

  &__item {
    flex: 1 1 33%;
    display: flex;
    flex-wrap: wrap;

    @include media("t-large", max) {
      &:not(:last-child) {
        flex-basis: 100%;
        margin-bottom: $rem-24;
      }
    }
  }

  &__subItem {
    flex: 1 1 50%;

    &--logo {
      @include media("t-large", max) {
        display: none;
      }
    }
  }

  &__logo {
    width: 150px;
    height: auto;
  }

  &__contact {
    margin: 0 auto;
    @include media("t-large", max) {
      margin: 0;
    }
  }

  &__paragraph {
    font-size: 0.875rem;
    line-height: 1.1667;
    margin-bottom: 1rem;
  }

  &__paragraph--withSection {
    display: flex;
    margin-bottom: 0;
    > .footer__section {
      flex: 1 1 30%;
      display: flex;
    }
  }

  &__text--highlight {
    color: $color-secondary1;
  }

  &__paragraph--near {
    margin-bottom: 0.5rem;
  }

  &__copyright {
    color: $color-neutral4;
  }

  &__iconContainer {
    display: flex;
  }

  &__icon {
    cursor: pointer;
    transform: translateY(-6px);
    width: 32px;
    height: 32px;
    line-height: 36px;
    text-align: center;
    border-radius: 16px;
  }

  &__iconFooter {
    fill: $color-footer;
    &:hover {
      fill: $color-neutral8;
      .icon-android,
      .icon-apple,
      .icon-facebook,
      .icon-twitter,
      .icon-instagram,
      .icon-youtube,
      .icon-linkedin {
        display: none;
      }
    }
    .icon-android,
    .icon-apple,
    .icon-facebook,
    .icon-twitter,
    .icon-instagram,
    .icon-youtube,
    .icon-linkedin {
      fill: $color-neutral8;
    }
  }

  &__browserCompatibility {
    cursor: pointer;
  }
}

.browserCompatibility {
  color: $color-neutral1;

  &__header {
    border: 0;
    padding-bottom: 0;

    button {
      width: 12px;
      height: 12px;
      background-size: 12px 12px;
      opacity: 0.25;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  &__body {
    padding: $rem-16 $rem-40 $rem-32 $rem-40;
  }

  &__modalCloseButton {
    .button {
      padding-top: $rem-12;
      padding-bottom: $rem-12;
    }
  }
}
