.customModal {
  .modal-header {
    border-bottom: none;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0;
  }
  .modal-content {
    border-radius: $rem-16;
    padding: $rem-40 $rem-48;

    position: relative;
    color: $color-neutral1;
    font-size: $rem-24;
  }
  .modal-title {
    font-size: $rem-24;
    line-height: $rem-32;
    padding-top: $rem-32;
  }
  .modal-body {
    font-size: $rem-16;
    line-height: $rem-24;
    text-align: center;
  }
  .modal-footer {
    border-top: none;
    display: block;
    padding: 2px 16px;
    text-align: center;
  }
  .btn-close {
    box-shadow: none;
    position: absolute;
    right: 24px;
    top: 24px;
    width: 8px;
    height: 8px;
    padding: 4px;
  }
  .modal__NEWImage {
    position: absolute;
    top: 23px;
    left: -8px;
  }
  .modal__image {
    margin: auto;
  }
  @include media("t-large", max) {
    .modal-content {
      padding: $rem-24 $rem-16;
    }
    .modal-title {
      font-size: $rem-16;
      line-height: $rem-24;
      padding-top: $rem-24;
    }
    .modal-body {
      font-size: $rem-14;
      line-height: $rem-20;
    }
  }
}
