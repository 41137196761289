.fileUpload {
  position: relative;
  height: fit-content;
  width: 100%;
  cursor: pointer;

  &--isLoading {
    cursor: not-allowed;
    pointer-events: none;
  }
  
  &__hidden {
    display: none;
  }

  &__icon {
    padding-right: $rem-20;
  }

  &__mainLabel {
    font-size: $rem-14;
    line-height: 1.4286;
  }

  &__subLabel {
    font-size: $rem-12;
    line-height: 1.33;
  }

  &__hint {
    font-size: $rem-14;
    line-height: 1.7143;
    color: $color-neutral3;

    &--error {
      color: $color-warning;
    }
  }
  
  &__target {
    height: fit-content;
    width: 100%;
    padding: $rem-20;
    border: 1px dashed $color-primary4;
    border-radius: 5px;
    display: flex;
    align-items: center;
    color: $color-primary4;
    background-color: $color-neutral11;
  }

  &__target.fileUpload__draggingOverFrame {
    opacity: 0.5;
  }

  &__target.fileUpload__draggingOverTarget {
    opacity: 1;
    background-color: $color-secondary9;
  }

  &__spinner {
    border: 4px solid $color-neutral7;
    border-top: 4px solid $color-primary5;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spinning 2s linear infinite;
  }

  @keyframes spinning {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
