.notifications {
  &__body {
    margin-bottom: $rem-24;
  }

  &__block {
    padding: 0;
    overflow: hidden;
    min-height: auto;
    position: sticky;
    top: $rem-24;
  }

  &__left {
    &--hasSelection {
      @include media("t-large", max) {
        display: none;
      }
    }
  }

  &__section {
    &--right {
      align-self: flex-end;
    }
  }

  &__sectionHeader {
    padding: $rem-24 $rem-24 0 $rem-24;
    margin-bottom: $rem-24;
    align-items: baseline;
    background-color: $color-secondary6;
    box-shadow: 0px 7px 12px rgba(52, 115, 238, 0.2);
    
    @include media("t-large", max) {
      flex-direction: row;
      align-items: center;

      .section-header__description {
        margin-bottom: $rem-16;
      }
    } 
  }
  
  &__sectionTitle {
    margin-bottom: 0;
    
    @include media("t-large", max) {
      margin-bottom: $rem-16;
    }
  }
  
  &__sectionPanel {
    display: flex;
    align-items: center;
  }

  &__buttonContainer {

  }

  &__panel {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
  }

  &__selectToggle {
    border-right: 1px solid $color-neutral5;
  }

  &__filter {
    // display: inline-block;
  }

  &__list {
    max-height: 800px;
    overflow-y: auto;
    overflow-x: hidden;
    &--selectMode {
      .notifications__checkbox {
        display: block;
      }
    }
  }
  
  &__group {
    margin-bottom: $rem-16;

    &:last-child .notifications__item:last-child .notifications__itemDetail {
      border-bottom: 0;
    }
  }

  &__groupTitle {
    padding: $rem-8 $rem-24;
    font-size: $rem-24;
    color: $color-neutral1;
    line-height: 1.3333;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 0 $rem-24;
    position: relative;
    
    &:hover {
      background-color: $color-neutral7;
    }

    &--isUnread {
      font-weight: $fontWeight-Bold;

      .notifications__dot {
        display: block;
      }
    }

    &--selected {
      background-color: $color-secondary9;
      &:hover {
        background-color: $color-secondary9;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: $rem-8;
        background: $color-primary5;
      }
    }
  }

  &__itemDetail {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: $rem-16 0;
    border-bottom: 1px solid $color-neutral5;
    overflow: hidden;
    cursor: pointer;
    
    @include media("t-large", max) {
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  &__checkboxAllContainer {
    flex-basis: $rem-24;
  }

  &__checkbox {
    display: none;
    flex-basis: fit-content;
  }

  &__indicator {
    flex-basis: 40px;
    min-width: 40px;
    
    @include media("t-large", max) {
      flex-basis: 20px;
      min-width: 20px;
    }
  }

  &__dot {
    display: none;
    margin: 0 auto;
    height: 8px;
    width: 8px;
    border-radius: 0.25em;
    background-color: $color-positive;
  }

  &__icon {
    flex-basis: 40px;
    height: 40px;
  }

  &__text {
    padding: 0 $rem-16;
    flex-grow: 1;
    overflow: hidden;
    
    @include media("t-large", max) {
      order: 1;
    }
  }

  &__title {
    color: $color-neutral14;
    padding-bottom: $rem-8;
    line-height: 1.5;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__description {
    color: $color-neutral2;
    font-size: $rem-14;
    line-height: 1.4286;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__timestamp {
    font-weight: $fontWeight-Normal;
    color: $color-neutral3;
    justify-self: flex-end;
    text-align: right;
    white-space: nowrap;
  }

  &__detail {
    position: sticky;
    height: fit-content;
    top: $rem-24;
    margin-bottom: $rem-24;
  }

  &__closeButton {
    cursor: pointer;
    color: $color-neutral14;
    position: absolute;
    top: $rem-20;
    right: $rem-28;
    width: $rem-24;
    height: $rem-24;
  }

  &__status {
    color: $color-primary5;
    font-size: $rem-20;
    line-height: 1.4;
    padding: $rem-16 0;
  }

  &__actionButton {
    margin: $rem-16 20% 0 20%;

    .button {
      padding-top: $rem-12;
      padding-bottom: $rem-12;
    }
  }
}