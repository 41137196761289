@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@for $i from 0 through 16 {
  $step: $i / 4;
  $step-str: str-replace(inspect($step), ".", "_");
  .pad-#{$step-str} {
    padding: $step * 1rem;
  }
  .pad-x-#{$step-str} {
    padding-left: $step * 1rem;
    padding-right: $step * 1rem;
  }
  .pad-y-#{$step-str} {
    padding-top: $step * 1rem;
    padding-bottom: $step * 1rem;
  }
  .pad-top-#{$step-str} {
    padding-top: $step * 1rem;
  }
  .pad-bottom-#{$step-str} {
    padding-bottom: $step * 1rem;
  }
  .pad-left-#{$step-str} {
    padding-left: $step * 1rem;
  }
  .pad-right-#{$step-str} {
    padding-right: $step * 1rem;
  }
}

@for $i from 0 through 16 {
  $step: $i/4;
  $step-str: str-replace(inspect($step), ".", "_");
  .mar-#{$step-str} {
    margin: $step * 1rem !important;
  }
  .mar-x-#{$step-str} {
    margin-left: $step * 1rem !important;
    margin-right: $step * 1rem !important;
  }
  .mar-y-#{$step-str} {
    margin-top: $step * 1rem !important;
    margin-bottom: $step * 1rem !important;
  }
  .mar-top-#{$step-str} {
    margin-top: $step * 1rem !important;
  }
  .mar-bottom-#{$step-str} {
    margin-bottom: $step * 1rem !important;
  }
  .mar-left-#{$step-str} {
    margin-left: $step * 1rem !important;
  }
  .mar-right-#{$step-str} {
    margin-right: $step * 1rem !important;
  }
}
