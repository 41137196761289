.verifyActivationLink {
  min-height: 100vh;
  letter-spacing: 0.2px;
  &__logo {
    width: 150px;
    height: auto;
    margin-left: $rem-32;
    padding: 0;
    margin-bottom: $rem-40;
    cursor: pointer;
  }

  &__pageContent {
    padding-top: $rem-72;
    @include media("t-large", max) {
      padding-top: 0;
    }
  }

  &__img {
    @include media("t-large", max) {
      max-width: 40%;
      min-width: 250px;
    }
  }

  &__contentContainer {
    position: relative;
    padding-bottom: $rem-40;
  }

  &__notification {
    position: relative;
    &--noError {
      visibility: hidden;
      transition: visibility 0.3s ease;
    }
  }

  &__title {
    font-weight: 600;
    font-size: $rem-32;
    padding-bottom: $rem-16;
  }

  &__loginBtn {
    max-width: 240px;
    min-width: fit-content;
    margin-left: auto;
    .button {
      border-radius: $rem-40;
      height: $rem-48;
    }
    @include media("t-large", max) {
      max-width: 100%;
    }
  }

  &__forgotPasswordLink {
    font-size: $rem-14;
    line-height: 1.4286;
    color: $color-secondary1;
    &:link {
      color: $color-secondary1;
    }
    &:hover {
      color: $color-secondary1;
    }
    &.disabled {
      cursor: default;
    }
  }
}
