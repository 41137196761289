.addAccountsSuccess {
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  &__block {
    padding-top: $rem-32;
    padding-bottom: $rem-32;
    @include media("t-large", max) {
      padding: $rem-16;
    }
  }
  &__title {
    font-weight: $fontWeight-Bold;
    font-size: $rem-32;
    color: $color-primary1;
    @include media("t-large", max) {
      font-size: $rem-20;
    }
  }
  &__description {
    font-weight: $fontWeight-Normal;
    font-size: $rem-16;
    line-height: $rem-24;
    color: $color-neutral1;
  }
  &__detailTitle {
    font-size: $rem-12;
    line-height: $rem-16;
    color: $color-neutral3;
  }
  &__detailInfo {
    font-size: $rem-16;
    line-height: $rem-24;
    color: $color-neutral1;
  }

  &__buttonContainer {
    display: flex;
    .button {
      border-radius: $rem-40;
      height: $rem-48;
      width: 184px;
      min-width: max-content;
    }

    @include media("t-large", max) {
      flex: auto;
      flex-direction: column;
      .button {
        width: 100%;
      }
    }
  }
  &__manageContainer {
    margin-left: $rem-24;
    @include media("t-large", max) {
      margin-left: 0;
      margin-top: $rem-16;
    }
  }
  @include media("t-large", max) {
    margin-bottom: $rem-36;
    &__container {
      padding: 0;
    }
    &__infoContainer {
      flex-direction: column;
    }
  }
}
