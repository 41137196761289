.pendingCA {
  color: $color-neutral1;

  &__tableContainer {
    height: fit-content;
  }

  &__btnsContainer {
    display: flex;
    justify-content: flex-end;
    @include media("m-large", max) {
      flex-direction: column;
      justify-content: center;
    }
  }

  &__btnContainer {
    min-width: 120px;
    .button {
      border-radius: $rem-40;
      height: $rem-48;
    }
    @include media("m-large", max) {
      max-width: 100%;
    }
  }

  &__removeBtn,
  &__removeBtnDisabled {
    margin-right: $rem-16;
    @include media("m-large", max) {
      margin-right: 0;
      margin-bottom: $rem-16;
    }
  }

  &__removeBtn {
    .button {
      color: $color-error-lightBackground;
      border-color: $color-error-lightBackground;
      &:hover {
        color: $color-error-lightBackground;
        border-color: $color-error-lightBackground;
      }
    }
  }
}
