.profilePage {
  min-height: 87vh;
  letter-spacing: 0.2px;
  padding-left: 11px;
  padding-right: 11px;
  position: relative;
  margin-bottom: $rem-32;
  &__notificationContainer {
    position: absolute;
    max-width: 1016px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: -8px;
  }
  &__detail {
    padding: $rem-32 $rem-40 $rem-32 $rem-40;
  }
  &__detailProfile {
    color: $color-primary4;
    font-size: $rem-24;
    line-height: $rem-32;
    display: flex;
    align-items: center;
  }
  &__userRole {
    background: $color-primary1;
    margin-left: $rem-8;
    padding: $rem-4 $rem-16;
    color: $color-neutral8;
    width: fit-content;
    border-radius: $rem-32;
    font-size: $rem-16;
    text-align: center;
  }
  &__detailLeftContainer {
    min-width: 40%;
    padding-right: 3rem;
  }
  &__detailMiddleContainer {
    min-width: 35%;
    padding-right: 3rem;
  }
  &__detailTitle {
    font-size: $rem-12;
    line-height: $rem-18;
    color: $color-neutral3;
  }
  &__detailInfo {
    font-size: $rem-16;
    line-height: 1.375rem;
    color: $color-neutral1;
    overflow-wrap: break-word;
    &--emailImage {
      margin-left: $rem-8;
      width: $rem-16;
    }
    &--name, &--ic {
      max-width: fit-content;
    }
  }
  &__detailButtonContainer {
    width: 240px;
    height: $rem-48;
    margin-left: auto;
    min-width: max-content;
    .button {
      border-radius: $rem-40;
    }
  }
  &__nav {
    margin: auto;
  }
  &__disabled {
    cursor: not-allowed;
    color: $color-neutral7;
  }
  @include media("t-large", max) {
    &__detailContainer {
      flex-direction: column;
      padding-bottom: $rem-36;
    }
    &__detailLeftContainer {
      padding-right: 0;
    }
    &__detail {
      padding: $rem-20 $rem-16 $rem-36 $rem-16;
    }
    &__detailProfile {
      padding-bottom: 0rem;
      font-size: $rem-16;
      line-height: $rem-24;
    }
    &__detailName, &__detailIC, &__detailEmail, &__detailMobile, &__detailEmailNotification {
      padding-top: $rem-24;
      padding-bottom: 0rem;
    }
    &__detailButtonContainer {
      width: auto;
    }
  }
}

.notification {
  &__textValue {
    padding-top: 22px;
    padding-bottom: 22px;
  }
  &__icon {
    padding-top: $rem-20;
  }
}