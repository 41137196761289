.searchBillTable {
  min-height: max-content;
  display: flex;
  align-items: center;

  &__excelSection {
    padding-bottom: $rem-16;
    margin-bottom: $rem-16;
    border-bottom: 1px solid $color-neutral5;
  }

  &__excelContainer {
    margin: $rem-24 0;
    button.button {
      padding-left: $rem-48;
      padding-right: $rem-48;
    }
  }

  &__download-icon-wrapper {
    cursor: pointer;
    text-align: center;
    &:hover {
      fill: $color-primary5;
      color: $color-primary5;
    }
  }

  &__download-icon {
    width: $rem-20;
  }

  &__status-icon {
    cursor: pointer;
    width: $rem-20;
    fill: $color-neutral8;

    &:hover {
      fill: $color-secondary1;
    }
  }
  &__statusTooltip {
    white-space: pre-wrap;
    margin-top: $rem-8;
    max-width: 400px;
    font-size: $rem-16;
    line-height: $rem-24;
  }
  &__status-paid {
    color: $color-positive;
  }
  &__status-paymentRequired {
    color: $color-warning;
  }
  &__status-paymentProcessing {
    color: $color-neutral2;
  }
  &__spinner {
    vertical-align: middle;
    display: flex;
    justify-content: center;
    .spinner-border {
      width: $rem-20;
      height: $rem-20;
      border-right: 0.25em solid transparent !important;
    }
  }
  &__filterPopover {
    max-height: 330px;
    overflow-y: auto;
    @include media("t-large", max) {
      max-height: none;
    }
  }
}
