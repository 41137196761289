.tableControls {
  margin-bottom: 1rem;
  &__filterCol {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__filterIconContainer {
    min-width: 40px;
    height: 40px;
  }

  &__sort {
    width: 24px;
    height: 24px;
    margin: $rem-8;
  }

  &__filterIcon {
    height: 100%;
    width: 100%;
    cursor: pointer;
    fill: $color-neutral2;
    &.active {
      fill: $color-primary5;
    }
    &.disabled {
      fill: $color-neutral5;
      cursor: not-allowed;
    }
  }
}
