.accountByStateLoader {
  background: rgb(19, 35, 129);
  border-radius: $rem-16;
  z-index: 4;
  &__title {
    height: $rem-30;
    margin-bottom: $rem-32;
  }
  &__info {
    height: $rem-24;
    margin-bottom: $rem-24;
    @include media("t-large", max) {
      display: none;
    }
  }
}