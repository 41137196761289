.manuallyAddAccounts {
  &__block {
    padding: $rem-32;
  }
  &__addNewRow {
    color: $color-primary5;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: $color-primary4;
      .manuallyAddAccounts__addIcon {
        fill: $color-primary4;
      }
    }
  }
  &__addIcon {
    width: $rem-20;
    height: $rem-20;
    fill: $color-primary5;
  }
  &__deleteIcon {
    width: $rem-20;
    height: $rem-20;
    fill: $color-neutral1;
    cursor: pointer;
    &:hover {
      fill: $color-primary4;
    }
  }
  &__bottomContainer {
    display: flex;
    align-items: center;
    @include media("t-large", max) {
      flex-direction: column;
    }
  }
  &__manualAdd {
    color: $color-primary4;
    font-size: $rem-24;
    @include media("m-large", max) {
      font-size: $rem-16;
    }
  }
  &__btnContainer {
    display: flex;
    justify-content: flex-end;
    @include media("t-large", max) {
      justify-content: center;
      flex-direction: column;
      padding-top: $rem-16;
    }
  }
  &__cancelBtnContainer,
  &__saveBtnContainer {
    min-width: 190px;
    .button {
      height: $rem-48;
      border-radius: $rem-40;
    }
    @include media("t-large", max) {
      max-width: 100%;
      .button {
        flex: auto;
      }
    }
  }
  &__cancelBtnContainer {
    margin-right: $rem-24;
    @include media("t-large", max) {
      margin-right: 0;
      margin-top: $rem-16;
      order: 2;
    }
  }

  &__cell {
    &--error {
      color: $color-error-lightBackground;
    }
  }
}
