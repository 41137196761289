.activityLog {
  &__body {
    margin-bottom: $rem-24;
  }

  &__block {
    padding: 0;
    overflow: hidden;
    min-height: auto;
    position: sticky;
    top: $rem-24;
  }

  &__sectionHeader {
    padding: $rem-24 $rem-24 0 $rem-24;
    margin-bottom: $rem-24;
    align-items: baseline;
    background-color: $color-secondary6;
    box-shadow: 0px 7px 12px rgba(52, 115, 238, 0.2);
    
    @include media("t-large", max) {
      flex-direction: row;
      align-items: center;

      .section-header__description {
        margin-bottom: $rem-16;
      }
    } 
  }
  
  &__sectionTitle {
    margin-bottom: 0;
    
    @include media("t-large", max) {
      margin-bottom: $rem-16;
    }
  }
  
  &__sectionDescription {
    @include media("t-large", min) {
      margin-top: $rem-16;
      margin-bottom: $rem-16;
      
    }
  }

  &__panel {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
  }

  &__selectToggle {
    border-right: 1px solid $color-neutral5;
  }

  &__filter {
    // display: inline-block;
  }

  &__list {
    max-height: 800px;
    overflow-y: auto;
    overflow-x: hidden;
    &--selectMode {
      .activityLog__checkbox {
        display: block;
      }
    }
  }
  
  &__group {
    margin-bottom: $rem-16;

    &:last-child .activityLog__item:last-child .activityLog__itemDetail {
      border-bottom: 0;
    }
  }

  &__groupTitle {
    padding: $rem-8 $rem-24;
    font-size: $rem-20;
    font-weight: $fontWeight-Bold;
    color: $color-neutral2;
    line-height: 1.4;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 0 $rem-24;
    position: relative;
    
    &:hover {
      background-color: $color-neutral7;
    }
  }

  &__itemDetail {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: $rem-16 0;
    border-bottom: 1px solid $color-neutral5;
    
    @include media("t-large", max) {
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  &__checkbox {
    display: none;
    flex-basis: fit-content;
  }

  &__icon {
    flex-basis: 40px;
    height: 40px;
  }

  &__text {
    padding: 0 $rem-16;
    flex-grow: 1;
    overflow: hidden;
    color: $color-neutral2;
    font-size: $rem-14;
    line-height: 1.4286;
    
    @include media("t-large", max) {
      order: 1;
    }
  }

  &__timestamp {
    font-weight: $fontWeight-Normal;
    color: $color-neutral3;
    justify-self: flex-end;
    text-align: right;
    white-space: nowrap;
  }

  &__detail {
    position: sticky;
    height: fit-content;
    top: $rem-24;
    margin-bottom: $rem-24;
  }

  &__status {
    color: $color-primary5;
    font-size: $rem-20;
    line-height: 1.4;
    padding: $rem-16 0;
  }
}