.master-layout_admin {
  flex-grow: 1;
  width: 100%;

  & > .master-layout-container {
    position: relative;
    height: 100%;
    padding-left: $rem-72;
    padding-right: $rem-72;
  }
  @include media("t-large", max) {
    & > .master-layout-container {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
