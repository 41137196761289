.paymentTransactionTable {
  &__table {
    min-height: 288px;
  }
  
  &__actionColumn {
    display: flex;
    justify-content: space-evenly;
  }
  
  &__actionCell {
    height: 20px;
    min-width: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: $color-neutral2;
    color: $color-neutral2;

    &:hover {
      fill: $color-primary5;
      color: $color-primary5;
    }
  }
  &__actionText {
    flex: 1 0 max-content;
    padding-left: $rem-8;
  }
  &__previewIcon {
    width: $rem-20;
  }
  &__statusIcon {
    cursor: pointer;
    width: $rem-20;
    fill: $color-neutral8;

    &:hover {
      fill: $color-secondary1;
    }
  }
  &__statusTooltip {
    white-space: pre-wrap;
    margin-top: $rem-8;
    max-width: 400px;
    font-size: $rem-16;
    line-height: $rem-24;
  }
  &__status--paid {
    color: $color-positive;
  }
  &__status--actionRequired {
    color: $color-warning;
  }
  &__status--processing {
    color: $color-neutral2;
  }
  &__status--failed {
    color: $color-error-lightBackground;
  }
}

button.paymentTransactionTable__button {
  padding: 0;
  .paymentTransactionTable__buttonSpinner {
    height: $rem-14;
    transform: translateY(-50%);
  }
}