.pendingAction {
  &__export {
    width: fit-content;
  }
  &__button {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $color-primary5;
    fill: $color-primary5;
  }

  &__buttonLogo {
    height: $rem-24;
    width: $rem-24;
    padding-right: $rem-8;
  }
}