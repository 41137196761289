.emptyResult {
  &__img {
    width: $rem-100;
    height: $rem-100;
  }
  &__text {
    color: $color-neutral1;
    font-size: $rem-14;
  }
}
