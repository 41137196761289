.header {
  &__greet {
    color: $color-secondary1;
    font-size: $rem-32;
    line-height: 1.25;
    padding: $rem-16 0;
  }

  &__basicInfo {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__accountNo {
    flex: 1 1 50%;

    @include media("t-large", max) {
      flex-basis: 100%;
      padding-bottom: $rem-8;
    }
  }

  &__lastLogin {
    color: $color-secondary4;
    font-size: $rem-14;
    flex: 1 1 50%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    @include media("t-large", max) {
      display: initial;
      flex-basis: 100%;
      text-align: left;
      padding-bottom: $rem-8;
      border-bottom: 2px solid $color-neutral9;
    }
  }

  &__userRole {
    background: $color-primary7;
    margin-left: $rem-8;
    padding: $rem-4 $rem-16;
    color: $color-neutral8;
    width: fit-content;
    border-radius: $rem-32;

    @include media("t-large", max) {
      margin-left: 0;
      margin-top: $rem-8;
    }
  }
}