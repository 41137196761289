.textarea {
  position: relative;
  &__content {
    width: 100%;
    height: 100%;
    border-radius: $rem-8;
    padding: $rem-8;
  }
  &__noResize {
    resize: none;
  }
  &__count {
    position: absolute;
    color: $color-neutral3;
    bottom: $rem-16;
    right: $rem-16;
    font-size: $rem-14;
  }
}
