.displayCard {
  width: 100%;
  height: 100%;
  &__textValue {
    font-size: 1.5rem;
    font-weight: $fontWeight-Bold;
  }
  &__textPrefix {
    font-size: 1.5rem;
  }
  &__textPrefix--small {
    font-size: 1rem;
  }
  &__logo {
    height: 40px;
    width: 40px;
    background-size: cover;
    align-self: center;
  }
  &__logoContainer {
    flex-grow: 0;
    min-width: fit-content;
  }

  &__latestBillIcon{
    width: $rem-24;
    height: $rem-24;
    fill: $color-neutral2;
    cursor: pointer;
    
    // &:hover {
    //   fill: $color-secondary1;
    // }
  }

  &__iconContainer svg:nth-child(2) {
    display: none;
  }
  
  &__iconContainer:hover svg:nth-child(1) {
    display: none;
  }
  
  &__iconContainer:hover svg:nth-child(2) {
    display: inline-block;
  }

  &__hoverIcon{
    fill: $color-secondary1;
  }

  &__RedContainerText {
    color: $color-neutral18 !important;
  }

  &__OrangeContainerText {
    color: $color-neutral19 !important;
  }

  &__textArrow {
    fill: $color-secondary4;
    width: $rem-20;
    height: $rem-20;
    margin-top: $rem-4;
    cursor: pointer;
    &:hover {
      fill: $color-secondary1;
    }
  }
}
