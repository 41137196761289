.profileLoader {
  border-radius: $rem-16;
  background: $color-secondary6;
  &__name,
  &__ic,
  &__email,
  &__mobile {
    height: $rem-40;
    max-width: 30%;
  }
  &__title {
    height: $rem-40;
    max-width: 280px;
    top: 35px;
    left: 1.5%;
  }
  &__name {
    top: 54px;
    left: 1.5%;
  }
  &__ic {
    top: 62px;
    left: 1.5%;
  }
  &__email {
    top: -32px;
    left: 42%;
  }
  &__mobile {
    top: -24px;
    left: 42%;
  }
  &__changeBtn {
    left: 60%;
  }
  &__changePwd {
    height: $rem-48;
    max-width: 260px;
    left: -10px;
    top: -12px;
    display: flex;
    margin-left: auto;
  }

  @include media("t-large", max) {
    min-height: calc(100% - (36px));
    &__title,
    &__name,
    &__ic,
    &__email,
    &__mobile {
      height: $rem-36;
      left: 0;
      min-width: 230px;
    }

    &__title {
      top: 15px;
    }
    &__name {
      top: 20px;
    }
    &__ic {
      top: 22px;
    }
    &__email {
      top: 24px;
    }
    &__mobile {
      top: 26px;
    }
    &__changePwd {
      max-width: none;
      left: 0;
      top: 60px;
    }
  }
}
