html {
  font-size: 16px;
}

body {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
  font-family: "Museo Sans";
  font-weight: $fontWeight-Normal;
  color: $color-neutral8;
  line-height: 1.25;
  overflow: overlay;
  background: $color-footer;
}

h2 {
  font-size: 1.5rem;
  line-height: 1.33;
}

h3 {
  font-size: 1.25rem;
  line-height: 1.4;
}

h6 {
  font-size: 0.75rem;
  line-height: 1.5;
}

a,
a:link,
a:hover {
  color: $color-neutral8;
  text-decoration: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

button,
input {
  overflow: visible;
}

input {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

button,
select {
  text-transform: none;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[hidden] {
  display: none;
}

//#region Customization on browser scroll bar
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
  width: 0px;
  height: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-primary3;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color-primary5;
}

::-webkit-scrollbar-corner {
  height: 0px;
  width: 0px;
}

//#endregion Customization on browser scroll bar

:focus-visible {
  outline: 0px;
}
