.paymentFailure {
  min-height: 81vh;
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  margin-bottom: $rem-48;

  &__block {
    padding-top: $rem-32;
    padding-bottom: $rem-32;
  }
  &__imageContainer,
  &__title,
  &__description {
    text-align: center;
  }
  &__imageContainer {
    padding-bottom: $rem-24;
  }
  &__title {
    font-weight: $fontWeight-Bold;
    font-size: $rem-32;
    line-height: $rem-40;
    color: $color-primary1;
  }
  &__description {
    font-weight: $fontWeight-Lighter;
    font-size: $rem-16;
    line-height: $rem-24;
    color: $color-neutral1;
  }
  &__detailTitle {
    font-size: $rem-12;
    line-height: $rem-16;
    color: $color-neutral3;
  }
  &__detailInfo {
    font-size: $rem-16;
    line-height: $rem-24;
    color: $color-neutral1;
  }
  &__detailInfoStatus {
    font-size: $rem-16;
    line-height: $rem-24;
    font-weight: $fontWeight-Bold;

  }
  &__buttonContainer {
    padding-top: $rem-40;
    .button {
      padding: $rem-10;
      border-radius: $rem-40;
      max-width: 184px;
      height: $rem-48;
      &:last-child {
        margin-left: $rem-24;
        @include media("t-large", max) {
          margin-left: 0;
          margin-top: $rem-16;
        }
      }
    }
  }
  @include media("t-large", max) {
    margin-bottom: $rem-36;
    &__block {
      padding: $rem-16;
    }
    &__container {
      padding: 0;
    }
    &__title {
      font-size: $rem-24;
      line-height: $rem-32;
    }
    &__infoContainer {
      flex-direction: column;
      padding-top: $rem-36;
    }
    &__buttonContainer {
      padding: $rem-32 $rem-16 $rem-8 $rem-16;
      .button {
        padding: $rem-10;
        border-radius: $rem-40;
        max-width: none;
        flex: auto;
      }
    }
  }
}
