.viewAccountDetailsLoader {
  background: $color-secondary6;
  z-index: 2;
  &__title {
    height: $rem-40;
    max-width: 45%;
    margin-bottom: $rem-24;
    @include media("t-large", max) {
      max-width: 75%;
    }
  }
  &__label {
    margin-top: $rem-24;
    height: $rem-24;
    max-width: 40%;
    @include media("t-large", max) {
      max-width: 55%;
    }
  }
  &__data {
    margin-top: $rem-8;
    height: $rem-24;
    max-width: 35%;
    @include media("t-large", max) {
      max-width: 40%;
    }
  }
  &__row {
    @include media("t-large", max) {
      flex-direction: column;
    }
  }
  &__subTitle {
    height: $rem-36;
    max-width: 45%;
    margin-bottom: $rem-16;
    margin-top: $rem-48;
  }
  &__subDescription {
    height: $rem-24;
    max-width: 55%;
  }
}
