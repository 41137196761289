@mixin set-fontfamily($name, $path, $format, $weight: 0, $style: "normal") {
  @font-face {
    font-family: $name;
    src: url($path) format($format);

    @if $weight != 0 {
      font-weight: $weight;
    }

    @if $style != "normal" {
      font-style: $style;
    }
  }
}

@mixin set-museoSans($format: "opentype", $style: normal) {
  @include set-fontfamily("Museo Sans", "/assets/fonts/MuseoSans_300.otf", $format, "normal", $style);
  @include set-fontfamily("Museo Sans", "/assets/fonts/MuseoSans_300.otf", $format, 300, $style);
  @include set-fontfamily("Museo Sans", "/assets/fonts/MuseoSans_500.otf", $format, 500, $style);
  @include set-fontfamily("Museo Sans", "/assets/fonts/MuseoSans_700.otf", $format, 700, $style);
  @include set-fontfamily("Museo Sans", "/assets/fonts/MuseoSans_900.otf", $format, 900, $style);
}
