.accountInfoCard {
  letter-spacing: 0.2px;
  color: $color-neutral8;
  padding: $rem-32;
  &__container {
    padding: 0;
  }
  &__accountNameContainer {
    margin-right: 0;
  }
  &__accountName {
    color: $color-secondary1;
    font-size: $rem-24;
    line-height: $rem-32;
    padding-bottom: $rem-12;
  }
  &__bulkAccountNumberTitle {
    color: $color-neutral5;
  }
  &__accountDetailsCol {
    padding-left: 0;
    padding-right: 0;
    min-width: max-content;
    @include media("t-large", max) {
      min-width: auto;
    }
  }
  &__accountDetails {
    margin-left: auto;
    cursor: pointer;
    &:hover {
      color: $color-secondary1;
      .accountInfoCard__accountDetailsImage {
        fill: $color-secondary1;
      }
    }
  }
  &__accountDetailsImage {
    width: $rem-20;
    fill: $color-chevron-icon;
    margin-left: $rem-8;
    transition: transform 0.5s;
    &.rotateImage {
      transform: rotate(90deg);
    }
  }
  &__accountDetailsContainer {
    padding-top: $rem-32;
    overflow: hidden;
    transition: max-height 0.5s;
    max-height: 0;
    animation: fadeOut 0.8s;
    &.hasDetail {
      max-height: 3000px;
      animation: fadeIn 0.8s;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  &__subTitle {
    font-weight: $fontWeight-Bold;
  }
  &__detailTitle {
    font-weight: $fontWeight-Lighter;
    font-size: $rem-12;
    line-height: $rem-16;
    padding-bottom: $rem-4;
    color: $color-neutral5;
  }
  &__bulkAccountNumber,
  &__detailInfo {
    font-size: $rem-16;
    line-height: $rem-24;
  }
  &__btnContainer {
    max-width: 240px;
    margin-left: auto;
    margin-right: 0;
    min-width: max-content;
    .button {
      height: $rem-48;
      border-radius: $rem-40;
    }
    @include media("t-large", max) {
      max-width: 100%;
      margin-left: initial;
      margin-right: initial;
    }
  }
  @include media("t-large", max) {
    &__container {
      max-width: none;
    }
    &__accountNameContainer {
      flex-direction: column;
      margin-right: initial;
    }
    &__accountDetailsCol {
      padding-left: $rem-12;
    }
    &__accountDetails {
      margin-left: 0;
      padding-top: $rem-12;
    }
    &__accountInfoContainer {
      flex-direction: column;
    }
  }
}
