.actionLog {
  padding: $rem-32;
  margin-bottom: $rem-40;
  color: $color-neutral1;
  &__viewActionLogContainer > .button {
    margin-left: auto;
    width: fit-content;
    &:hover > .actionLog__viewActionLogIcon {         
      fill: $color-primary4;
    }
    @include media("t-large", max) {
      margin-left: unset;
      padding-left: 0px;
      padding-bottom: $rem-16;
    }
  }
  &__viewActionLogIcon {
    margin-left: $rem-8;
    width: $rem-24;
    height: $rem-24;
    fill: $color-primary5;
  }
  &__label {
    font-size: $rem-12;
    color: $color-neutral3;
  }
  &__actionLogContainer {
    display: flex;
    justify-content: center;
  }
  &__actionLogTitle {
    cursor: pointer;
    &:hover {
      color: $color-primary4;
      .actionLog__actionLogIcon {
        fill: $color-primary4;
      }
    }
  }
  &__downloadContainer > .button {
    padding-left: 0;
    justify-content: flex-start;
    width: fit-content;
    &:hover > .bulkAccountTable__downloadAccountIcon {         
      fill: $color-primary4;
    }
  }
  &__actionLogIcon {
    width: $rem-20;
    fill: $color-chevron-icon;
    margin-left: $rem-8;
    transition: transform 0.5s;
    &.rotateImage {
      transform: rotate(-180deg);
    }
  }
  &__actionLogDetailContainer {
    overflow: hidden;
    transition: max-height 0.5s;
    max-height: 0;
    animation: fadeOut 0.8s;
    &.hasDetail {
      max-height: 3000px;
      animation: fadeIn 0.8s;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}

.actionLogTable {
  &__closeButtonContainer {
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: $rem-16;
    right: $rem-16;
  }
  &__closeButton {
    cursor: pointer;
    width: $rem-16;
    height: $rem-16;
    color: $color-neutral1;
  }
}