.manageUserModals {
    letter-spacing: 0.2px;
    &__modalSentbuttonContainer {
      height: $rem-48;
      .button {
        border-radius: $rem-40;
        max-width: 220px;
      }
    }
    &__modal--title {
      font-size: $rem-12;
      line-height: 1;
      color: $color-neutral3;
      padding-bottom: $rem-4;
    }
    &__modal--info {
      font-size: $rem-14;
      line-height: 1.25;
    }
    &__modalButtonContainer {
      padding: $rem-28 $rem-16 0 $rem-16;
      &--close {
        margin-right: $rem-16;
      }
      .button {
        padding: $rem-10;
        border-radius: $rem-40;
        max-width: 250px;
        height: $rem-48;
      }
    }
    &__info--email,
    &__info--nickName {
      flex: inherit;
      width: 50%;
    }
    &__description{
      font-size: $rem-14;
    }
    &__labelCheckbox{
      font-size: $rem-14;
    }
    &__confirmModalIcon {
      fill: $color-primary5;
      width: $rem-56;
      height: $rem-56;
      margin-bottom: -20px;
    }
    &__userExistModalIcon {
      fill: $color-error-lightBackground;
      width: $rem-56;
      height: $rem-56;
      margin-bottom: -20px;
    }
    &__successIcon {
      width: $rem-56;
      height: $rem-56;
      fill: $color-neutral8;
      .icon-check-filled {
        fill: $color-positive;
      }
    }
    &__formlabel {
      transition: 0.2s;
      transition-property: transform, opacity;
      font-size: $rem-12;
      line-height: 1.33;
      color: $color-neutral3;
      padding-bottom: $rem-4;
    }
    &__checkboxContainer {
      cursor: pointer;
      display: flex;
      align-items: center;
      width: fit-content;
    }
    &__pointer {
      cursor: pointer;
    }
    &__disabled {
      cursor: not-allowed;
    }
    @include media("t-large", max) {
      &__addUserModal {
        .modal-content {
          padding: $rem-24 $rem-16;
        }
      }
      &__info--email,
      &__info--nickName {
        flex: auto;
        width: inherit;
        padding-right: 0;
      }
      &__viewerContainer {
        padding-top: $rem-24;
      }
      &__modalButtonContainer {
        padding: $rem-32 $rem-16 $rem-8 $rem-16;
        .button {
          padding: $rem-10;
          border-radius: $rem-40;
          max-width: none;
          flex: auto;
        }
      }
      &__modalSentbuttonContainer {
        .button {
          max-width: none;
        }
      }
    }
  }
  