.billPaymentHistoryLoader {
  z-index: 1;
  border-radius: $rem-16;
  background: rgb(19, 35, 129);
  &__title {
    height: $rem-30;
    width: 30%;
  }
  &__graph {
    margin-top: $rem-24;
    height: 80%;
  }
}
